import React from "react";
import Select from 'react-select';
import Tooltip from "../tooltip/Tooltip";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  arrow: {
    display: 'none',
  },
  tooltip: {
    backgroundColor: 'white',
  },
})

export default ({
  name,
  value,
  options,
  onChange,
  placeholder,
  helperText,
  label = "",
  tooltip,
  optionalLabel="",
  required = false,
  marginClass = "mb-4",
  ...others
}) => {
  const classes = useStyles();
  return (
    <div className={"p-field " + marginClass}>
      {label && <p className="label mb-1 font-weight-bold">{label + (required ? " *" : "")}</p>}
      {label && optionalLabel && <span className="optional"> ({optionalLabel})</span>}
      <Select
        name={name}
        value={value}
        isClearable
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        classNamePrefix={"react-select"}
        {...others}
      />
      <div className="d-flex flex-row align-items-center">
        {
          tooltip &&
          <Tooltip title={tooltip} placement="bottom-start" classes={{
            arrow: classes.arrow, tooltip: classes.tooltip
          }}>
            <i className="icon size-2x icon-circle-question-mark nile-green"/>
          </Tooltip>
        }
        {
          helperText && <small style={{fontSize: 12}}> {helperText}</small>
        }
      </div>
    </div>
  );
};