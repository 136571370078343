import React, {Component} from 'react';

class OurServicesCommonDetail extends Component {
    render() {
        const {cmsData} = this.props;

        return (
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-6 mb-sm-5">
                        <h5 className="bordered-heading font-weight-bold">{cmsData.whatToExpectTitle_t}</h5>
                        <div className="check-list mb-sm-5"
                             dangerouslySetInnerHTML={{__html: cmsData.whatToExpectDescription_html_raw}}/>
                        <h4 className="bordered-heading font-weight-bold mt-sm-4">
                            {cmsData.objectivesTitle_t}
                        </h4>
                        <div className="check-list mt-sm-4"
                             dangerouslySetInnerHTML={{__html: cmsData.objectivesDescription_html_raw}}/>
                    </div>
                    <div className="col-md-6">
                        <h4 className="bordered-heading font-weight-bold">
                            {cmsData.programsWeOfferTitle_t}
                        </h4>
                        <div className="check-list mt-sm-3">
                            <ProgramsWeOfferRow title={cmsData.programsWeOfferTitle1_o?.item[0].programName_t}
                                                detail={cmsData.programsWeOfferTitle1_o?.item[0].programDesc_t}/>
                            <ProgramsWeOfferRow title={cmsData.programsWeOfferTitle2_o?.item[0].programName_t}
                                                detail={cmsData.programsWeOfferTitle2_o?.item[0].programDesc_t}/>
                            <ProgramsWeOfferRow title={cmsData.programsWeOffer3Title_o?.item[0].programName_t}
                                                detail={cmsData.programsWeOffer3Title_o?.item[0].programDesc_t}/>
                            <ProgramsWeOfferRow title={cmsData.programsWeOffer4Title_o?.item[0].programName_t}
                                                detail={cmsData.programsWeOffer4Title_o?.item[0].programDesc_t}/>
                        </div>
                        <p>{cmsData.disclaimerDescription_t}</p>
                    </div>
                </div>
            </div>
        );
    }
}

const ProgramsWeOfferRow = (props) => {
    const {title, detail} = props;
    return (
        <div className="row">
            <div className="col">
                <h6 className="font-weight-bold">{title}</h6>
                <p>{detail}</p>
            </div>
        </div>
    );
}

export default OurServicesCommonDetail;