import React, {Component} from "react";
import PreferencesView from "./preferences/PreferencesView";
import WillContactYouView from "./preferences/WillContactYouView";

export default class PreferencesDetailView extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.preferences.haveInsurancePlan && this.props.fullyCovers) {
            return <WillContactYouView {...this.props} />;
        } else {
            return <PreferencesView {...this.props} />;
        }
    }
}