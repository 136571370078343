import Enum from "../../../enums/Enum";

const EnumDocumentType = new Enum({

    PHOTO: {name: "Photo"},
    ID_FRONT: {name: "ID Front"},
    ID_BACK: {name: "ID Back"},
    INSURANCE_FRONT: {name: "Insurance Front"},
    INSURANCE_BACK: {name: "Insurance Back"},
    APPOINTMENT_DOCUMENT: {name: "Appointment Document"},

});

export default EnumDocumentType;