import RemotingService from '../../../services/remoting-service/RemotingService'

const noTpaOption = {id: null, name: "Not Applicable"};
const noNetworkOption = {id: null, name: "Not Applicable"};

export const retrieveTPAs = (country, callback) => {
    if (country) {
        RemotingService.getRemoteCall(`/api/insurance/public/tpa/${country}/list`, null, (tpaList) => {
            tpaList.unshift(noTpaOption);
            callback(tpaList)
        })
    }
}

export const retrieveInsuranceCompanies = (tpa, country, callback) => {
    if (tpa) {
        RemotingService.getRemoteCall(
            '/api/insurance/public/tpa/company',
            {
                tpaId: tpa.id,
                country: country
            },
            insuranceCompanyList => {
                callback(insuranceCompanyList);
            });
    } else {
        callback({companyList: [], networkList: []})
    }
}

export const retrieveAcceptedNetworks = (company, callback) => {
    if (company && company.id) {
        RemotingService.getRemoteCall(
            '/api/insurance/public/company/' + company.id + '/accepted-networks',
            null,
            insuranceNetworkList => {
                if (insuranceNetworkList.length === 0) insuranceNetworkList.push(noNetworkOption);
                callback(insuranceNetworkList);
            });
    } else {
        callback({networkList: []});
    }
}

export const retrievePolicies = (network, callback) => {
    if (network && network.id) {
        RemotingService.getRemoteCall(
            '/api/insurance/public/network/' + network.id + '/policy',
            null,
            policyList => {
                callback(policyList);
            });
    } else {
        callback({policyList: []});
    }
}
