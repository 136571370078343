import React, {Component} from 'react';
import TextInput from "../../components/text-input/TextInput";
import PhoneInput from "../../components/phone-input/PhoneInput";
import {Dropdown} from "primereact/dropdown";
import EnumVisaStatus from "../user/enums/EnumVisaStatus";
import {InputTextarea} from "primereact/inputtextarea";
import {Captcha} from "primereact/captcha";
import NotificationService from "../../services/notification-service/NotificationService";
import StringUtil from "../../utils/StringUtil";
import {EmailValidator} from "../../components/form/validator";
import PrimeDateInput from "../../components/date-input/PrimeDateInput";
import DateUtil from "../../utils/DateUtil";
import {postContactUsData} from "../../services/cms-services/ContactUsCmsService";
import NavigationBar from "../../layout/NavigationBar";

class ContactUs extends Component {

    constructor(props) {
        super(props);

        this.state = {...this.getInitialData()};

        this.emailValidator = new EmailValidator();
    }

    onFieldChange = (field, value) => {
        this.setState(state => {
            state.contactUsDTO[field] = value;
            return state;
        });
    }

    getInitialData = () => {
        return {
            contactUsDTO: {
                date: new Date()
            }
        }
    }

    onSave = () => {
        const {name, email, mobilePhone, location, date, captcha} = this.state.contactUsDTO;
        if (StringUtil.isNullOrEmpty(name)) {
            NotificationService.showNotification({
                severity: "error",
                summary: "Validation Error",
                detail: "Name is mandatory.",
            });
            return;
        } else if (StringUtil.isNullOrEmpty(email) || !this.emailValidator.validate(email)) {
            NotificationService.showNotification({
                severity: "error",
                summary: "Validation Error",
                detail: "E-mail is not valid.",
            });
            return;
        } else if (StringUtil.isNullOrEmpty(mobilePhone)) {
            NotificationService.showNotification({
                severity: "error",
                summary: "Validation Error",
                detail: "Mobile phone is not valid.",
            });
            return;
        } else if (StringUtil.isNullOrEmpty(location)) {
            NotificationService.showNotification({
                severity: "error",
                summary: "Validation Error",
                detail: "Location is mandatory.",
            });
            return;
        } else if (StringUtil.isNullOrEmpty(date)) {
            NotificationService.showNotification({
                severity: "error",
                summary: "Validation Error",
                detail: "Date is mandatory.",
            });
            return;
        } else if (StringUtil.isNullOrEmpty(captcha)) {
            NotificationService.showNotification({
                severity: "error",
                summary: "Validation Error",
                detail: "Please check the captcha box.",
            });
            return;
        } else {
            const {contactUsDTO} = this.state;
            postContactUsData(contactUsDTO, () => {
                window.location.reload()
                NotificationService.showNotification({
                    severity: "success",
                    summary: "Successful submitted",
                    detail: "Your application has been successfully completed.",
                });
            })
        }
    }

    render() {
        const uniqueId = "contact-us-content";
        const {name, email, mobilePhone, location, date, message} = this.state.contactUsDTO;

        return (
            <>
                <NavigationBar/>
                <div className="container min-vh-100">

                    <section className="col-md-10 mx-auto py-5 px-0">
                        <div className="card">
                        <div className="card-body p-md-5 py-5">
                            <h2 className="bordered-heading font-weight-bold mb-5">Contact Us</h2>
                            <div className="row">
                                <div className="col-md-6 mb-2">
                                    <ContactUsField label="Name">
                                        <ContactUsText id="name" value={name}
                                                       onChange={(value) => this.onFieldChange("name", value)}/>
                                    </ContactUsField>
                                </div>
                                <div className="col-md-6">
                                    <ContactUsField label="Email">
                                        <ContactUsText id="email" value={email}
                                                       onChange={(value) => this.onFieldChange("email", value)}/>
                                    </ContactUsField>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="phone-input-wrapper col-md-6 mb-3">
                                    <ContactUsField label="Phone">
                                        <PhoneInput
                                            id="mobilePhone"
                                            value={mobilePhone}
                                            searchPlaceholder="Search"
                                            inputClass="phone-input"
                                            onChange={(value) => this.onFieldChange("mobilePhone", value)}
                                        />
                                    </ContactUsField>
                                </div>
                                <div className="col-md-6">
                                    <ContactUsField label="Location">
                                        <Dropdown inputId="location"
                                                  className="form-control"
                                                  options={['Dubai DHCC', 'Dubai JLT', 'Kuwait']}
                                                  showClear
                                                  value={location}
                                                  onChange={e => this.onFieldChange("location", e.value)}/>
                                    </ContactUsField>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <ContactUsField label="Date">
                                        <PrimeDateInput className="w-100 contact-us-time-input"
                                                        inputClassName="form-control"
                                                        clearable
                                                        value={DateUtil.moment(date).toDate()}
                                                        onChange={value => this.onFieldChange("date", value)}
                                                        format="DD.MM.yyyy"
                                                        disablePast/>
                                    </ContactUsField>
                                </div>

                            </div>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <ContactUsField label="Your Message">
                                        <InputTextarea id="message" className="w-100"
                                                       rows={5}
                                                       value={message}
                                                       onChange={(e) => this.onFieldChange("message", e.target.value)}/>
                                    </ContactUsField>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-3">
                                    <ContactUsField>
                                        <Captcha siteKey="6Lca14chAAAAAMi_qOrHyYrbLpXYoGSxuKIL4noK"
                                                 onResponse={(response) => this.onFieldChange("captcha", response)}
                                                 onExpire={() => this.onFieldChange("captcha", null)}/>
                                    </ContactUsField>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12">
                                    <button type="button"
                                            className="btn btn-secondary"
                                            onClick={() => this.onSave()}>Send
                                    </button>
                                </div>
                            </div>
                        </div>
                        </div>
                    </section>
                </div>

            </>

        );
    }
}

const ContactUsText = (props) => {
    const {id, value, onChange} = props;
    return (
        <TextInput
            id={id}
            className="form-control"
            required="required"
            value={value}
            onChange={onChange}
        />
    );
}

const ContactUsField = (props) => {
    const {label, children = {required: true}} = props;

    return (
        <div className="row">
            {label && <div className="col-12">
                <h6 className="font-weight-bold mb-2">{label}</h6>
            </div>}
            <div className="col-12">
                {children}

            </div>
        </div>
    );
}

export default ContactUs;