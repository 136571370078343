import React, {Component} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import ResultTable from "./ResultTable";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import RemotingService from "../../services/remoting-service/RemotingService";
import CriteriaPanel from "./CriteriaPanel";

class QueryPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            queryResult: [],
            totalRecords: 0,
            first: 0,
            queryOptions: {
                size: 10,
                page: 1,
                orderBy: props.orderBy || null,
                orderDirection: null,
            },
        };
    }

    componentDidMount() {
        this.resetAndQuery();
    }

    resetAndQuery() {
        this.setState((state)=> {
                let newState = {...state};
                newState.queryOptions.page = 1;
                newState.first = 0;
                newState.totalRecords = 0;
                return newState;
            },
            () => {
                this.query(1, 0);
            });
    }

    query(page, first){
        let queryOptions = {...this.state.queryOptions, ...this.props.queryOptions};
        if (page) {
            queryOptions.page = page;
        }

        RemotingService.getRemoteCall(this.props.url, queryOptions, (result) => {
            this.setState((state) => {
                let newState = {...state};
                newState.first = first != undefined ? first : state.first;
                newState.queryResult = result.items;
                newState.queryOptions.page = page != undefined ? page : state.queryOptions.page;
                newState.totalRecords = result.totalCount;
                return newState;
            });
        });
    }

    onSort(event) {
        this.state.queryOptions.orderBy = event.sortField;
        if (event.sortOrder === 1) {
            this.state.queryOptions.orderDirection = "ASC";
        } else if (event.sortOrder === -1) {
            this.state.queryOptions.orderDirection = "DESC";
        } else {
            this.state.queryOptions.orderDirection = null;
            this.state.queryOptions.orderBy = this.props.orderBy != null ? this.props.orderBy : event.sortField;
        }
        this.setState({sortField: event.sortField, sortOrder: event.sortOrder});
        this.resetAndQuery();
    }

    onPage(event) {
        if (event.rows != this.state.queryOptions.size) {
            this.onPageSizeChanged(event.rows);
        } else {
            let first = event.first;
            let page = Math.floor(first / this.state.queryOptions.size) + 1;
            this.query(page, first);
        }
    }

    onPageSizeChanged(size) {
        this.setState((state) => {
            let newState = {...state};
            newState.queryOptions.size = size;
            return newState;
        }, () => {
            this.query(1, 0);
        });
    }

    onClear = () => {
        this.state.queryOptions.orderBy = this.props.orderBy || null;
        this.state.queryOptions.orderDirection = null;
        this.setState({sortField: null, sortOrder: null}, () => {
            this.props.onClear();
            this.resetAndQuery();
        });
    }

    render() {
        let criteriaPanel = null;
        let queryResultTable = null;
        React.Children.forEach(this.props.children, (child) => {
            if (child.type === CriteriaPanel) {
                criteriaPanel = child;
            } else if (child.type === ResultTable) {
                queryResultTable = child;
            }
        });

        let columns = [];
        React.Children.forEach(queryResultTable.props.children, (child) => {
            if (child.type === Column) {
                columns.push(child);
            }
        });

        return <DataTable responsive autoLayout
                          emptyMessage="No records"
                          className={queryResultTable.props.className}
                          style={queryResultTable.props.style}
                          header={this.renderHeader(criteriaPanel)}
                          value={this.state.queryResult}
                          rows={this.state.queryOptions.size}
                          lazy={true}
                          first={this.state.first}
                          totalRecords={this.state.totalRecords}
                          currentPageReportTemplate="Record Count: {totalRecords}"
                          paginatorLeft={<span className="p-paginator-current">Items per Page</span>}
                          rowsPerPageOptions={[10, 25, 50, 100]}
                          onPage={this.onPage.bind(this)}
                          removableSort={true}
                          sortField={this.state.sortField}
                          sortOrder={this.state.sortOrder}
                          onSort={this.onSort.bind(this)}>
            {columns}
        </DataTable>;
    }

    renderHeader(criteriaPanel) {
        return criteriaPanel ?
            <div class="row">
                <div class="col">
                    {criteriaPanel.props.children}
                </div>
                <div style={{width: '110px', padding: '5px'}}>
                    <Button className="dim14querybutton" variant="primary" onClick={() => this.resetAndQuery()} >
                        <FontAwesomeIcon icon={["fas", "search"]} /> Query
                    </Button>
                    <Button className="dim14clearbutton" variant="secondary" onClick={this.onClear} >
                        <FontAwesomeIcon icon={["fas", "trash"]} /> Clear
                    </Button>
                </div>
            </div> : null
    }
}

export default QueryPanel;