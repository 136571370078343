import React, {Component} from "react";
import _uniqueId from "lodash/uniqueId";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import PickerToolbar from "@material-ui/pickers/_shared/PickerToolbar";
import ToolbarButton from "@material-ui/pickers/_shared/ToolbarButton";
import moment from "moment";
import "./DateInput.css";
import MomentUtils from "@date-io/moment";

const CustomToolbar = function (props) {

    const {date, isLandscape, openView, setOpenView, title} = props;

    const handleChangeViewClick = (view) => (e) => {
        setOpenView(view);
    };

    return (
        <PickerToolbar title={title}
                       isLandscape={isLandscape}>
            <ToolbarButton
                onClick={handleChangeViewClick("month")}
                variant="h6"
                label={date.format("MMM")}
                selected={openView === "month"}
            />
            <ToolbarButton
                onClick={handleChangeViewClick("year")}
                variant="h6"
                label={date.format("YYYY")}
                selected={openView === "year"}
            />
        </PickerToolbar>
    );
};

export default class DateInput extends Component {

    constructor(props) {
        super(props);
        if (props.dateFormat) {
            this.dateFormat = props.dateFormat;
        } else {
            this.dateFormat = "DD-MMM-YYYY";
        }

        if (props.id) {
            this.id = props.id;
        } else {
            this.id = _uniqueId("dim14-date-id-");
        }
    }

    handleOnChange(date) {
        this.props.onChange(moment(date).utc(true));
    }

    render() {
        const {id, onChange, variant, ...rest} = this.props;
        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                    id={this.id}
                    autoOk="true"
                    format={this.dateFormat}
                    inputVariant="outlined"
                    variant={variant ? variant : "inline"}
                    ToolbarComponent={CustomToolbar}
                    onChange={date => this.handleOnChange(date)}
                    {...rest}
                />
            </MuiPickersUtilsProvider>);
    }
}

