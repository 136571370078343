import React, { Component } from 'react'
import ClinicNavigationBar from '../../layout/ClinicNavigationBar'
import TopBar from '../../layout/TopBar'
import OurServicesDubai from '../../modules/corporatelandingpage/OurServicesDubai'
import OurTeam from '../../modules/corporatelandingpage/OurTeam'
import YourAppointment from '../../modules/corporatelandingpage/YourAppointment'
import BlogSection from '../../modules/corporatelandingpage/BlogSection'
import Subscription from '../../modules/corporatelandingpage/Subscription'
import WhyChooseDiscDubai from '../corporatelandingpage/WhyChooseDISCDubai'
import {
  getOurApproachItemsDubai,
  getSharingOurKnowledge,
  getSpecialityItemsDubai,
  getUAELandingPageData,
  getYourAppointmentDataDubai
} from '../../services/cms-services/UAECmsService'

export default class DubaiClinic extends Component {

    state = {
        cmsData: null,
        isLoaded: false,
    }

    componentDidMount() {
        getUAELandingPageData(this.handleCmsData);
    }

    handleCmsData = (result) => {
        this.setState({cmsData: result, isLoaded: true})
    }


    render() {
        const cmsURL = process.env.REACT_APP_CMS_URL;
        const postFix = process.env.REACT_APP_POST_FIX;
        const {cmsData, isLoaded} = this.state;
        const data = cmsData?.descriptorDom?.page;

        return (

            <>
                {isLoaded ?
                    <>
                        <TopBar/>
                        <ClinicNavigationBar/>
                        <div className="container-fluid introduction-section">
                            <div className="row align-items-center justify-content-end">
                                <div className="col-md-5 py-5">
                                    <h1 className="font-weight-bold">
                                        {data.uAETitle_t}
                                    </h1>
                                    <h5 className="mb-4">
                                        {data.uAEDescription_t}
                                    </h5>
                                    {/*PatientPortalHide : <SlotSearch country={EnumCountry.UAE}/>*/}
                                </div>
                                <div className="col-md-6 text-right">
                                    <img className="img-fluid" src={cmsURL + data.uAEBanner_s + "?" + postFix}/>
                                </div>
                            </div>
                        </div>

                        <div id="ourServices">
                            <OurServicesDubai
                                ourServicesTitle={data.ourServicesTitle_t}
                                ourServicesDescription={data.ourServicesDescription_t}
                                getData={getSpecialityItemsDubai}/>
                        </div>
                        <div class="container-fluid xxl-limiter ">
                            <OurTeam clinicNames={["DHCC", "JLT"]}/>
                        </div>
                        <YourAppointment getData={getYourAppointmentDataDubai}/>
                        <WhyChooseDiscDubai getData={getOurApproachItemsDubai}/>
                        <BlogSection getData={getSharingOurKnowledge}/>
                        <Subscription/>
                        <div className="whatsapp-link">
                            <a data-action="open" data-phone="971521214295"
                               href="https://wa.me/971524179578" target="_blank"></a>
                        </div>
                    </>
                    : null}
            </>

        );
    }

}


