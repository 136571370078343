import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import RemotingService from '../../services/remoting-service/RemotingService'
import FileUpload from '../formcomponents/FileUpload'
import Modal from 'react-bootstrap/Modal'

const PHOTO = 'PHOTO';

class PatientProfileImage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            photoModalVisible: false,
            photoValue: null,
            newPhotoValue: null,
            photoChanged: false,
        };
    }

    componentDidMount() {
        this.retrievePhoto();
    }

    retrievePhoto = () => {
        RemotingService.getRemoteCall('api/patient/document/' + PHOTO, null, (dto) => {
            this.setState({photoValue: dto});
        });
    };

    showPhotoUpdate = () => {
        this.setState({photoModalVisible: true, photoChanged: false});
    }

    renderPhotoUpdateModal = () => {
        return this.state.photoModalVisible ?
            (<Modal className="fade"
                    id="PatienPhotoModal"
                    aria-labelledby="PatienPhotoUpdate"
                    tabindex="-1"
                    aria-hidden="true"
                    size={'lg'}
                    show={this.state.photoModalVisible}
            >
                <Modal.Header>
                    <h5 className="modal-title" id="CamOfChrioKuwait">
                        <i className="icon icon-documents size-2x"></i>
                        Photo
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => this.setState({
                          photoModalVisible: false,
                          newPhotoValue: this.state.photoValue
                      })}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                {this.photoUpdateModalBody()}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" disabled={this.state.newPhotoValue == null} onClick={this.handlePhotoUpdate}>
                        Update
                    </button>
                </Modal.Footer>
            </Modal>)
            : null;
    }

    photoUpdateModalBody = () => {
        return (
          <div>
              <FileUpload
                id="File"
                className="form-control"
                labelText="Profile Photo"
                required="required"
                multiple={false}
                value={this.state.newPhotoValue}
                specificFileTypes={["jpg", "jpeg", "png"]}
                maxFileSize={5242880}
                onChange={(value) => this.handlePhotoChange(value)}/>
            </div>
        );
    }

    handlePhotoChange = (value) => {
        this.setState({newPhotoValue: value, photoChanged: true});
    }

    handlePhotoUpdate = () => {
        if (this.state.photoChanged && this.state.newPhotoValue != null) {
            this.uploadFile(PHOTO, this.state.newPhotoValue, (fileValue) => {
                this.setState({photoValue: fileValue})
            });
        }

        this.setState({photoModalVisible: false});
    }

    uploadFile = (documentType, fileValue, callBack = () => {
    }) => {
        if (fileValue) {
            RemotingService.postRemoteCall('api/patient/document/' + documentType, fileValue, () => {
                this.setState({photoModalVisible: false});
                callBack(fileValue);
            });
        } else {
            RemotingService.deleteRemoteCall('api/patient/document/' + documentType, null, () => {
                this.setState({photoModalVisible: false});
                callBack(fileValue);
            });
        }
    }

    render() {
        const photoValue = this.state.photoValue;
        const photoSrc = photoValue ? `data:image/jpeg;base64,${photoValue.content}` : `${process.env.PUBLIC_URL}/assets/patient_profile.png`;

        return (
            <div className="profile-image-container">
                <img className="profile-image" src={photoSrc} alt=""/>
                <div className="profile-image-overlay">
                    <FontAwesomeIcon icon={["fas", "camera"]} onClick={this.showPhotoUpdate}/>
                </div>

                {this.renderPhotoUpdateModal()}
            </div>
        );
    }

}

export default PatientProfileImage;