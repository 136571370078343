import Enum from "../../../enums/Enum";

const EnumCancellationReason = new Enum({
    EMERGENCY: {name: "Cancelled", iconClass: "icon-on-time"},
    CLINICIAN_REQUEST: {name: "Cancelled", iconClass: "icon-on-time"},
    MANAGEMENT_REQUEST: {name: "Cancelled", iconClass: "icon-on-time"},
    IN_TIME: {name: "Cancelled", iconClass: "icon-on-time"},
    LATE: {name: "Late Cancellation", iconClass: "icon-late-cancellation"},
    CHARGED_LATE: {name: "Cancelled", iconClass: "icon-on-time"}
});

export default EnumCancellationReason;