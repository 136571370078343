import React from "react";
import {makeStyles, Tooltip as MaterialTooltip} from '@material-ui/core';

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
        fontSize: "0.9em"
    },
}));

function Tooltip(
  {
    placement = "top",
    children,
    classes,
    ...others
  }) {
    const combinedClasses = {...useStylesBootstrap(), ...classes};

    return <MaterialTooltip arrow placement={placement} classes={combinedClasses} {...others}><div>{children}</div></MaterialTooltip>;
}

export default Tooltip;