import React, {Component} from 'react';
import PatientNavigationBar from "../../layout/PatientNavigationBar";
import RemotingService from "../../services/remoting-service/RemotingService";
import {getFooter} from "../../layout/Layout";
import {clinicBasedPage} from "../../routes";
import EnumCountry from "../user/enums/EnumCountry";
import AppointmentAppNavBar from "../../layout/AppointmentAppNavBar";
import clsx from "clsx";

class PatientPageWrapper extends Component {

    constructor(props) {
        super(props)
        const isMobile = window.innerWidth <= 760;
        this.state = {
            clinicCountry: null,
            isMenuCollapsed: isMobile ? true : false,
            isMobile: isMobile
        }
    }

    componentDidMount() {
        this.retrieveClinicCountry();
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    retrieveClinicCountry() {
        RemotingService.getRemoteCall(`/api/patient/clinic-country`, null, clinicCountry => {
            this.setState({clinicCountry})
        })
    }

    handleMenuClick = () => {
        this.setState(state => {
            state.isMenuCollapsed = !state.isMenuCollapsed;
            return state;
        })
    }

    resize() {
        const {isMobile, isMenuCollapsed} = this.state;
        if (!(isMobile == false && window.innerWidth > 760) && !(isMobile == true && window.innerWidth <= 760)){
            const isDecreasingSizeToMobile = isMobile == false && window.innerWidth < 760 ? true : false;
            this.setState({isMobile: window.innerWidth <= 760, isMenuCollapsed: isDecreasingSizeToMobile ? true : isMenuCollapsed});
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }


    getBasePage = (clinicCountry) => {
        const {DUBAI, KUWAIT} = clinicBasedPage;
        switch (clinicCountry) {
            case EnumCountry.UAE.key:
                return DUBAI;
            case EnumCountry.KUWAIT.key:
                return KUWAIT;
            default:
                return null;
        }
    }

    render() {
        const {
            children,
            containerClass = "container-fluid p-0 bg-light-50",
            hideSideBar = false,
            activeItem
        } = this.props;
        const {clinicCountry, isMenuCollapsed, isMobile} = this.state;
        const basePage = this.getBasePage(clinicCountry);

        return (
            <>
                <div className={containerClass}>
                    <div className="container-fluid">
                        <div className={"row"}>
                            <PatientNavigationBar clinicCountry={clinicCountry} handleMenuClick={this.handleMenuClick}
                                                  isMenuCollapsed={isMenuCollapsed} hideSideBar={hideSideBar}/>
                        </div>
                    </div>
                    {hideSideBar ? children : (
                        <div className={"container-fluid"}>
                            <div className={"row"}>
                                <AppointmentAppNavBar clinicCountry={clinicCountry} activeItem={activeItem}
                                                      isMenuCollapsed={isMenuCollapsed}
                                                      handleMenuClick={this.handleMenuClick} isMobile={isMobile}/>
                                <div className={clsx("col mt-0 mt-md-3 content-offset", !isMenuCollapsed ? "d-none d-md-block" : null)}>
                                    {children}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {getFooter(basePage)}
            </>
        );
    }
}

export default PatientPageWrapper;