import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Stepper from '../../../components/stepper/Stepper'
import InsuranceDetailView from './view/InsuranceDetailView'
import RemotingService from '../../../services/remoting-service/RemotingService'
import ConfirmationDetailView from './view/ConfirmationDetailView'
import PreferencesDetailView from './view/PreferencesDetailView'
import { getMoment } from '../../../utils/CalendarDateUtil'
import ObjectUtil from '../../../utils/ObjectUtil'
import AuthService from '../../../services/auth-service/AuthService'
import Paper from '@material-ui/core/Paper'
import NotificationService from '../../../services/notification-service/NotificationService'
import PatientPageWrapper from '../../profile/PatientPageWrapper'

class Appointment extends Component {

    constructor (props) {
        super(props)
        const moment = getMoment()
        this.steps = ['Insurance', 'Confirmation', 'Preferences']

        this.initialPreferences = {
            dateOfVisit: moment().utc().startOf('day').toDate(),
            clinics: [],
            communicationMethods: [],
            bestTimesToContact: [],
            bestTimes: [],
            languages: [],
            genders: []
        };

        this.state = {
            stepperVisible: true,
            activeStep: 0,
            clinicList: [],
            appointmentPreferences: {...this.initialPreferences},
        }
    }

    componentDidMount() {
        const user = AuthService.getUser();

        if (AuthService.isAuthenticated()) {
            this.setState({
                patient: user
            }, () => {
                this.retrieveClinics();
                this.retrievePreferences();
            })
        } else {
            this.props.history.push({pathname: this.props.history.location.pathname});
        }
    }

    retrieveClinics = () => {
        RemotingService.getRemoteCall('/api/public/clinic/list', null, (result) => {
            this.setState({clinicList: result});
        });
    }

    getPreferencesFromSession = () => {
        const prefStr = sessionStorage.getItem('patientPreferences')
        return prefStr ? JSON.parse(prefStr) : {};
    }

    retrievePreferences = () => {
        RemotingService.getRemoteCall(`/api/patient/preferences`, null, result => {
            const sessionPrefs = this.getPreferencesFromSession();
            const appointmentPreferences = {...this.initialPreferences, ...ObjectUtil.removeNullFields(result), ...ObjectUtil.removeNullFields(sessionPrefs)};
            this.setState({appointmentPreferences});
        });
    }

    handleChange = (field, value, callback) => {
        this.setState(prevState => (
            {
                appointmentPreferences: {
                    ...prevState.appointmentPreferences,
                    [field]: value
                }
            }), callback);
    }

    handleClinicChange = (clinics) => {
        this.setState(prevState => (
            {
                appointmentPreferences: {
                    ...prevState.appointmentPreferences,
                    clinics,
                    bestTimes: []
                }
            }));
    }

    handleMultiSelectionChange = (field, enumValue, checked, callback) => {
        this.setState(prevState => {
            const selectedItems = prevState.appointmentPreferences[field];
            if (checked) {
                selectedItems.push(enumValue.key);
            } else {
                selectedItems.splice(selectedItems.indexOf(enumValue.key), 1);
            }
            return (
                {
                    appointmentPreferences: {
                        ...prevState.appointmentPreferences,
                        [field]: selectedItems
                    }
                });
        }, () => {
            if (callback != null && typeof callback == "function") {
                callback(this.state.appointmentPreferences[field])
            }
        });
    }

    handleCountryChange = (country) => {
        this.setState(prevState => ({
            insurance: null,
            appointmentPreferences: {
                ...prevState.appointmentPreferences,
                country,
            },
        }));
    }

    handleInsurancePlanChange = (haveInsurancePlan) => {
        if (!this.state.appointmentPreferences.country) {
            NotificationService.showNotification({
                severity: 'warn',
                summary: 'Location Required',
                detail: 'Please select location.'
            });
            return;
        }

        let nextStep = this.state.activeStep;
        if (haveInsurancePlan === false) {
            nextStep++;
        }

        this.setState(prevState => ({
            activeStep: nextStep,
            insurance: null,
            appointmentPreferences: {
                ...prevState.appointmentPreferences,
                haveInsurancePlan,
            },
        }));
    }

    goToStep = (index) => {
        this.setState({
            activeStep: index
        });
    }

    insuranceButtonClicked = (insurance) => {
        this.setState(prevState => ({
            insurance
        }))
        this.nextStep()
    }

    confirmButtonClicked = () => {
        this.nextStep()
    }

    prevStep = () => {
        this.setState(prevState => ({
            activeStep: prevState.activeStep - 1
        }))
    }

    nextStep = () => {
        this.setState(prevState => ({
            activeStep: prevState.activeStep + 1
        }))
    }

    removeSessionPreferences = () => {
        sessionStorage.removeItem('patientPreferences')
    }

    updatePreferencesFunc = (callback) => {
        this.setState({ stepperVisible: false })
        const url = `/api/patient/preferences${this.state.appointmentPreferences.id ? '/update' : ''}`

        const func = () => {
            const clinicList = [...this.state.clinicList];
            let clinics = this.state.appointmentPreferences?.clinics && this.state.appointmentPreferences?.clinics?.length != 0 ?
                this.state.appointmentPreferences.clinics.map(clinicId => clinicList.find(clinic => clinic.id == clinicId)).filter(Boolean) : [];
            const appointmentPreferences = {...this.state.appointmentPreferences, clinics: clinics};
            RemotingService.postRemoteCall(url, appointmentPreferences, (result) => {
                this.removeSessionPreferences();
                callback(result)
            });
        };

        const {insurance} = this.state;

        if (insurance) {
            let insuranceDto = {
                ...insurance,
                tpaId: insurance.tpa ? insurance.tpa.id : null,
                companyId: insurance.company ? insurance.company.id : null,
                networkId: insurance.network ? insurance.network.id : null
            };
            RemotingService.postRemoteCall('api/insurance', insuranceDto, func);
        } else {
            func();
        }
    }


    render() {
        return (
            <PatientPageWrapper containerClass="container-fluid appointment-section p-0" hideSideBar>
                <div className="content-offset mt-3">
                    <Grid container direction="column" justify="flex-start" alignItems="center">
                        <Grid item md={5} className={`${this.state.stepperVisible ? 'd-block' : 'd-none'} w-100`}>
                            <Stepper className="mt-5 mb-3" steps={this.steps} activeStep={this.state.activeStep}
                                     goToStep={this.goToStep}/>
                        </Grid>

                        {this.state.activeStep === 0 &&
                        <>
                            <Grid item className="appointment-label">
                                Please select your location
                            </Grid>
                            <Grid item className="mb-2">
                                <Grid container alignItems="center" spacing={4}>
                                    <Grid item md={5}>
                                        <button
                                            className={`square-btn btn btn-secondary appointment-button px-4 py-2 
                      ${this.state.appointmentPreferences.country === "UAE" ? 'selected' : ''}`
                                            }
                                            onClick={() => this.handleCountryChange('UAE')}>
                                            Dubai
                                        </button>
                                    </Grid>
                                    <Grid item md={2} className={"nile-green-text"}>or</Grid>
                                    <Grid item md={5}>
                                        <button
                                            className={`square-btn btn btn-secondary appointment-button px-4 py-2 ${this.state.appointmentPreferences.country === "KUWAIT" ? 'selected' : ''}`}
                                            onClick={() => this.handleCountryChange('KUWAIT')}>
                                            Kuwait
                                        </button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className="appointment-label">
                                Do you have Health Insurance?
                            </Grid>
                            <Grid item className="mb-2">
                                <Grid container alignItems="center" spacing={4}>
                                    <Grid item md={6}>
                                        <button
                                            className={`square-btn btn btn-secondary appointment-button px-4 py-2 ${this.state.appointmentPreferences.haveInsurancePlan === true ? 'selected' : ''}`}
                                            onClick={() => this.handleInsurancePlanChange(true)}
                                        >Yes
                                        </button>
                                    </Grid>
                                    <Grid item md={6}>
                                        <button
                                            className={`square-btn btn btn-secondary appointment-button px-4 py-2 ${this.state.appointmentPreferences.haveInsurancePlan === false ? 'selected' : ''}`}
                                            onClick={() => this.handleInsurancePlanChange(false)}
                                        >No
                                        </button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                this.state.appointmentPreferences.haveInsurancePlan &&
                                <Grid item md={5} className="w-100">
                                    <Paper variant={"outlined"} className="paper mt-4">
                                        <InsuranceDetailView handler={this.insuranceButtonClicked} hideCountry={true}
                                                             country={this.state.appointmentPreferences.country}/>
                                    </Paper>
                                </Grid>
                            }
                        </>
                        }
                        {
                            this.state.activeStep === 1 &&
                            <ConfirmationDetailView country={this.state.appointmentPreferences.country}
                                                    preferences={this.state.appointmentPreferences}
                                                    handleChange={this.handleChange}
                                                    handleMultiSelectionChange={this.handleMultiSelectionChange}
                                                    fullyCovers={this.state.insurance?.network}
                                                    confirmButtonClicked={this.confirmButtonClicked}
                            />
                        }
                        {
                            this.state.activeStep === 2 &&
                            <PreferencesDetailView country={this.state.appointmentPreferences.country}
                                                   preferences={this.state.appointmentPreferences}
                                                   fullyCovers={this.state.insurance?.network}
                                                   clinicList={this.state.clinicList.filter(clinic => clinic.country === this.state.appointmentPreferences.country)}
                                                   handleChange={this.handleChange}
                                                   handleClinicChange={this.handleClinicChange}
                                                   handleMultiSelectionChange={this.handleMultiSelectionChange}
                                                   updatePreferencesFunc={this.updatePreferencesFunc}
                                                   changeSlotAction={this.prevStep}
                            />
                        }
                    </Grid>
                </div>
            </PatientPageWrapper>
        );
    }
}

export default Appointment;
