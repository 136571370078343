export default class MaxValidator {

    getErrorMessage(prop) {
        return "Field should be less than or equal to " + prop;
    }

    validate(value, prop) {
        return value <= prop;
    }

}
