import React, {Component} from "react";
import Paper from "@material-ui/core/Paper";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FormField from "../../../../../components/form/FormField";
import ValidationUtil from "../../../../../components/form/validator/ValidationUtil";
import Accordion from "../../../../../components/accordion/Accordion";
import AccordionSummary from "../../../../../components/accordion/AccordionSummary";
import Tooltip from "../../../../../components/tooltip/Tooltip";
import {
    getDubaiPayAndClaimData,
    getKuwaitPayAndClaimData
} from "../../../../../services/cms-services/PatientCmsService";
import clsx from "clsx";

const generalPrefix = "tariff";
const titlePostfix = "Title_t";
const itemsPostfix = "_o";
const titlePricePostfix = "Price_t";

export default class InsurancePayNClaimView extends Component {

    constructor(props) {
        super(props);
        this.isUAE = this.props.country === 'UAE';
        this.state = {
            agreed: false,
            expanded: false,
            tariffs: []
        }
    }

    componentDidMount() {
        const getData = this.isUAE ? getDubaiPayAndClaimData : getKuwaitPayAndClaimData;
        getData(this.handleCmsData);
    }

    handleCmsData = (result) => {
        this.setState({cmsData: result, isLoaded: true})
    }

    validateAndConfirm = () => {
        if (!ValidationUtil.isValid(this.formFields)) {
            return;
        }
        this.props.confirmButtonClicked();
    }

    renderListOfTariffs = (data) => {
        const tariffs = this.getTariffList(data);

        return (
            tariffs.map(tariff => {
                    return <div className={clsx("row", tariff.titlePrice && "justify-content-between ")} key={`tariff-${tariff.title}`}>
                        <h6 className="nile-green font-weight-bold">{tariff.title}</h6>
                        {tariff.titlePrice && <p><div key={`treatment-title-price-${tariff.title}`}>{tariff.titlePrice}</div></p>}
                        {tariff.items?.map((treatment, idx) =>
                            <p className="d-flex w-100 justify-content-between ml-2" key={`treatment-${idx}`}>
                                <div>{treatment.tariffTitle_t}</div>
                                <div>{treatment.tariffPrice_t}</div>
                            </p>
                        )}
                    </div>
                }
            )
        );
    }

    getTariffList = (data) => {
        const tariffs = [];
        let index = 1;

        while (data[`${generalPrefix}${index}${titlePostfix}`] != null) {
            tariffs.push({
                title: data[`${generalPrefix}${index}${titlePostfix}`],
                items: data[`${generalPrefix}${index}${itemsPostfix}`]?.item,
                titlePrice: data[`${generalPrefix}${index}${titlePricePostfix}`]
            })
            index++;
        }

        return tariffs;
    }

    render() {
        this.formFields = [];

        const {cmsData, isLoaded} = this.state;
        let data = null;

        if (isLoaded) {
            data = cmsData[0].descriptorDom.page;
        }

        return (
            <>
                {
                    isLoaded ?
                        <div className="container p-0">
                            <Paper variant={"outlined"} className="paper mt-4 pt-2 pb-3">
                                <div className="container">
                                    <div className="row flex-column flex-lg-row align-items-lg-start align-items-center text-lg-left text-center">
                                        <div className="col-auto">
                                            <i className="icon size-5x icon-exclamation-circle-linear disc-yellow"/>
                                        </div>
                                        <div className="col pt-2 pl-3 mid-grey-400">
                                            <div className="container d-flex flex-column">
                                                <h3 className="font-weight-bold">{data.payAndClaimTitle_t}</h3>
                                                <div
                                                    dangerouslySetInnerHTML={{__html: data.payAndClaimDescriptionSection1_html}}/>
                                                {data.payAndClaimDescriptionList_o.item.map((p, idx) =>
                                                    <div
                                                        className="d-flex flex-row justify-content-start py-2 small text-left"
                                                        key={idx}>
                                                        <div
                                                            className="rounded-circle bg-nile-green white d-flex justify-content-center align-items-center mr-3"
                                                            style={{
                                                                minWidth: 32,
                                                                minHeight: 32,
                                                                width: 32,
                                                                height: 32
                                                            }}>{idx + 1}</div>
                                                        <small
                                                            className="align-self-center">{p.description_t}</small>
                                                    </div>
                                                )}
                                                <div className="row text-left">
                                                    <Accordion className="mt-4 mb-5" variant="outlined"
                                                               expanded={this.state.expanded}
                                                               onChange={() => (this.setState(prevState => ({expanded: !prevState.expanded})))}>
                                                        <AccordionSummary
                                                            expandIcon={<i className="icon icon-close-menu mid-grey-400"
                                                                           style={{transform: "rotate(45deg)"}}/>}
                                                        >
                                                        <span
                                                            className="nile-green font-weight-bold">List of Tariffs</span>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <div className="container">
                                                                {
                                                                    this.renderListOfTariffs(data)}

                                                                <div className="row mt-4 align-items-start">
                                                                    <div className="col-auto p-0">
                                                                        <Tooltip
                                                                            title="DISC-Wallet is a pre-payment solution at DISC clinics entitling you to discounted rates on DISC services.">
                                                                            <i className="icon size-2x icon-circle-question-mark nile-green  pb-2"/>
                                                                        </Tooltip>
                                                                    </div>
                                                                    <div className="col" dangerouslySetInnerHTML={{__html: data.listOfTariffDescription_html || data.listofTariffDescription_html}}></div>
                                                                </div>

                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex align-items-center justify-content-between mid-grey-400 pl-4">
                                        <FormField ref={formField => this.formFields.push(formField)} checked
                                                   validateOn={this.state.agreed}>
                                            <div className="custom-control custom-checkbox">
                                                <input id="agree-checkbox"
                                                       className="custom-control-input"
                                                       edge="end"
                                                       type="checkbox" checked={this.state.agreed}
                                                       onChange={(e) => this.setState({agreed: e.target.checked})}/>
                                                <label className="custom-control-label" htmlFor="agree-checkbox"><span
                                                    className="pl-2 pr-5">I have read and agree</span></label>
                                            </div>
                                        </FormField>
                                        <button
                                            className="square-btn btn btn-secondary appointment-button selected px-3 py-3 mt-3 mr-3"
                                            onClick={this.validateAndConfirm}>Continue
                                        </button>
                                    </div>
                                </div>
                            </Paper>
                        </div> : null
                }
            </>);
    }
}