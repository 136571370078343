import Switch from "@material-ui/core/Switch";
import withStyles from "@material-ui/core/styles/withStyles";

const GreenSwitch = withStyles({
    switchBase: {
        color: "#E2E2E2",
        '&$checked': {
            color: "#FFFFFF",
        },
        '&$checked + $track': {
            backgroundColor: "#99ca3c",
            opacity: 1,
        },
    },
    checked: {},
    track: {
        backgroundColor: "#E2E2E2",
    },
})(Switch);

export default GreenSwitch;

