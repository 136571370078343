import NotificationService from "../../../services/notification-service/NotificationService";

const isValid = (formFields) => {
    let isValid = true;
    formFields.map(formField => {
        if (formField) {
            isValid &= formField.validate();
        }
    });
    return isValid;
}

const softValid = (formFields) => {
    let invalidCount = 0;
    formFields.map(formField => {
        if (formField) {
            if(!formField.validate()) {
                invalidCount++;
            }
        }
    });
    return invalidCount;
}

const checkWithNotification = (formFields) => {
    const result = isValid(formFields);

    if (!result) {
        showNotification();
    }

    return result;
}

const showNotification = () => {
    NotificationService.showNotification({ severity: 'error', summary: 'Validation Error', detail: 'There are missing fields.' });
}

const clear = (formFields) => {
    formFields.map(formField => {
        if (formField) {
            formField.clear();
        }
    });
}

export default {isValid, softValid, showNotification, checkWithNotification, clear};