import './BestPossibleSlots.scss'
import React, { Component } from 'react'
import { Image } from 'react-bootstrap'
import DateUtil from '../../../utils/DateUtil'
import { withRouter } from 'react-router-dom'
import AuthService from '../../../services/auth-service/AuthService'
import RemotingService from '../../../services/remoting-service/RemotingService'
import { noop } from 'lodash-es'
import Modal from '../../../components/modal/Modal'
import WindowUtil from '../../../utils/WindowUtil'

class SelectBestPossibleSlot extends Component {

    constructor (props) {
        super(props)

        const { slot, staff } = this.props
        const appointmentType = slot.blockType === 'FOLLOW_UP' ? 'FOLLOW_UP' : 'NEW_COMPLAINT'

        this.state = { slot, staff, appointmentType }
    }

    openInNewTab = (event, url) => {
        event.preventDefault();
        WindowUtil.openInNewTab(url);
    }

    getDirectionsLink = (staff) => {
        return `${staff.dubaiStaff ? "dubai" : "kuwait"}/our-locations`;
    }

    getStaffProfileLink = (staff) => {
        return '/doctorprofile/' + staff.id;
    }

    onClose = () => {
        this.props.changeSlotAction();
    }

    confirmAppointment = () => {
        const {slot, staff, appointmentType} = this.state;
        const user = AuthService.getUser();
        const authenticatedUserFlow = user && user.token;

        if (authenticatedUserFlow) {
            RemotingService.postRemoteCall(
                '/api/appointment/create',
                {
                    patientId: user.id,
                    staffId: staff.id,
                    roomId: slot.roomId,
                    appointmentType: appointmentType,
                    startTime: slot.startTime,
                    endTime: slot.endTime
                },
                (appointment) => {
                    this.setState({appointment});
                });

        } else {
            sessionStorage.setItem("patientPreferences", JSON.stringify(this.props.preferences));
            this.props.history.push({pathname: '/login'});
        }
    }

    render() {
        const {slot, staff} = this.state;
        const photoSrc = staff.thumbnail ?
            'data:image/jpeg;base64,' + staff.thumbnail :
            process.env.PUBLIC_URL + '/assets/images/clinic/doctors/default.png';

        const directionsLink = this.getDirectionsLink(staff);
        const staffProfileLink = this.getStaffProfileLink(staff);

        return (
            <>
                <div className="m-3 d-flex flex-row justify-content-between">
                    <a href="#" className="navigation-button small-font" onClick={this.props.changeSlotAction}>
                        <i className="icon-chevron-left"></i>Change Slot</a>
                    <button className="square-btn btn btn-secondary dark-green-button"
                            onClick={this.confirmAppointment}>
                        Confirm Appointment <i className="icon-chevron-right"></i>
                    </button>
                </div>


                    <div className={"row border-bottom-top bg-light-green"}>
                        <h4 className="col-12 pl-5 py-3 font-weight-bold">Your selected slot</h4>
                    </div>
                    <div className="d-flex flex-column flex-lg-row ml-3 mb-3 mt-3 ">
                        <div className="col-lg-3">
                            <div className="d-flex align-items-center align-items-lg-start flex-column flex-lg-row text-center text-lg-left">
                                <Image src={photoSrc} className="staff-photo"/><br/>
                                <div className="d-flex flex-column mt-3">
                                    <span className="font-weight-bold">{staff.name}</span>
                                    <span className="small-font">{staff.specialityName}</span>
                                    <a href={staffProfileLink} className="staff-link-button"
                                       onClick={(event) => this.openInNewTab(event, staffProfileLink)}>
                                        <i className="icon size-1x icon-person"></i>
                                        View Profile
                                    </a>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-2 mt-3">
                            <div className="d-flex align-items-center align-items-lg-start flex-column flex-lg-row text-center text-lg-left ">
                                <i className="icon-calendar icon size-2x"></i>
                                <div className="d-flex flex-column ml-1 small-font">
                                <span
                                    className="">{DateUtil.formatDate(slot.startTime, 'DD MMMM YYYY')}</span>
                                    <span className="font-weight-bold text-uppercase">
                                    {DateUtil.formatDate(slot.startTime, 'dddd')}
                                </span>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-2 mt-3">
                            <div className="d-flex align-items-center align-items-lg-start flex-column flex-lg-row text-center text-lg-left ">
                                <i className="icon-time icon size-2x"></i>
                                <span className="small-font mt-2 ml-1">{DateUtil.getHourMinute12H(slot.startTime)}</span>
                            </div>

                        </div>
                        <div className="col-lg-5 mt-3">

                                <div className="d-flex align-items-center align-items-lg-start flex-column flex-lg-row text-center text-lg-left">

                                    <i className="icon-location icon size-2x"></i>
                                    <div className="d-flex flex-column pt-1">
                                        <span className="font-weight-bold text-uppercase">{slot.clinicName}</span>
                                        <span className={staff.address ? '' : 'd-none'}>{staff.address || '-'}</span>
                                        <a href={directionsLink} className="staff-link-button"
                                           onClick={(event) => this.openInNewTab(event, directionsLink)}>
                                            <i className="icon size-1x icon-directions-linear"></i>
                                            Get Directions
                                        </a>
                                    </div>
                                </div>


                        </div>

                    </div>

                {this.state.appointment &&
                <Modal visible={true} contentStyle={{width: 350}} closeAction={this.onClose}>
                    <div className="mt-1 display-flex flex-column small-font">
                        <i className="icon size-4x icon-calendar-checked disc-green d-flex justify-content-center"></i>
                        <h6 className="disc-green mb-2">Your appointment has been booked.</h6>
                        <div className="my-2">
                            <i className="icon size-1x icon-check-circle-filled disc-green"></i>
                            Please come to the clinic at least 15 minutes before your session.
                        </div>
                        <a href={slot.clinicName === 'Kuwait' ? 'kuwait/your-appointment' : 'dubai/your-appointment'}
                           target={'_blank'} className="disc-green" onClick={noop}>
                            Learn More
                            <i className="icon size-1x icon-chevron-right"></i>
                        </a>
                        <hr className="solid my-3"/>
                        <div className="d-flex justify-content-center">
                            <button className="square-btn btn btn-secondary dark-green-button"
                                    onClick={() => {
                                        this.props.history.push('/dashboard')
                                    }}>
                                View My Appointment
                            </button>
                        </div>
                    </div>
                </Modal>}
            </>
        );
    }
}

export default withRouter(SelectBestPossibleSlot);
