import React from 'react'
import {Provider} from 'react-redux'
import {store} from './utils/store'

// font-awesome setup
import {fab} from '@fortawesome/free-brands-svg-icons'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {library} from '@fortawesome/fontawesome-svg-core'

// common layout setup

// root-level css imports
import './App.css';
import './scss/main-style.scss'
import Growl from './components/growl/Growl'
import Layout from './layout/Layout'
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova-light/theme.css';
import "react-datepicker/dist/react-datepicker.css";
import LoadingComponent from "./components/loading/LoadingComponent";

library.add(fab, fas)

class App extends React.Component {
  render () {
    return (
        <Provider store={store}>
            <LoadingComponent>
                <Growl/>
                <div id="dialog-container"></div>
                <Layout/>
            </LoadingComponent>
        </Provider>
    )
  }
}

export default App
