import axios from 'axios';
import jwt_decode from 'jwt-decode';
import ReduxService from '../redux-service/ReduxService';
import RemotingService from "../remoting-service/RemotingService";

let AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL;

function login(phoneNumber, otpCode, patientId) {
    ReduxService.incrementRemotingOperationCount();

    let params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('username', phoneNumber);
    params.append('patientId', patientId);
    params.append('password', otpCode);

    return axios({
        method: 'POST',
        url: AUTH_API_URL + 'oauth/token',
        auth: {
            username: "clientId",
            password: "secret"
        },
        params,
        headers: {
            'Content-Type': 'application/json'
        },

    }).then(response => {
        let user = response.data;
        let decodedToken = jwt_decode(user.access_token);
        user.token = user.access_token;
        user.id = decodedToken.user_name;
        user.authorities = decodedToken.authorities;

        localStorage.setItem('user', JSON.stringify(user));

        return user;

    }).catch(error => {
        logout();
        let response = error.response;
        let msg = 'Authentication server is not available, try again after a while.';

        if (response) {
            if (response.data && response.data.error) {
                switch (response.data.error) {
                    case 'invalid_grant':
                    case 'unauthorized':
                        msg = 'Bad Credentials';
                        break;
                    default:
                        msg= '';
                }
            } else {
                msg = response.statusText;
            }
        }

        return Promise.reject(msg);

    }).finally(() => {
        ReduxService.decrementRemotingOperationCount();
    });
}

function logout() {
    localStorage.removeItem('user');
}

function retrieveUserData(callback) {
    RemotingService.getRemoteCall('api/user/me', null, callback);
}

function getUser() {
    return JSON.parse(localStorage.getItem('user'));
}


function getAuthToken() {
    let user = getUser();

    return user ? user.token : undefined;
}

function isAuthenticated() {
    return !!getAuthToken();
}

function userHasAuthority(authority) {
    let user = getUser();

    return user && user.authorities.includes(authority);
}

function userIsAdmin() {
    return userHasAuthority("ADMIN");
}


export default {login, logout, retrieveUserData, getUser, getAuthToken, isAuthenticated, userHasAuthority, userIsAdmin};