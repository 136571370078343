import React, {Component, Fragment} from "react";
import Paper from "@material-ui/core/Paper";
import Select from "../../../../../components/select/Select";
import Grid from "@material-ui/core/Grid";
import {Calendar} from "primereact/calendar";
import EnumTimeOfDay from "../../../../user/enums/TimeOfDay";
import GreenSwitchWithEmptyMessage from "../../../../../components/switch/GreenSwitchWithEmptyMessage";
import EnumLanguage from "../../../../user/enums/EnumLanguage";
import EnumGender from "../../../../user/enums/EnumGender";
import EnumAppointmentType from "../../../enums/EnumAppointmentType";
import RemotingService from "../../../../../services/remoting-service/RemotingService";
import DateUtil from "../../../../../utils/DateUtil";
import FormField from "../../../../../components/form/FormField";
import ValidationUtil from "../../../../../components/form/validator/ValidationUtil";
import moment from "moment";
import {onlyUnique} from "../../../../../utils/ArrayUtil";
import {withRouter} from "react-router";
import {filterManagementSpecialities} from "../../../../../utils/SpecialityUtil";
import StaffUtil from "../../../../../utils/StaffUtil";
import NotificationService from "../../../../../services/notification-service/NotificationService";

class PreferencesView extends Component {
  constructor(props) {
    super(props);
    const country = props.country || props.preferences?.clinics[0]?.country;
    this.state = {
      specialityList: [],
      showAdditionalPreferences: props.preferences.languages.length > 0 || props.preferences.genders.length > 0,
      country: country
    }
  }

  componentDidMount() {
    if(this.props.preferences.dateOfVisit && moment(this.props.preferences.dateOfVisit).isBefore(DateUtil.now())) {
      this.props.handleChange('dateOfVisit', DateUtil.addHours(DateUtil.now(), 12));
    }
    const clinics = [];
    this.props.preferences.clinics.forEach(clinic => {
      if(this.props.clinicList.find(c => c.id === (clinic.id || clinic))) {
        clinics.push((clinic.id || clinic));
      }
    })
    this.retrieveInitialData(clinics);
  }

  retrieveSpecialities = (clinicIds) => {
    RemotingService.getRemoteCall("/api/speciality/public/list-by-clinic", {clinicIds: clinicIds}, result => {
      const filteredSpecialities = filterManagementSpecialities(result);
      this.setState({specialityList: filteredSpecialities});
    });
  }

  retrieveInitialData = (clinics) => {
    const clinicList = [...this.props.clinicList];
    let clinicNames = clinics ? clinics.map(clinicId => clinicList.find(clinic => clinic.id == clinicId)?.name) : [];
    let clinicIds = clinics ? clinics.map(clinicId => clinicList.find(clinic => clinic.id == clinicId)?.id) : [];
    RemotingService.getRemoteCall("/api/staff/public/list/cliniciansByClinics", {clinicNameList: clinicNames}, result => {
      this.retrieveSpecialities(clinicIds);
      const filteredStaffList = StaffUtil.filterManagementStaff(result)
      this.setState({staffList: filteredStaffList, defaultStaffs: result});
      this.props.handleChange('clinics', clinics);
    });
  }

  handleClinicChange = (clinics) => {
    const clinicList = [...this.props.clinicList];
    let clinicNames = clinics ? clinics.map(clinicId => clinicList.find(clinic => clinic.id == clinicId)?.name) : [];
    let clinicIds = clinics ? clinics.map(clinicId => clinicList.find(clinic => clinic.id == clinicId)?.id) : [];
    RemotingService.getRemoteCall("/api/staff/public/list/cliniciansByClinics", {clinicNameList: clinicNames}, result => {
      this.retrieveSpecialities(clinicIds);
      const filteredStaffList = StaffUtil.filterManagementStaff(result)
      this.setState({staffList: filteredStaffList, defaultStaffs: result});
      this.props.handleChange('clinics', clinics);
      this.props.handleChange('specialities', null);
      this.props.handleChange('staffs', null);
      this.props.handleChange('bestTimes', []);
    });
  }

  handleSpecialityChange = (specialities) => {
    this.props.handleChange("specialities", specialities);
    this.props.handleChange('staffs', null);

    if(specialities && specialities.length > 0){
      let updatedStaffList = this.state.defaultStaffs.filter(staff => specialities.some(selectedSpeciality => selectedSpeciality.name === staff.specialityName))
      this.setState({staffList: updatedStaffList})
    }else{
      this.setState({staffList: this.state.defaultStaffs})
    }
  }

  validateAndUpdatePreferences = () => {
    const {bestTimes, clinics, dateOfVisit} = this.props.preferences
    if (clinics == null || clinics.length == 0){
      NotificationService.showNotification({
        severity: 'error',
        summary: 'Missing Information',
        detail: 'Please choose clinic location'
      });
      return;
    }

    if (dateOfVisit == null){
      NotificationService.showNotification({
        severity: 'error',
        summary: 'Missing Information',
        detail: 'Please choose date of visit'
      });
      return;
    }

    if (bestTimes == null || bestTimes.length == 0){
      NotificationService.showNotification({
        severity: 'error',
        summary: 'Missing Information',
        detail: 'Please choose time slot(s)'
      });
      return;
    }

    if(ValidationUtil.isValid(this.formFields)) {
      this.props.updatePreferencesFunc(() => {
        this.props.history.push({pathname: '/best-slots'});
      });
    }
  }

  render() {
    this.formFields = [];
    const {handleChange, handleClinicChange, preferences, embeddedMode, clinicList} = this.props;
    const {country} = this.state;

    const appointmentTypes = EnumAppointmentType.toArray().filter(type=>["NEW_COMPLAINT", "FOLLOW_UP"].includes(type.key));
    const morningBestTimes = EnumTimeOfDay[country]?.morningTimes || [];
    const afternoonBestTimes = EnumTimeOfDay[country]?.afternoonTimes || [];

    return (
      <div className={`container ${embeddedMode ? 'my-3 small-font' : 'my-3 small-font'}`}>

            <div className="row preferences-view">
              <div className="col">
                <FormField ref={formField => this.formFields.push(formField)}>
                  <Select
                    options={appointmentTypes}
                    getOptionLabel={option =>`${option.name}`}
                    getOptionValue={option => `${option.key}`}
                    value={preferences.appointmentType != null ?
                      {key: EnumAppointmentType[preferences.appointmentType].key,
                        name: EnumAppointmentType[preferences.appointmentType].name}: null}
                    onChange={(value) => {
                      handleChange("appointmentType", value ? value.key : null);
                    }}
                    className="w-100"
                    placeholder="Appointment Type"
                  />
                </FormField>
                <FormField ref={formField => this.formFields.push(formField)}>
                  <FormField ref={formField => this.formFields.push(formField)} maxSelection={1}
                             maxSelectionMessage="Locations in same country can be selected."
                             validateOn={preferences.clinics ? preferences.clinics.map(clinicId => this.props.clinicList?.find(c => c.id == clinicId)?.country).filter(onlyUnique) : []}>
                    <p className="font-weight-bold">Select suitable clinic location</p>
                    <div className="row mb-4">
                      {this.props.clinicList?.filter(c => c.country == country).filter(onlyUnique).map((option) => (
                          <div className={`d-flex ${option.name == "Kuwait" ? "col align-items-center" : "col-auto align-items-center"}`} key={`${option.id}-clinic-container`}>
                            <input id={`${option.name}-clinic-checkbox`}
                                   key={`${option.name}-clinic-checkbox`}
                                   className="align-middle"
                                   type="checkbox"
                                   checked={preferences.clinics.find(c => c == option.id) != null}
                                   onChange={(e) => {
                                       this.props.handleMultiSelectionChange("clinics", {key: option.id}, e.target.checked,
                                           ((selectedClinics) => this.handleClinicChange(selectedClinics)));
                                   }}
                            />
                              <h6  htmlFor={`${option.name}-clinic-checkbox`}  className="mb-0 pl-2 ">{option.name == "Kuwait" ? "Al Hamra Business Tower" : option.name}</h6>
                          </div>
                      ))}
                    </div>
                  </FormField>
                </FormField>
                <FormField ref={formField => this.formFields.push(formField)}>
                <Select
                  options={this.state.specialityList}
                  getOptionLabel={option =>`${option.name}`}
                  getOptionValue={option => `${option.id}`}
                  value={preferences.specialities}
                  onChange={value => this.handleSpecialityChange(value)}
                  isMulti
                  className="w-100"
                  placeholder="What speciality are you looking for?"
                />
                </FormField>

                <FormField ref={formField => this.formFields.push(formField)}>
                  <Select
                      options={this.state.staffList}
                      getOptionLabel={option =>`${option.name}`}
                      getOptionValue={option => `${option.id}`}
                      value={preferences.staffs}
                      onChange={(value) => {
                        handleChange("staffs", value);
                      }}
                      isMulti
                      className="w-100"
                      placeholder="Are you looking for a specific provider?"
                  />
                </FormField>

                <FormField ref={formField => this.formFields.push(formField)}>
                  <Calendar value={typeof preferences.dateOfVisit === 'string' ? DateUtil.moment(preferences.dateOfVisit).toDate() : preferences.dateOfVisit}
                          onChange={(e) => handleChange('dateOfVisit', DateUtil.addHours(e.value, 12))} // saved as prev day otherwise
                          minDate={DateUtil.now().toDate()}  readOnlyInput showIcon dateFormat="dd/mm/yy" />
                </FormField>

                <Paper variant={"outlined"} className="paper mt-4 py-2 pb-3">
                  <div className="p-2">
                    <FormField ref={formField => this.formFields.push(formField)}>
                      <div className="row d-flex flex-column p-4">
                      <p>What is the best time of your visit?</p>
                      <p className="font-weight-bold nile-green">MORNING</p>
                      <div className="col-12 p-0 d-flex flex-column flex-lg-row">
                        {morningBestTimes.map((timeOfDay) => (
                          <Fragment key={`${timeOfDay.key}-morning-container`}>
                            <div className="flex-row flex-lg-column">
                              <input id={`${timeOfDay.key}-morning-checkbox`}
                                     key={`${timeOfDay.key}-morning-checkbox`}
                                     className="align-middle"
                                     type="checkbox"
                                     checked={preferences.bestTimes.includes(timeOfDay.key)}
                                     onChange={(e) => this.props.handleMultiSelectionChange("bestTimes", timeOfDay, e.target.checked)}
                              />
                              <label htmlFor={`${timeOfDay.key}-morning-checkbox`}>
                                <div className="d-flex flex-column pr-3 pl-2">
                                  <h6 className="font-weight-bold mb-0">{timeOfDay.name}</h6>
                                  <h6 className="small">{timeOfDay.timeRange}</h6>
                                </div>
                              </label>
                            </div>

                          </Fragment>
                        ))}
                      </div>
                      <p className="font-weight-bold nile-green">AFTERNOON</p>
                      <div className="col-12 p-0 d-flex flex-column flex-lg-row">
                        {afternoonBestTimes.map((timeOfDay) => (
                          <Fragment key={`${timeOfDay.key}-afternoon-container`}>
                            <div className="flex-row flex-lg-column">
                            <input id={`${timeOfDay.key}-afternoon-checkbox`}
                                   key={`${timeOfDay.key}-afternoon-checkbox`}
                                   className="align-middle"
                                   type="checkbox"
                                   checked={preferences.bestTimes.includes(timeOfDay.key)}
                                   onChange={(e) => this.props.handleMultiSelectionChange("bestTimes", timeOfDay, e.target.checked)}
                            />
                            <label htmlFor={`${timeOfDay.key}-afternoon-checkbox`}>
                              <div className="d-flex flex-column pr-3 pl-2">
                                <h6 className="font-weight-bold mb-0">{timeOfDay.name}</h6>
                                <h6 className="small">{timeOfDay.timeRange}</h6>
                              </div>
                            </label>
                            </div>
                          </Fragment>
                        ))}
                      </div>
                      <div className={"row"}>
                        <div className="col font-weight-bold">Have additional preferences?</div>
                        <GreenSwitchWithEmptyMessage
                          className="mr-1"
                          checked={this.state.showAdditionalPreferences === true}
                          onChange={() => {
                            this.setState(prevState => ({
                              showAdditionalPreferences: !prevState.showAdditionalPreferences
                            }));
                          }}
                        />
                      </div>
                      <div style={{display: this.state.showAdditionalPreferences ? 'block' : 'none'}}>
                        <p className="font-weight-bold">What languages are you comfortable with</p>
                        <div className="col-12 p-0 d-flex flex-column flex-lg-row">
                          {EnumLanguage.toArray().map((languageEnum) => (
                            <Fragment key={`${languageEnum.key}-language-container`}>
                              <div className="flex-row flex-lg-column">
                            <div className="form-check form-check-inline mr-3 mb-3">
                              <input id={`${languageEnum.key}-language-checkbox`}
                                     key={`${languageEnum.key}-language-checkbox`}
                                     className="form-check-input"
                                     type="checkbox"
                                     checked={preferences.languages.includes(languageEnum.key)}
                                     onChange={(e) => this.props.handleMultiSelectionChange("languages", languageEnum, e.target.checked)}
                              />
                              <h6 className="mid-grey-400 mb-0" htmlFor={`${languageEnum.key}-language-checkbox`}>
                             {languageEnum.name}
                              </h6>
                            </div>
                              </div>
                            </Fragment>
                          ))}
                        </div>
                        <p className="font-weight-bold">Do you prefer male or female clinician?</p>
                        <div className="col-12 p-0 d-flex flex-column flex-lg-row">
                          {EnumGender.toArray().map((genderEnum) => (
                            <Fragment key={`${genderEnum.key}-gender-container`}>
                              <div className="flex-row flex-lg-column">
                                <div className="form-check form-check-inline mr-3 mb-3">
                                  <input id={`${genderEnum.key}-gender-checkbox`}
                                         key={`${genderEnum.key}-gender-checkbox`}
                                         className="form-check-input"
                                         type="checkbox"
                                         checked={preferences.genders.includes(genderEnum.key)}
                                         onChange={(e) => this.props.handleMultiSelectionChange("genders", genderEnum, e.target.checked)}
                                  />
                                  <h6 className="mid-grey-400 mb-0" htmlFor={`${genderEnum.key}-gender-checkbox`}>
                                    {genderEnum.name}
                                  </h6>
                                </div>
                              </div>

                            </Fragment>
                          ))}
                        </div>
                      </div>
                    </div>
                    </FormField>
                  </div>
                  <Grid item className="mx-3">
                    <Grid container justify="flex-end">
                      <button
                        className="square-btn btn btn-secondary appointment-button selected px-3 py-3 mt-3"
                        onClick={this.validateAndUpdatePreferences}
                      >
                        {embeddedMode ? 'Apply Preferences' : 'Apply'}
                      </button>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            </div>

      </div>
    );
  }
}

export default withRouter(PreferencesView);