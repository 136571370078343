export default class MinValidator {

    getErrorMessage(prop) {
        return "Field should be more than " + prop;
    }

    validate(value, prop) {
        return value > prop;
    }

}