import React from "react";
import {noop} from "lodash-es";
import RemotingService from "../services/remoting-service/RemotingService";
import {downloadBlob} from "./FileDownloader";
import ReduxService from "../services/redux-service/ReduxService";
import dialog from "../components/dialog/Dialog";

const download = (file) => {
    RemotingService.instance.get('api/file/download/' + file.uuid, {responseType: 'blob'})
        .then((response) => {
            downloadBlob(new Blob([response.data]), file.name);
        });
}

const downloadAsBlob = (uuid, successCallback = noop) => {
    ReduxService.incrementRemotingOperationCount();

    RemotingService.instance.get(`api/file/download/${uuid}`, {responseType: 'blob'})
        .then((response) => {
            const blob = new Blob([response.data], {type: response.headers['content-type']});
            successCallback(blob);
        })
        .finally(() => {
            ReduxService.decrementRemotingOperationCount();
        });
}

const viewEnable = (fileName) => {
    return isPdf(fileName) || isImage(fileName);
}


const viewFile = (fileName, blob) => {
    const blobUrl = window.URL.createObjectURL(blob);
    if (viewEnable(fileName)) {
        window.open(blobUrl,fileName, "menubar=1,resizable=1,width=768,height=1024")
    }
}

const isImage = (fileName) => {
    return fileName.endsWith('.jpg')
        || fileName.endsWith('.jpeg')
        || fileName.endsWith('.png');
}

const isPdf = (fileName) => {
    return fileName.endsWith('.pdf');
}

export default {download, downloadAsBlob, viewFile, viewEnable, isImage, isPdf};
