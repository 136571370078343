import React, { Component } from 'react'

export default class WhyChooseDisc extends Component {
    state = {
        cmsData: null,
        isLoaded: false
    }

    componentDidMount() {
        this.props.getData(this.handleCmsData);

    }

    handleCmsData = (result) => {
        this.setState({cmsData: result, isLoaded: true})
    }


    render() {
        const {cmsData, isLoaded} = this.state;
        let component = null;
        if (isLoaded) {
            component = cmsData[0].descriptorDom.component;

        }
        const cmsURL = process.env.REACT_APP_CMS_URL;
        const postFix = process.env.REACT_APP_POST_FIX;


        return (

            <>
                {isLoaded ?
                    <>

                        <div className="col-lg-6 mx-auto text-center mt-5">
                            <h1 className="font-weight-bold">{component?.ourApproachTitle_t}</h1>
                            <p className="mt-3"
                               dangerouslySetInnerHTML={{__html: component?.ourApproachDescription_html}}>
                            </p>
                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="scrolling-wrapper">

                                    {
                                        component?.ourApproachRepetitingGroup_o?.item?.map((i, index) =>
                                            <div key={index.toString()} className="col-lg-4 col-9">
                                                <div className="square-card">
                                                    <div
                                                        className="square-card-image"
                                                        style={{
                                                            backgroundImage: "url(" + cmsURL + i.ourApproachSubBanner_s + "?" + postFix,
                                                        }}
                                                    ></div>
                                                    <div className="square-card-footer">
                                                        <h6 className="font-weight-bold pl-2">{i.ourApproachSubTitle_t}</h6>
                                                        <a className="btn btn-card-link btn-sm"
                                                           href={i.ourApproachSubURL_t || '#'}>
                                                            SEE MORE <i className="icon icon-right-arrow size-1x"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>

                            </div>
                        </div>


                    </>
                    : null}

            </>


        );


    }


}


