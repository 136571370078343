import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import EnumTimeOfDay from "../../../../user/enums/TimeOfDay";
import RemotingService from "../../../../../services/remoting-service/RemotingService";
import {withRouter} from "react-router";
import ContactInformation from "../../common/ContactInformation";

class WillContactYouView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patient: {}
        };
    }

    componentDidMount() {
        this.retrievePatient();

        this.props.updatePreferencesFunc(() => {
            RemotingService.postRemoteCall("/api/appointment/create-request");
        });
    }

    retrievePatient () {
        RemotingService.getRemoteCall(`/api/patient`, null, patient => {
            this.setState({ patient })
        })
    }

    goToDashboard = () => {
        this.props.history.push({pathname: '/dashboard'});
    }


    renderPreferredCommunicationMethods = () => {
        const {preferences} = this.props;
        const {patient} = this.state;

        return (
            <div className="d-flex flex-row">
                <div className="d-flex flex-column">
                    {preferences.communicationMethods.includes("PHONE") &&
                    <small className="font-weight-bold mb-2">Phone: </small>}
                    {preferences.communicationMethods.includes("WHATSAPP") &&
                    <small className="font-weight-bold mb-2">Whatsapp: </small>}
                    {preferences.communicationMethods.includes("EMAIL") &&
                    <small className="font-weight-bold mb-2">E-Mail: </small>}
                </div>
                <div className="d-flex flex-column ml-2">
                    {preferences.communicationMethods.includes("PHONE") &&
                    <small className="mb-2">{patient.mobilePhone ? patient.mobilePhone : '-'}</small>}
                    {preferences.communicationMethods.includes("WHATSAPP") &&
                    <small className="mb-2">{patient.mobilePhone ? patient.mobilePhone : '-'}</small>}
                    {preferences.communicationMethods.includes("EMAIL") &&
                    <small className="mb-2">{patient.email ? patient.email : '-'}</small>}
                </div>
            </div>
        );
    }

    renderBestTimeToContact = () => {
        const {preferences} = this.props;

        return (
            <div className="d-flex flex-row">
                <div className="d-flex flex-column">
                    {
                        EnumTimeOfDay[preferences.country].toArray().map((bestTime, idx) =>
                            preferences.bestTimesToContact.includes(bestTime.key) &&
                            <small key={idx} className="font-weight-bold mb-2">
                                {`${bestTime.name} ${bestTime.dayPart}: `}
                            </small>
                        )
                    }
                </div>
                <div className="d-flex flex-column ml-2">
                    {
                        EnumTimeOfDay[preferences.country].toArray().map((bestTime, idx) =>
                            preferences.bestTimesToContact.includes(bestTime.key) &&
                            <small className="mb-2" key={idx}>
                                {bestTime.timeRange}
                            </small>
                        )
                    }
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="container d-flex justify-content-center">
                <div className="col-9 mt-5">
                    <Paper variant={"outlined"} className="paper mt-4 pb-3">
                        <div className="d-flex flex-column align-items-center">
                            <i className="icon size-5x icon-check-circle-linear disc-green"/>
                            <h3>Thank you.</h3>
                            <h5>Our Insurance Coordinator will contact you.</h5>
                        </div>
                        <div className="pl-5 pt-4">
                            <h5 className="font-weight-bold">Preferred Communication Method</h5>
                            {this.renderPreferredCommunicationMethods()}
                            <h5 className="font-weight-bold mt-3">Best Time to Contact</h5>
                            {this.renderBestTimeToContact()}
                        </div>
                        <Divider className="mt-4"/>
                        <Grid item className="mx-3">
                            <Grid container justify="flex-end">
                                <button
                                    className="square-btn btn btn-secondary appointment-button selected px-3 py-3 mt-3"
                                    onClick={this.goToDashboard}>Go To Dashboard
                                </button>
                            </Grid>
                        </Grid>
                    </Paper>
                    <ContactInformation country={this.props.preferences.country} />
                </div>
            </div>
        )
    }
}

export default withRouter(WillContactYouView);