import React, {Component} from "react";
import ClinicNavigationBar from "../../layout/ClinicNavigationBar";
import TopBar from "../../layout/TopBar";
import Subscription from "../corporatelandingpage/Subscription";
import {getLocationsDataDubai} from "../../services/cms-services/OurAppointmentCmsService";
import YourAppointmentSideMenuDubai from "./YourAppointmentSideMenuDubai";

class DubaiLocations extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cmsData: null,
            isLoaded: false
        };
    }

    componentDidMount() {
        getLocationsDataDubai(this.handleCmsData);

    }

    handleCmsData = (result) => {
        this.setState({cmsData: result, isLoaded: true})
    }

    render() {
        const {cmsData, isLoaded} = this.state;
        let page = null;
        if (isLoaded) {
            page = cmsData[0].descriptorDom.page;
        }


        return (
            <>
                <TopBar/>
                <ClinicNavigationBar/>
                {isLoaded ?
                    <div className="your-appointment-container container-fluid py-md-5 xxl-limiter">
                        <div className="row min-vh-100 flex-column-reverse flex-md-row justify-content-end">
                            <div className="col-md-8 offset-md-1">
                                <h4 className="mb-4">{page?.informativeDescription_t}</h4>
                                {
                                    page?.locations_o?.item?.map((i, index) =>
                                        <div
                                            data-toggle="collapse"
                                            href={"#" + i.uniqueId_t}
                                            role="button"
                                            aria-expanded="false"
                                            aria-controls={i.uniqueId_t}
                                            className="container-link mb-3"
                                        >
                                            <div className="card">
                                                <div className="card-body p-0">
                                                    <div className="row p-4">
                                                        <div className="col-10 col-md-11">
                                                            <h2 className="bordered-heading font-weight-bold">{i.locationsTitle_t}</h2>
                                                            <h6>{i.locationDescription_t}</h6>
                                                        </div>
                                                        <div
                                                            className="col-md-1 col-2 d-flex justify-content-end align-items-start pr-0">
                                                            <button
                                                                className="btn btn-square collapsed"
                                                                type="button"
                                                                data-toggle="collapse"
                                                                data-target={"#" + i.uniqueId_t}
                                                                aria-expanded="false"
                                                                aria-controls={i.uniqueId_t}
                                                            >
                                                                <i className="icon icon-chevron-up size-2x mid-grey-400"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div id={i.uniqueId_t} className="card-content collapse">
                                                        <section className="border-bottom p-4">
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <h4 className="font-weight-bold">{i.workingHoursTitle_t}</h4>
                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center no-gutters">
                                                                <div className="col-5 col-lg-2">
                                                                    <div className="card">
                                                                        <div className="card-body p-2">
                                                                            <h6 className="mb-0 ">
                                                                                <small
                                                                                    className="font-weight-bold">FROM</small>
                                                                            </h6>
                                                                            <p className="mb-2 nile-green font-weight-bold">
                                                                                {i.workingDayFrom_t}
                                                                            </p>
                                                                            <p className="mb-0">{i.workingHoursFrom_t} - {i.workingHoursTo_t}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-1 col-2 p-0 text-center">
                                                                    <i className="icon icon-right-arrow size-2x mid-grey-400"></i>
                                                                </div>
                                                                <div className="col-5 col-lg-2">
                                                                    <div className="card">
                                                                        <div className="card-body p-2">
                                                                            <h6 className="mb-0 ">
                                                                                <small
                                                                                    className="font-weight-bold">TO</small>
                                                                            </h6>
                                                                            <p className="mb-2 nile-green font-weight-bold">
                                                                                {i.workingDayTo_t}
                                                                            </p>
                                                                            <p className="mb-0">{i.workingHoursFrom_t} - {i.workingHoursTo_t}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                        <section className="p-4 border-bottom ">
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <h4 className="font-weight-bold">{i.contactTitle_t}</h4>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-6 col-12">
                                                                    <ul className="contact-info-list ">
                                                                        <li>
                                                                            <label>
                                                                                <i className="icon size-2x icon-phone nile-green"></i>
                                                                                {i.phoneTitle_t}
                                                                            </label>
                                                                            <a href={"tel:" + i.phoneNumber_t}>{i.phoneNumber_t}</a>
                                                                        </li>
                                                                        <li>
                                                                            <label>
                                                                                <i className="icon size-2x icon-whatsapp nile-green"></i>
                                                                                {i.mobileTitle_t}
                                                                            </label>
                                                                            <span>{i.mobileNumber_t}</span>
                                                                        </li>
                                                                        <li>
                                                                            <label>
                                                                                <i className="icon size-2x icon-fax nile-green"></i>
                                                                                {i.faxTitle_t}
                                                                            </label>
                                                                            <span>{i.faxNumber_t}</span>
                                                                        </li>
                                                                        <li>
                                                                            <label>
                                                                                <i className="icon size-2x icon-mail nile-green"></i>
                                                                                {i.emailTitle_t}
                                                                            </label>
                                                                            <span>{i.emailAddress_t}</span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </section>
                                                        <section className="p-4 border-bottom ">
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <h4 className="font-weight-bold">{i.addressTitle_t}</h4>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <p dangerouslySetInnerHTML={{__html: i.addressDescription_html}}>
                                                                    </p>
                                                                    <a href={i.getDirections_t} target="_blank"
                                                                       className="disc-link-button font-weight-normal">
                                                                        <i className="icon size-1x icon-directions-linear disc-green"></i>
                                                                        Get Directions
                                                                    </a>
                                                                    <div className="mt-4 google-maps"
                                                                         dangerouslySetInnerHTML={{__html: i.mapsEmbedCode_t}}></div>

                                                                </div>

                                                            </div>
                                                        </section>
                                                        <section className="p-4 ">
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <h4 className="font-weight-bold">{i.parkingTitle_t}</h4>
                                                                </div>
                                                                <div className="col-lg-8">
                                                                    <div className="parking-information"
                                                                         dangerouslySetInnerHTML={{__html: i.parkingDescription_html}}>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="col-md-3 p-0">
                                <YourAppointmentSideMenuDubai locationActive="menu-active"/>
                            </div>
                        </div>
                    </div>
                    : null}
                <Subscription/>
            </>
        );
    }

}

export default DubaiLocations;
