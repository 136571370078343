import React, {Component} from "react";
import "./InsuranceInformation.css"
import RemotingService from "../../services/remoting-service/RemotingService";
import DateUtil from "../../utils/DateUtil";
import InsuranceModal from "./InsuranceModal";
import dialog from "../../components/dialog/Dialog";
import clsx from "clsx";

export default class InsuranceInformation extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.retrieveInsurance();
    }

    retrieveInsurance() {
        RemotingService.getRemoteCall('api/insurance', null, (insurance) => {
            this.setState({insurance});
        });
    };

    render() {
        const publicURL = process.env.PUBLIC_URL;
        const insurance = this.state.insurance || {};

        const tpaName = insurance.tpa ? insurance.tpa.name : (insurance.customTpa || "-");
        const companyName = insurance.company ? insurance.company.name : (insurance.customCompany || "-");
        const networkName = insurance.network ? insurance.network.name : (insurance.customNetwork || "-");
        const {cardProps} = this.props;

        return (
            <>
                <div className={clsx("card insurance", cardProps)}>
                    <div className={"card-header d-flex justify-content-between p-1 bg-white"}>
                        <div className={"card-header-title d-flex ml-2"}>
                            <i className="icon icon-insurance size-2x mid-grey-400 align-self-center"></i>
                            <h6 className={"font-weight-bold mb-0 align-self-center"}>INSURANCE</h6>
                        </div>
                        <div className={"card-header-actions"}>
                            <button type="button"
                                    className="edit-button"
                                    data-toggle="modal"
                                    data-target="#InsuranceModal"
                                    aria-hidden="true"
                                    onClick={() => {
                                        this.showInsuranceModal()
                                    }}>
                                <i className="icon size-2x icon-edit mid-grey-400 btn p-0"></i>
                            </button>
                        </div>
                    </div>
                    <div className={"card-body small-font pt-2"}>
                        <div className="row mb-2 align-items-center" style={{marginBottom: "24px"}}>
                            <div className="col-3 d-flex">
                                <div className="insurance-company-image-container">
                                    <img src={publicURL + "/assets/images/icons/verified.svg"}
                                         alt="Verified"
                                         className="insurance-company-verified-icon"/>
                                    <img src={publicURL + "/assets/images/insurance-company-mock-img.png"}
                                         alt="Mock Company"
                                         className="insurance-company-image"/>
                                </div>
                            </div>
                            <div className="col-9">
                                <div className="row">
                                    {companyName}
                                </div>
                                <div className="row font-weight-bold">
                                    Direct Billing
                                </div>
                            </div>
                        </div>
                        <InsuranceItems title="TPA" content={tpaName}/>
                        <InsuranceItems title="Network" content={networkName}/>
                        {
                            insurance.policyName &&
                            <InsuranceItems title="Policy Name"
                                            content={insurance.policyName}/>
                        }
                        {
                            insurance.policyNumber &&
                            <InsuranceItems title="Policy Number"
                                            content={insurance.policyNumber}/>
                        }
                        <InsuranceItems title="Member ID" content={insurance.memberId ? insurance.memberId : "-"}/>
                        <InsuranceItems title="Validity"
                                        content={insurance.validityTo ? DateUtil.formatDateMMM(insurance.validityTo) : "-"}/>
                    </div>
                </div>
            </>
        )
    }

    showInsuranceModal() {
        dialog({
            component: <InsuranceModal onInsuranceUpdated={insurance => this.onInsuranceUpdated(insurance)}/>,
            size: "lg"
        });
    }

    onInsuranceUpdated(insurance) {
        this.setState({insurance});
    }
}

class InsuranceItems extends Component {
    render() {
        return (
            <div className={"row mb-1"}>
                <div className={"col-4 d-flex font-weight-bold"}>
                    <div className={"align-self-center"}>{this.props.title}</div>
                </div>
                <div className={"col-8 align-self-center"}>{this.props.content}</div>
            </div>
        )
    }
}