import React, { Component } from 'react'
import Login from './Login'
import Registration from './Registration'
import OtpChallenge from './OtpChallenge'
import ClinicNavigationBar from '../../../layout/ClinicNavigationBar'
import TopBar from '../../../layout/TopBar'
import RemotingService from '../../../services/remoting-service/RemotingService'
import AuthService from '../../../services/auth-service/AuthService'
import NotificationService from '../../../services/notification-service/NotificationService'
import { withRouter } from 'react-router'
import PatientSelection from './PatientSelection'
import DateUtil from '../../../utils/DateUtil'
import KuwaitTopBar from '../../../layout/KuwaitTopBar'
import KuwaitClinicNavigationBar from '../../../layout/KuwaitClinicNavigationBar'

class LoginRegistration extends Component {
  constructor (props) {
    super(props)

    this.state = {
      mobilePhone: '',
      otpType: 'NOT_SENT',
      fullName: '',
      email: '',
      patients: [],
      otpCode: {},
      confirmedBirthday: null,
      selectedPatient: {},
      clinicCountry: this.getClinicCountry()
    };

    this.sanitizePhoneNumber = this.sanitizePhoneNumber.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.onLoginResend = this.onLoginResend.bind(this);
    this.onRegister = this.onRegister.bind(this);
    this.onRegisterResend = this.onRegisterResend.bind(this);
    this.verifyLogin = this.verifyLogin.bind(this);
    this.verifyRegistration = this.verifyRegistration.bind(this);
    this.patientDateChanged = this.patientDateChanged.bind(this);
    this.patientDateSubmitted = this.patientDateSubmitted.bind(this);
    this.selectPatient = this.selectPatient.bind(this);
  }

  componentDidMount() {
    if(AuthService.isAuthenticated()) {
      this.props.history.push('/dashboard')
      window.location.reload(false)
    }
  }

  sanitizePhoneNumber(phoneNumber) {
    return phoneNumber.replaceAll(/\s+/g, '');
  }

  onLogin(mobilePhone) {
    const sanitizedPhoneNumber = this.sanitizePhoneNumber(mobilePhone);

    const otpErrorUrl = "api/public/sms/checkForOtpErrors/" + sanitizedPhoneNumber;
    const loginUrl = "api/public/sms/loginOtp/" + sanitizedPhoneNumber;

    RemotingService.postRemoteCall(otpErrorUrl, null, (result) => {
      RemotingService.postRemoteCall(loginUrl, null, (result) => {
        this.setState({
          otpType: "LOGIN_SENT",
          mobilePhone: sanitizedPhoneNumber,
        });
      });
    });
  }

  onLoginResend() {
    this.onLogin(this.state.mobilePhone);
  }

  onRegister(mobilePhone, email, fullName) {
    const sanitizedPhoneNumber = this.sanitizePhoneNumber(mobilePhone);

    const otpErrorUrl = "api/public/sms/checkForOtpErrors/" + sanitizedPhoneNumber;
    const registerUrl = "api/public/sms/registrationOtp/" + sanitizedPhoneNumber;

    RemotingService.postRemoteCall(otpErrorUrl, null, (result) => {
      RemotingService.postRemoteCall(registerUrl, null, (result) => {
        this.setState({
          otpType: "REGISTRATION_SENT",
          mobilePhone: sanitizedPhoneNumber,
          email: email,
          fullName: fullName,
        });
      });
    });
  }

  onRegisterResend() {
    this.onRegister(
      this.state.mobilePhone,
      this.state.email,
      this.state.fullName
    );
  }

  forwardToLogin () {
    this.props.history.push("/dashboard");

    window.location.reload(true);
  }

  authenticateAndRedirect(user) {
    AuthService.retrieveUserData((userData) => {
      this.state.selectedPatient ?
          this.assignUser(user, this.state.selectedPatient) :
          this.assignUser(user, userData)
      this.forwardToLogin();
    });
  }

  assignUser(user, userData) {
    Object.assign(user, userData);
    localStorage.setItem("user", JSON.stringify(user));
  }

  patientDateChanged(date) {
    this.setState({confirmedBirthday: date})
  }

  patientDateSubmitted(patient) {
    if(patient.birthday === DateUtil.formatDate(this.state.confirmedBirthday, "YYYY-MM-DD")){
      AuthService.login(this.state.mobilePhone, this.state.otpCode, patient.id).then((user)=> {
        this.authenticateAndRedirect(user);
      }, (error) => {
        NotificationService.showNotification({
          severity: "error",
          summary: "Authentication Error",
          detail: "Bad Credentials",
        });
      })
    }else{
      NotificationService.showNotification({
        severity: "error",
        summary: "Authentication Error",
        detail: "Date of Birth does not match",
      });
    }
  }


  verifyLogin(otpCode) {
    AuthService.login(this.state.mobilePhone, otpCode, "").then(
        (user) => {
          RemotingService.getRemoteCall(`api/user/retrieveByPhoneNumber`, {phoneNumber: this.state.mobilePhone}, patients => {
            patients.length === 1 ?
                this.authenticateAndRedirect(user) :
                this.prepareMultiplePatientScreen(patients, otpCode);
          });
        },
        (error) => {
          NotificationService.showNotification({
            severity: "error",
            summary: "Authentication Error",
            detail: "Bad Credentials",
          });
        }
    );
  }

  prepareMultiplePatientScreen(patients, otpCode) {
    AuthService.logout();
    this.setState({otpType: "PATIENT_SELECTION", patients: patients, otpCode: otpCode})
  }

  verifyRegistration(otpCode) {
    const url = "api/public/sms/register";
    const params = {
      code: otpCode,
      mobilePhone: this.state.mobilePhone,
      email: this.state.email,
      fullName: this.state.fullName,
      clinicCountry: this.state.clinicCountry
    };

    RemotingService.postRemoteCall(url, params, (result) => {
      NotificationService.showNotification({
        severity: "success",
        summary: "Registration Successful",
        detail: "You will be redirected to your dashboard",
      });

      setTimeout(() => this.verifyLogin(otpCode), 1000);
    });
  }

  selectPatient(patient) {
    this.state.selectedPatient === patient ?
        this.setState({selectedPatient: {}}) :
        this.setState({selectedPatient: patient});
  }

  getClinicCountry = () => {
    const pathName = this.props.history.location.pathname ;
    return pathName.endsWith('-kuwait') ? "KUWAIT" :"UAE";
  }

  render() {
    return (
      <>
        {
          this.props.history.location.pathname === '/login-kuwait' || this.props.history.location.pathname === '/new-appointment-kuwait' ?
              <KuwaitTopBar /> : <TopBar />
        }
        {
          this.props.history.location.pathname === '/login-kuwait' || this.props.history.location.pathname === '/new-appointment-kuwait' ?
              <KuwaitClinicNavigationBar/> :  <ClinicNavigationBar/>

        }

        <div className="container-fluid">
          {this.state.otpType === "NOT_SENT" && (
            <div className={"row min-vh-100"}>
              <div className={"col-md-6 py-5"}>
                <div className={"offset-lg-1"}>
                  <Login onLogin={this.onLogin} />
                </div>
              </div>
              <div className={"col-md-6 py-5 bg-light-grey-100"}>
                <div className={"offset-lg-1"}>
                  <Registration onRegister={this.onRegister} />
                </div>
              </div>
            </div>
          )}
          {this.state.otpType === "LOGIN_SENT" && (
            <div className={"row"}>
              <div className={"col"}>
                <OtpChallenge
                  verify={this.verifyLogin}
                  resend={this.onLoginResend}
                />
              </div>
            </div>
          )}
          {this.state.otpType === "REGISTRATION_SENT" && (
            <div className={"row"}>
              <div className={"col"}>
                <OtpChallenge
                  verify={this.verifyRegistration}
                  resend={this.onRegisterResend}
                />
              </div>
            </div>
          )}
          {this.state.otpType === "PATIENT_SELECTION" && (
              <div className={"row"}>
                <div className={"col"}>
                  <PatientSelection
                      patients={this.state.patients}
                      confirmedBirthday={this.state.confirmedBirthday}
                      selectedPatient={this.state.selectedPatient}
                      selectPatient={this.selectPatient}
                      patientDateChanged={this.patientDateChanged}
                      patientDateSubmitted={this.patientDateSubmitted}
                  />
                </div>
              </div>
          )}
        </div>
      </>
    );
  }
}
export default withRouter(LoginRegistration);
