import React, {Component} from "react";
import PatientNameUtil from "../../../utils/PatientNameUtil";
import DateUtil from "../../../utils/DateUtil";
import EnumGender from "../enums/EnumGender";
import EnumVisaStatus from "../enums/EnumVisaStatus";
import EnumSpecialStatus from "../enums/EnumSpecialStatus";
import Tooltip from "../../../components/tooltip/Tooltip";
import EnumLanguage from "../enums/EnumLanguage";
import dialog from "../../../components/dialog/Dialog";
import moment from "moment";
import UpdateProfileModal from "../modals/UpdateProfileModal";
import PhoneNumberUtil from "../../../utils/PhoneNumberUtil";

export default class MyProfileCard extends Component {

    openEditProfileModal() {
        dialog({
            component: <UpdateProfileModal patient={this.props.patient}
                                           onPatientUpdated={patient => this.props.onPatientUpdated(patient)}/>,
       
        });
    }

    findActiveEmergencyContact = (emergencyContact) => {
        return (
          emergencyContact && emergencyContact.length
            ? emergencyContact.reduce((prev, current) => (moment(prev.updateDate).isAfter(moment(current.updateDate))) ? prev : current)
            : {
                relationshipType: "",
                name: "",
                mobilePhone: ""
            }
        )
    }

    render() {
        const patient = this.props.patient || {specialStatusList: []};
        const emergencyContact = patient.emergencyContact ? this.findActiveEmergencyContact(patient.emergencyContact) : null;

        return (
            <div className={"card h-100"}>
                <div className={"card-header d-flex justify-content-between p-1 bg-white"}>
                    <div className={"card-header-title d-flex ml-2"}>
                        <i className="icon size-2x icon-person align-self-center mid-grey-400"/>
                        <h6 className={"font-weight-bold mb-0 align-self-center"}>MY PROFILE</h6>
                    </div>
                    <div className={"card-header-actions"}>
                        <i className="icon size-2x icon-edit mid-grey-400 btn p-0"
                           onClick={() => {this.openEditProfileModal()}}/>
                    </div>
                </div>
                <div className="card-header p-0">
                    <div className="d-flex justify-content-between bg-light-grey-100 w-100 h-100 pt-2 pb-2 pl-3 pr-3">
                        <div className="font-weight-bold">{PatientNameUtil.getPatientsName(patient)}</div>
                        <div><i className="icon size-1x icon-verified disc-green"></i> <b>MRN</b> {patient.mrn ? patient.mrn : '-'}</div>
                    </div>
                </div>
                <div className={"card-body small-font pt-2"}>
                    <RowItem label="ID No." value={patient.eid}/>
                    <RowItem label="Date of Birth" value={patient.birthday ? `${DateUtil.formatDateMMM(patient.birthday)} / ${DateUtil.getAge(patient.birthday)}` : null}/>
                    <RowItem label="Gender" value={EnumGender[patient.gender]}/>
                    <RowItem label="Nationality" value={patient.nationality ? patient.nationality.nameInEnglish : null}/>
                    <RowItem label="Visa Status" value={EnumVisaStatus[patient.visaStatus]}/>
                    <RowItem label="Phone" value={PhoneNumberUtil.formatInternational(patient.mobilePhone)}/>
                    <RowItem label="Email" value={patient.email}/>
                    <RowItem label="Address" value={patient.address}/>
                    <RowItem label="Emergency Contact" value={emergencyContact ? emergencyContact.name + " / " + PhoneNumberUtil.formatInternational(emergencyContact.mobilePhone) : null}/>
                </div>
                <div className={"card-footer small-font bg-white"}>
                    <div className={'row'}>
                        <div className={'col-lg-5 d-flex'}>
                            <i className="icon size-2x icon-preferences align-self-center"></i>
                            <div className={'font-weight-bolder small-font align-self-center'}>MY PREFERENCES</div>
                        </div>
                        <div className={'col-lg-7'}>
                            <div className={'row'}>
                                {this.renderSpecialStatusIcons(patient)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderSpecialStatusIcons(patient) {
        const icons = [];
        for (const ss of patient.specialStatusList) {
            if (ss.specialStatus === "LANGUAGE" && ss.languageLimits) {
                const languageLimits = ss.languageLimits.split(", ");
                languageLimits.map(language => {
                    icons.push(
                        <Tooltip title={`${EnumSpecialStatus[ss.specialStatus]} ${ss.specialStatus === "LANGUAGE" ? `: ${language}` : ''}`}>
                            <MyPreferencesItem icon="icon-language" language={EnumLanguage[language].code}/>
                        </Tooltip>
                    );
                })
            } else {
                icons.push(
                    <Tooltip title={EnumSpecialStatus[ss.specialStatus]}>
                        <MyPreferencesItem icon={`icon-${ss.specialStatus.toLowerCase()}`}/>
                    </Tooltip>

                );
            }
        }
        return <div className={'col d-flex flex-wrap justify-content-end'}>{icons}</div>
    }

}

class RowItem extends Component{

    render(){
        const {label, value} = this.props;

        return (
            <div className={"row mb-1"}>
                <div className={"col-5 font-weight-bold"}>
                    <div className={"align-self-center"}>{label}</div>
                </div>
                <div className={"col-7 align-self-center"}>{value == null ? "-" : value}</div>
            </div>
        )
    }
}

class MyPreferencesItem extends Component {
    render () {
        return (
            <div className={'preferences-item-border d-flex ml-1 mb-1'}>
                <i className={'icon size-2x nile-green p-0 ' + this.props.icon}/>
                {this.props.language ?
                    <div className={'font-weight-bolder small-font nile-green align-self-center mr-1'}>
                        {this.props.language}
                    </div> : null}
            </div>
        )
    }
}