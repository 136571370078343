import React, {Component} from "react";
import MyProfileCard from "./MyProfileCard";
import {Tab, Tabs} from "react-bootstrap";
import RemotingService from "../../../services/remoting-service/RemotingService";
import AppointmentTable from "./AppointmentTable";
import VisitTable from "./VisitTable";
import InsuranceInformation from "../../insurance/InsuranceInformation";
import DiscWallet from "../../profile/DiscWallet";
import PatientPageWrapper from "../../profile/PatientPageWrapper";

export default class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patient: null,
            selectedTab: "appointment"
        };
    }

    componentDidMount() {
        RemotingService.getRemoteCall(`/api/patient`, null, patient => {
            this.setState({patient});
        });
    }

    render() {
        const {patient} = this.state;

        return (
            <PatientPageWrapper activeItem={"DASHBOARD"}>
                <div className={'row mb-md-6 mb-4'}>
                    <div className={'col-12 col-xl-8'}>
                        <div className="mb-4">
                            <MyProfileCard
                                patient={patient}
                                onPatientUpdated={patient => this.setState({patient})}/>
                        </div>
                    </div>
                    <div className="col-12 col-xl-4">
                        <div className="mb-xl-3 mb-4">
                            <InsuranceInformation/>
                        </div>

                        <DiscWallet/>
                    </div>
                </div>
                <div className={'row mb-md-5'}>
                    <div className={'col-md-12'}>
                        <Tabs className="mpw-tabs"
                              defaultActiveKey="appointment"
                              onSelect={selectedTab => this.setState({selectedTab})}>
                            <Tab eventKey="appointment" title={<b>UPCOMING APPOINTMENTS</b>}>
                                {this.state.selectedTab === "appointment" && <AppointmentTable/>}
                            </Tab>
                            <Tab eventKey="visit" title={<b>VISIT HISTORY</b>}>
                                {this.state.selectedTab === "visit" && <VisitTable/>}
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </PatientPageWrapper>
        )
    }

}