import React, {Component} from "react";
import DateInput from "../../../components/date-input/DateInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "react-bootstrap";

class PatientSelection extends Component{

    constructor (props) {
        super(props)
        this.publicURL = process.env.PUBLIC_URL;
    }

    render() {
        return(
            <div className="container ">
                <div className={"row min-vh-100 pt-5"}>
                    <div className={"col-lg-5 mx-auto mt-5"}>
                        <div className={"col"}>
                            <h4 className="bordered-heading font-weight-bold">Choose a profile</h4>
                            <p>to continue to dashboard</p>
                        </div>
                        {this.props.patients.map(patient => {
                            return(
                                <div className={"col ml-2 mb-4 border"}>
                                    <div className={"row ml-2 mb-3 mt-3"} onClick={() => this.props.selectPatient(patient)}>
                                        <img src={patient.thumbnail ?
                                            'data:image/jpeg;base64,' + patient.thumbnail :
                                            this.publicURL + "../assets/images/clinic/visitor-registration.svg"}
                                             className="img-fluid rounded-circle" style={{margin: "5px", maxWidth: "50px", maxHeight: "50px"}} />
                                        <p className="text-center text-sm-left" style={{margin: "5px", marginTop: "20px"}}>{patient.fullname}</p>
                                    </div>
                                    {this.props.selectedPatient === patient && (
                                        <div className={"col ml-2 mb-3 mt-3"}>
                                            <h6 className="bordered-heading font-weight-bold">Confirm Date of Birth</h6>
                                            <div className={"row ml-2 mb-3 mt-3"}>
                                                <DateInput id="birthday"
                                                           className="form-control mb-1"
                                                           disableFuture={true}
                                                           value={this.props.confirmedBirthday}
                                                           onChange={(date) => this.props.patientDateChanged(date)}
                                                           style={{maxWidth: "80%", maxHeight: "80%"}}
                                                />
                                                <Button className="ml-2 mb-3 mt-2" style={{ maxHeight: "60%"}}
                                                        variant="secondary"
                                                        onClick={() => this.props.patientDateSubmitted(patient)} >
                                                    <FontAwesomeIcon icon={["fas", "check"]} />
                                                </Button>
                                            </div>

                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default PatientSelection