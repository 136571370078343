import React, {Component} from "react";
import InsuranceInformation from "./InsuranceInformation";
import InsuranceCardUpload from "./InsuranceCardUpload";
import PatientPageWrapper from "../profile/PatientPageWrapper";

export default class Insurance extends Component {
    render() {
        return (
            <PatientPageWrapper activeItem={"INSURANCE"}>
                <div className={"row"}>
                    <div className={"col-lg-6 mb-4 mb-lg-0"}>
                        <InsuranceInformation cardProps={"h-100"}/>
                    </div>
                    <div className={"col-lg-6"}>
                        <InsuranceCardUpload/>
                    </div>
                </div>
            </PatientPageWrapper>
        )
    }
}