import { getData } from './CmsService'

export const getLandingPageData = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/item.json?${process.env.REACT_APP_POST_FIX}&url=/site/items/SitePages/landingpage/index.xml`, callBack)
}

export const getSpecialityItemsLanding = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/SpecialtyCarousel/Landing`, callBack)
}

export const getOurApproachItems = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/OurApproach/Landing`, callBack)
}

export const getCareerPageData = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/item.json?${process.env.REACT_APP_POST_FIX}&url=/site/items/SitePages/careerpage/index.xml`, callBack)
}

export const getOurTeamLandingPageData = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/OurTeam/Landing`, callBack)
}

export const getInnovationLandingPageData = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/SitePages/OurApproach/Landing/innovationlanding`, callBack)
}

export const getIntegrationLandingPageData = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/SitePages/OurApproach/Landing/integrationlanding`, callBack)
}

export const getQualityAndSafetyLandingPageData = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/SitePages/OurApproach/Landing/qualityandsafetylanding`, callBack)
}



