import {BaseConstants} from "../constants/BaseConstants";

const initialState = {
    baseUrl: "",
    remotingOperationCount: 0
};

export function base(state = initialState, action) {
    if (action.type === 'SET_VALUE') {
        let newState = {...state};
        newState[action.key] = action.value;
        return newState;
    }

    if (action.type === BaseConstants.SCHEDULES) {
        let newState = {...state};
        newState["schedules"] = action.value;
        return newState;
    }

    if (action.type === BaseConstants.PRESCRIPTIONS) {
        let newState = {...state};
        newState["prescriptions"] = action.value;
        return newState;
    }

    if (action.type === 'INCREMENT_REMOTING_OPERATION_COUNT') {
        let newState = {...state};
        newState["remotingOperationCount"] = newState["remotingOperationCount"] + 1;
        return newState;
    }

    if (action.type === 'DECREMENT_REMOTING_OPERATION_COUNT') {
        let newState = {...state};
        newState["remotingOperationCount"] = newState["remotingOperationCount"] - 1;
        if (newState["remotingOperationCount"] < 0) {
            newState["remotingOperationCount"] = 0;
        }
        return newState;
    }

    return state;
}
