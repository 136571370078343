import React, { Component } from 'react'
import KuwaitClinicNavigationBar from '../../layout/KuwaitClinicNavigationBar'
import KuwaitTopBar from '../../layout/KuwaitTopBar'
import KuwaitTeam from '../corporatelandingpage/KuwaitTeam'
import YourAppointmentKuwait from '../corporatelandingpage/YourAppointmentKuwait'
import WhyChooseDISCKuwait from '../corporatelandingpage/WhyChooseDISCKuwait'
import BlogSection from '../corporatelandingpage/BlogSection'
import Subscription from '../corporatelandingpage/Subscription'
import OurServicesKuwait from '../corporatelandingpage/OurServicesKuwait'
import {
  getKuwaitLandingPageData,
  getOurApproachItemsKuwait,
  getSharingOurKnowledge,
  getSpecialityItemsKuwait,
  getYourAppointmentDataKuwait
} from '../../services/cms-services/KuwaitCmsService'

export default class KuwaitClinic extends Component {


    state = {
        cmsData: null,
        isLoaded: false,
    }

    componentDidMount() {
        getKuwaitLandingPageData(this.handleCmsData);
    }

    handleCmsData = (result) => {
        this.setState({cmsData: result, isLoaded: true})
    }


    render() {
        const cmsURL = process.env.REACT_APP_CMS_URL;
        const postFix = process.env.REACT_APP_POST_FIX;
        const {cmsData, isLoaded} = this.state;
        const data = cmsData?.descriptorDom?.page;
        return (

            <>
                {isLoaded ?
                    <>
                        <KuwaitTopBar/>
                        <KuwaitClinicNavigationBar/>
                        <div className="container-fluid introduction-section">
                            <div className="row align-items-center justify-content-end">
                                <div className="col-md-5 py-5">
                                    <h1 className="font-weight-bold">
                                        {data.kWITitle_t}
                                    </h1>
                                    <h5 className="mb-4" dangerouslySetInnerHTML={{__html: data.kWIDescription_html}}>
                                    </h5>
                                    {/*PatientPortalHide : <SlotSearch country={EnumCountry.KUWAIT}/>*/}
                                </div>
                                <div className="col-md-6 text-right">
                                    <img className="img-fluid" src={cmsURL + data.kWIBanner_s + "?" + postFix}/>
                                </div>
                            </div>
                        </div>

                        <div id="ourServices">
                            <OurServicesKuwait
                                ourServicesTitle={data.ourServicesTitle_t}
                                ourServicesDescription={data.ourServicesDescription_html}
                                getData={getSpecialityItemsKuwait}/>
                        </div>
                        <div class="container-fluid xxl-limiter">
                            <KuwaitTeam/>
                        </div>
                        <YourAppointmentKuwait getData={getYourAppointmentDataKuwait}/>
                        <WhyChooseDISCKuwait getData={getOurApproachItemsKuwait}/>
                        <BlogSection getData={getSharingOurKnowledge}/>
                        <Subscription/>
                        <div className="whatsapp-link">
                            <a data-action="open" data-phone="96599319488"
                               href="https://wa.me/96599319488" target="_blank"></a>
                        </div>
                    </>
                    : null}
            </>
        );
    }

}


