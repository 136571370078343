import { getData } from './CmsService'

export const getUAELandingPageData = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/item.json?${process.env.REACT_APP_POST_FIX}&url=/site/items/SitePages/uaepage/index.xml`, callBack)
}
export const getYourAppointmentDataDubai = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/YourAppointment/Dubai`, callBack)
}
export const getSharingOurKnowledge = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/SharingOurKnowledge`, callBack)
}
export const getSpecialityItemsDubai = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/SpecialtyCarousel/Dubai`, callBack)
}
export const getOurApproachItemsDubai = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/OurApproach/Dubai`, callBack)
}
export const getOurTeamUAEPageData = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/OurTeam/Dubai`, callBack)
}

export const getIntegrationUAEData = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/SitePages/OurApproach/Dubai/integrationdubai`, callBack)
}
export const getInnovationUAEData = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/SitePages/OurApproach/Dubai/innovationdubai`, callBack)
}
export const getQualityAndSafetyUAEData = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/SitePages/OurApproach/Dubai/qualityandsafetydubai`, callBack)
}
