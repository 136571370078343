import React from "react";
import {connect} from "react-redux";
import {Route} from "react-router-dom";
import LoginRegistration from "./login/LoginRegistration";
import {getWrappedComponent} from "../../layout/Layout";

function getTargetComponent(Component, props, rest) {
    const user = rest.user;

    if(user && (!rest.authority || (user.authorities && user.authorities.includes(rest.authority)))) {
        return <Component {...props} />
    } else {
        const NonAuthorizedComponent = getWrappedComponent(props.basePage, LoginRegistration)
        return <NonAuthorizedComponent {...props}/>
    }
}

function PrivateRoute({component: Component, ...rest}) {
    return (
        <Route
            {...rest}
            render={props => getTargetComponent(Component, props, rest)
            }
        />
    );
}

const mapStateToProps = state => {
    return {
        user: state.authentication.user
    };
};

export default connect(mapStateToProps)(PrivateRoute);
