import {withStyles} from "@material-ui/core";
import {Accordion as MUIAccordion} from "@material-ui/core";

const Accordion = withStyles({
  root: {
    position: "inherit",
    borderRadius: 8,
    '&:before': {
      height: 0
    },
  },
  rounded: {
    '&:last-child': {
      borderRadius: 8,
    }
  }
})(MUIAccordion);

export default Accordion;