import React, {Component} from "react";
import PropTypes from "prop-types";
import {noop} from "lodash-es";

export default class FileInput extends Component {

    // TODO add support for size restriction
    // TODO add support for multiple files when needed

    static defaultProps = {
        id: null,
        className: null,
        accept: null,
        onChange: noop
    };

    static propTypes = {
        id: PropTypes.string,
        className: PropTypes.string,
        accept: PropTypes.string,
        onChange: PropTypes.func
    }

    onChange(event) {
        const files = event.target.files;
        if (files && files.length > 0) {
            this.createFileDto(files[0]).then(fileDto => {
                this.props.onChange(fileDto);
                this.inputRef.value = "";
            });
        }
    }

    async createFileDto(file) {
        let fileType = file.type || 'application/octet-stream';
        let fileName = file.name || 'file';
        let fileSize = file.size;

        let reader = new FileReader();

        return new Promise ((resolve) => {
            reader.readAsDataURL(file);
            reader.onloadend = (evt) => {
                let dataAsDataUrl = evt.target.result;
                let base64String = dataAsDataUrl.replace(/^data:.+(;base64)?,/,'');
                resolve({
                    content: base64String,
                    mimeType: fileType,
                    name: fileName,
                    fileSize: fileSize
                });
            };
        });
    }

    render() {
        const {accept, children, className} = this.props;

        return (
            <>
                <label htmlFor={this.props.id} className={className}>
                    {children}
                </label>
                <input ref={ref => this.inputRef = ref}
                       id={this.props.id}
                       type="file"
                       hidden
                       accept={accept}
                       onChange={event => this.onChange(event)}/>
            </>
        )
    }

}