import moment from "moment";
import RemotingService from "../remoting-service/RemotingService";
import NotificationService from "../notification-service/NotificationService";
import {noop} from "lodash-es";

function getSchedules(callback) {
    RemotingService.getRemoteCall('api/scheduling/schedule', null, (schedules) => {
        callback(schedules);
    }, noop(), false);
}

function retrieveSchedulingData(callback) {
    const specialityPromise = new Promise((resolve, reject) => {
        RemotingService.getRemoteCall('api/speciality/list', null, (specialities) => {
            resolve(specialities);
        });
    });

    const roomPromise = new Promise((resolve, reject) => {
        RemotingService.getRemoteCall('api/room/list-all', null, (rooms) => {
            resolve(rooms);
        })
    });

    const staffPromise = new Promise((resolve, reject) => {
        RemotingService.getRemoteCall('api/staff/list-base', null, (result) => {
            resolve(result.items);
        })
    });

    Promise.all([specialityPromise, roomPromise, staffPromise]).then((values) => {
        callback({specialities: values[0], rooms: values[1], staffs: values[2]});
    });

}

function showWarning(summary, detail) {
    NotificationService.showNotification({
        severity: 'warn',
        summary,
        detail
    });
}

// Check staff's schedule for given start-end times and return a room if any available.
function checkStaffScheduleAndGetRoom(schedule, staff, start, end) {
    let room = null;

    if (!staff) {
        showWarning('Staff Selection Required', 'Staff could not be selected."');
        return room;
    }

    if (start < new Date()) {
        showWarning('Invalid Start Time', 'Please select a time in future.');
        return room;
    }

    let staffSchedule = schedule[staff.id];
    let currDay = moment(start).format('YYYYMMDD');

    let daySchedule = staffSchedule[currDay];
    let inSchedule = false;

    if (daySchedule && daySchedule.blocks.length > 0) {
        daySchedule.blocks.forEach(block => {
            if (start.getTime() >= block.startTime.getTime() && end.getTime() <= block.endTime.getTime()) {
                room = block.roomDto;
                inSchedule = true;
            }
        });
    }

    if (!inSchedule) {
        showWarning('Invalid For Staff Schedule', 'Please select a slot that suits staff\'s schedule.')
    }

    return room;
}

function getRotation(callback) {
    RemotingService.getRemoteCall('api/scheduling/rotation', null, (rotation) => {
        callback(rotation);
    });
}

function generateRotation(callback) {
    RemotingService.postRemoteCall('api/scheduling/rotation', null, (rotation) => {
        callback(rotation);
    });
}

function confirmRotation(rotationId, callback) {
    RemotingService.postRemoteCall(`api/scheduling/rotation/${rotationId}/confirm`, null, (rotation) => {
        callback(rotation);
    });
}

function rejectRotation(rotationId, callback) {
    RemotingService.postRemoteCall(`api/scheduling/rotation/${rotationId}/reject`, null, (rotation) => {
        callback(rotation);
    });
}

export default {
    getSchedules,
    retrieveSchedulingData,
    checkStaffScheduleAndGetRoom,
    getRotation,
    generateRotation,
    confirmRotation,
    rejectRotation
};
