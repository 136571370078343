import React, {Component} from "react";
import "./VisitTable.scss";
import Modal from "../../../components/modal/Modal";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import AttachmentUtils from "../../../utils/AttachmentUtils";
import RemotingService from "../../../services/remoting-service/RemotingService";
import {Image} from "react-bootstrap";
import DateUtil from "../../../utils/DateUtil";

export default class VisitDocumentsModal extends Component {

    constructor(props) {
        super(props);
        this.state = {documents: []};
    }

    componentDidMount() {
        const {visit} = this.props;
        RemotingService.getRemoteCall('api/patient/medical-report/' + visit.id, {}, (files) => {
            this.setState({documents: files});
        })
    }

    viewDocument = (document) => {
        AttachmentUtils.downloadAsBlob(document.uuid, blob => {
            AttachmentUtils.viewFile(document.name, blob);
        });
    }

    downloadDocument = (document) => {
        AttachmentUtils.download(document);
    }

    documentActionsTemplate = (document) => {
        return (
            <div className="d-flex flex-row ">
                <a href="#" className="bg-light-green" onClick={() => this.viewDocument(document)}>
                    <i className="icon size-1x icon-eye nile-green ">
                    </i>
                </a>
                <a href="#" className="bg-light-green"  onClick={() => this.downloadDocument(document)}>
                    <i className="icon size-1x icon-download-file nile-green bg-light-green">
                    </i>
                </a>

            </div>
        );
    }

    render() {
        const {documents} = this.state;
        const {visit} = this.props;
        const staff = visit.staff;
        const photoSrc = staff.thumbnail ?
            'data:image/jpeg;base64,' + staff.thumbnail :
            process.env.PUBLIC_URL + '/assets/images/clinic/doctors/default.png';

        return (
            <Modal visible={true} closeAction={this.props.onClose}>

                    <div className="row border-bottom">
                        <div className="col-md-12">

                            <h5 className="font-weight-bold mt-2 d-flex align-items-center"><i className="icon-documents icon size-3x"></i>My Documents</h5>
                        </div>
                    </div>
                    <div className="row bg-light-grey-100 border-bottom py-3">
                        <div className="col-md-6">
                            <div className="d-flex">
                                <Image src={photoSrc} className="staff-photo avatar-size-sm"/><br/>
                                <div className="d-flex flex-column">
                                    <span className="font-weight-bold">{staff.name}</span>
                                    <span className="small-font">{staff.specialityName}</span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex col-md-6 mt-md-0 mt-3">
                            <div className="row">
                                <div className="col-md-auto">
                                    <div className="d-flex">
                                        <i className="icon-calendar icon size-2x"></i>
                                        <div className="d-flex flex-column ml-1 small-font">
                                <span
                                    className="">{DateUtil.formatDate(visit.dateTime, 'DD MMMM YYYY')}</span>
                                            <span className="font-weight-bold text-uppercase">
                                    {DateUtil.formatDate(visit.dateTime, 'dddd')}
                                </span>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-auto">
                                    <div className="d-flex">
                                        <i className="icon-time icon size-2x"></i>
                                        <span className="small-font mt-2 ml-1">{DateUtil.getHourMinute12H(visit.dateTime)}</span>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <DataTable responsive autoLayout
                                   emptyMessage="No documents"
                                   className="mpw-datatable w-100"
                                   value={documents}>
                            <Column header="Document Name" field="name"/>
                            <Column header="Document Date" field="date"/>
                            <Column header="Actions" body={this.documentActionsTemplate}/>
                        </DataTable>
                    </div>

            </Modal>
        );
    }

}
