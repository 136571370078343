import Enum from "../../../enums/Enum";

const EnumTimeOfDay = {
  UAE: new Enum({
    EARLY_MORNING: {name: "Early", dayPart: "Morning", timeRange: "07:00 - 09:00"},
    MID_MORNING: {name: "Mid", dayPart: "Morning", timeRange: "09:00 - 11:00"},
    LATE_MORNING: {name: "Late", dayPart: "Morning", timeRange: "11:00 - 12:00"},
    EARLY_AFTERNOON: {name: "Early", dayPart: "Afternoon", timeRange: "13:00 - 15:00"},
    MID_AFTERNOON: {name: "Mid", dayPart: "Afternoon", timeRange: "15:00 - 17:00"},
    LATE_AFTERNOON: {name: "Late", dayPart: "Afternoon", timeRange: "17:00 - 19:00"},
  }),
  KUWAIT: new Enum({
    MID_MORNING: {name: "Mid", dayPart: "Morning", timeRange: "09:00 - 10:30"},
    LATE_MORNING: {name: "Late", dayPart: "Morning", timeRange: "10:30 - 12:00"},
    EARLY_AFTERNOON: {name: "Early", dayPart: "Afternoon", timeRange: "12:00 - 15:00"},
    MID_AFTERNOON: {name: "Mid", dayPart: "Afternoon", timeRange: "15:00 - 18:30"},
    LATE_AFTERNOON: {name: "Late", dayPart: "Afternoon", timeRange: "18:30 - 20:00"},
  })
};

EnumTimeOfDay.UAE.morningTimes = [EnumTimeOfDay.UAE.EARLY_MORNING, EnumTimeOfDay.UAE.MID_MORNING, EnumTimeOfDay.UAE.LATE_MORNING];
EnumTimeOfDay.UAE.afternoonTimes = [EnumTimeOfDay.UAE.EARLY_AFTERNOON, EnumTimeOfDay.UAE.MID_AFTERNOON, EnumTimeOfDay.UAE.LATE_AFTERNOON];

EnumTimeOfDay.KUWAIT.morningTimes = [EnumTimeOfDay.KUWAIT.MID_MORNING, EnumTimeOfDay.KUWAIT.LATE_MORNING];
EnumTimeOfDay.KUWAIT.afternoonTimes = [EnumTimeOfDay.KUWAIT.EARLY_AFTERNOON, EnumTimeOfDay.KUWAIT.MID_AFTERNOON, EnumTimeOfDay.KUWAIT.LATE_AFTERNOON];

export default EnumTimeOfDay;