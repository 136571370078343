import React, {Component} from "react";
import EnumTimeOfDay from "../../../../user/enums/TimeOfDay";
import BestTimeInputs from "./BestTimeInputs";
import CommunicationMethod from "./CommunicationMethod";

export default class InsuranceFullyCoversViewDetail extends Component {

    state = {
        cmsData: null,
        isLoaded: false,
    }

    componentDidMount() {
        const {getData} = this.props;
        getData(this.handleCmsData);
    }

    handleCmsData = (result) => {
        this.setState({cmsData: result, isLoaded: true})
    }


    render() {
        const morningBestTimes = EnumTimeOfDay.UAE.morningTimes;
        const afternoonBestTimes = EnumTimeOfDay.UAE.afternoonTimes;
        const {cmsData, isLoaded} = this.state;
        let data = null;

        if (isLoaded) {
            data = cmsData[0].descriptorDom.page;
        }

        return (
            <>
                {
                    isLoaded ?
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <h3 className="font-weight-bold">{data.directBillingTitle_t}</h3>
                                    <div
                                        dangerouslySetInnerHTML={{__html: data.directBillingDescriptionSection1_html || data.directBillingDescriotionSection1_html}}></div>
                                </div>
                            </div>
                            <div
                                dangerouslySetInnerHTML={{__html: data.directBillingDescriptionSection2_html || data.directBillingDescriotionSection2_html}}></div>
                            <div>
                                <h5 className="font-weight-bold pt-3">{data.communicationPreferenceTitle_t}</h5>
                                <CommunicationMethod communicationMethods={this.props.preferences.communicationMethods}
                                                     handleMultiSelectionChange={this.props.handleMultiSelectionChange}/>
                                <h5 className="font-weight-bold pt-4">{data.bestTimeToContactTitle_t}</h5>
                                <BestTimeInputs time={"MORNING"} bestTimes={morningBestTimes}
                                                bestTimesToContact={this.props.preferences.bestTimesToContact}
                                                handleMultiSelectionChange={this.props.handleMultiSelectionChange}/>
                                <BestTimeInputs time={"AFTERNOON"} bestTimes={afternoonBestTimes}
                                                bestTimesToContact={this.props.preferences.bestTimesToContact}
                                                handleMultiSelectionChange={this.props.handleMultiSelectionChange}/>
                            </div>
                        </div>
                        : null
                }
            </>
        );
    }
}