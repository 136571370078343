import Enum from "../../../enums/Enum";

const EnumSpecialStatus = new Enum({
    MALE: "Male Clinician only",
    FEMALE: "Female Clinician only",
    VIP: "VIP",
    ELEVATOR: "Elevator user",
    WHEELCHAIR: "Wheelchair user",
    WHATSAPP: "Whatsapp user",
    LANGUAGE: "Language limit"
});

export default EnumSpecialStatus;