import React from "react";

export default function ContactInformation({country}) {
    const phoneNumber = country == null ? "" : country === 'UAE' ? '+971 4 215 6888' : '+965 22205331';

    return (
        <div
            className="insurance-warning d-flex flex-column justify-content-center align-items-center mt-3 pt-3 pb-3">
            <span>Need help - please contact us on</span>
            {
                country && <div className="small d-flex flex-row align-items-center phone-number-wrapper mt-3">
                    <i className="icon size-2x icon-phone-circle disc-green"
                       style={{transform: "rotate(-90deg)"}}/>
                    <span className="pr-1">{phoneNumber}</span>
                </div>
            }
        </div>
    );
}