import React, {Component} from "react";
import {Column} from "primereact/column";
import DateUtil from "../../../utils/DateUtil";
import EnumAppointmentType from "../../appointment/enums/EnumAppointmentType";
import QueryPanel from "../../../components/query/QueryPanel";
import ResultTable from "../../../components/query/ResultTable";
import {Image} from "react-bootstrap";
import "./AppointmentTable.scss";
import TimezoneUtil from "../../../utils/TimezoneUtil";
import EnumAppointmentStatus from "../../appointment/enums/EnumAppointmentStatus";
import RemotingService from "../../../services/remoting-service/RemotingService";
import EnumCancellationReason from "../../appointment/enums/EnumCancellationReason";
import Tooltip from "../../../components/tooltip/Tooltip";
import EnumAppointmentSource from "../../appointment/enums/EnumAppointmentSource";

export default class AppointmentTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            appointments: []
        };
    }

    cancelAppointment = (id) => {
        RemotingService.postRemoteCall(`/api/appointment/${id}/cancel`, null,()=>{
            this.appointmentQueryPanel.resetAndQuery()
        })
    }

    render() {
        return (
            <QueryPanel url="/api/appointment/list"
                        paginator={false}
                        queryOptions={this.state.queryOptions}
                        ref={el => this.appointmentQueryPanel = el}>
                <ResultTable className="mpw-datatable appointment-datatable">
                    <Column header="Clinician" body={appointment => this.clinicianTemplate(appointment)} style={{width: '30%'}}/>
                    <Column header="Date" field="startTime" sortable body={appointment => this.dateTemplate(appointment)}/>
                    <Column header="Time" body={appointment => this.timeTemplate(appointment)}/>
                    <Column header="Clinic" body={appointment => this.clinicTemplate(appointment)}/>
                    <Column header="Type" body={appointment => this.patientTypeTemplate(appointment)}/>
                    <Column header="Source" body={appointment => this.sourceTemplate(appointment)}/>
                    <Column header="Actions" body={appointment => this.actionsTemplate(appointment)}/>
                </ResultTable>
            </QueryPanel>
        )
    }

    clinicianTemplate(appointment) {
        const staff = appointment.staff;
        const thumbnail = staff.thumbnail ?
            'data:image/jpeg;base64,' + staff.thumbnail :
            process.env.PUBLIC_URL + '/assets/images/clinic/doctors/default.png';

        return <div className="d-flex">
            <Image src={thumbnail} className="staff-photo-smaller"/>
            <div className="align-self-center">
                <div className="font-weight-bold">{staff.name}</div>
                <div className="">{staff.specialityName}</div>
            </div>
        </div>
    }

    dateTemplate(appointment) {
        const start = TimezoneUtil.atClinicZone(appointment.start, appointment.room.clinicName);
        return <div className="d-flex">
            <i className="icon size-2x icon-calendar align-self-center"></i>
            <div className="ml-2 align-self-center">
                <div>{DateUtil.formatDateMMM(appointment.start)}</div>
                <div className="font-weight-bold text-uppercase">{DateUtil.formatDate(start, "dddd")}</div>
            </div>
        </div>;
    }

    timeTemplate(appointment) {
        const start = TimezoneUtil.atClinicZone(appointment.start, appointment.room.clinicName);
        return <div className="d-flex">
            <i className="icon size-2x icon-time align-self-center"></i>
            <div className="ml-2 align-self-center">{DateUtil.formatDate(start, "hh:mm A")}</div>
        </div>
    }

    clinicTemplate(appointment) {
        return <div className="d-flex">
            <i className="icon size-2x icon-location align-self-center"></i>
            <div className="ml-2 align-self-center">{appointment.room.clinicName}</div>
        </div>
    }

    patientTypeTemplate(appointment) {
        return EnumAppointmentType[appointment.patientType].name;
    }

    sourceTemplate(appointment) {
        return appointment.source ? EnumAppointmentSource[appointment.source] : '';
    }

    actionsTemplate(appointment) {
        if (["BOOKED", "APPROVED"].includes(appointment.status)) {
            return (
                <div className="d-flex">
                    <button className="ml-1 btn appointment-red-button"
                            onClick={() => this.cancelAppointment(appointment.id)}>
                        <i className="icon size-1x icon-cancel-appointment"/>
                        Cancel
                    </button>
                </div>
            );
        } else {
            return (this.getActionTemplateForCompletedAndCancelled(appointment));
        }
    }

    getActionTemplateForCompletedAndCancelled (appointment) {
        const {status, cancellationReason} = appointment;
        let statusText = EnumAppointmentStatus[appointment.status].name;
        const cancellationTime = TimezoneUtil.atClinicZone(appointment.cancellationTime, appointment.room.clinicName);
        let icon = null;

        if(status == EnumAppointmentStatus.CANCELLED.key){
            statusText = EnumCancellationReason[cancellationReason]?.name
            icon = (<i className={"icon disc-red size-sm " + EnumCancellationReason[cancellationReason]?.iconClass}/>);
        } else if (status == EnumAppointmentStatus.COMPLETED.key) {
            icon = (<i className="icon icon-check-circle-filled disc-green size-1x"/>);
        }

        return (
            <Tooltip title={<>Cancelled within 6hrs of <br/> appointment time</>} disableHoverListener={!(status == EnumAppointmentStatus.CANCELLED.key && cancellationReason == EnumCancellationReason.LATE.key)}>
                <div className="d-flex">
                    {icon}
                    <div className="d-flex flex-column">
                        <div className="d-flex">{statusText}</div>
                        <p>{appointment.cancellationTime ? DateUtil.formatDate(cancellationTime, "DD.MM.YYYY / hh:mm A") : ""}</p>
                    </div>
                </div>
            </Tooltip>

        )
    }
}