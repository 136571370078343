import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import FormField from "../../../../components/form/FormField";
import Divider from "@material-ui/core/Divider";
import ValidationUtil from "../../../../components/form/validator/ValidationUtil";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import InputWrapper from "../InputWrapper";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import Select from "react-select";
import ObjectUtil from "../../../../utils/ObjectUtil";
import ContactInformation from "../common/ContactInformation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class InsuranceDetailView extends Component {

  constructor(props) {
    super(props);
    this.publicURL = process.env.PUBLIC_URL;

    this.countryList = [
      {label: 'Dubai', value: 'UAE'},
      {label: 'Kuwait', value: 'KUWAIT'},
    ];

    this.noTpaOption = {id: null, name: "Not Applicable"};

    this.state = {
      insurance: {},
      tpaList: [],
      insuranceCompanyList: [],
      insuranceNetworkList: [],
      policyList: [],
    }
  }

  componentDidMount() {
    this.retrieveInsurance();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.country !== prevProps.country) {
      this.retrieveInsurance();
    }
  }

  retrieveTPAs = () => {
    const country = this.props.country || (this.state.insurance.country ? this.state.insurance.country.value : null);

    if (country) {
      RemotingService.getRemoteCall(`/api/insurance/public/tpa/${country}/list`, null, (tpaList) => {
        tpaList.unshift(this.noTpaOption);
        this.setState({tpaList});
      });
    }
  }

  retrieveInsurance = () => {
    RemotingService.getRemoteCall('/api/insurance', null, (res) => {
      const result = ObjectUtil.nvl(res, {});
      result.tpa = result.tpa? result.tpa : this.noTpaOption;

      if(this.props.country && this.props.country !== result.country) {
        this.handleCountryChange({value: this.props.country});
      } else {
        const insurance = {
          ...result,
          country: result.country ?
              this.countryList.find(country => country.value === result.country) : null,
          tpa: result.tpa ?
              {
                label: result.tpa.name,
                value: result.tpa
              } :
              (result.customTpa ? {
                label: result.customTpa,
                value: {value: result.customTpa}
              } : null),
          company: result.company ?
              {
                label: result.company.name,
                value: result.company
              } :
              (result.customCompany ? {
                label: result.customCompany,
                value: {value: result.customCompany}
              } : null),
          network: result.network ?
              {
                label: result.network.name,
                value: result.network
              } :
              (result.customNetwork ? {
                label: result.customNetwork,
                value: {value: result.customNetwork}
              } : null),
          policyName: result.policyName ?
              {
                label: result.policyName,
                value: {name: result.policyName}
              } : null
        }

        this.setState({insurance}, () => {
          this.retrieveTPAs();
          if (this.state.insurance.tpa) {
            this.retrieveInsuranceCompanies(() => this.retrieveAcceptedNetworks(this.retrievePolicies));
          }
        });
      }
      ValidationUtil.clear(this.formFields);
    });
  }

  retrieveInsuranceCompanies = (callback) => {
    const tpa = this.state.insurance.tpa?.value;
    const country = this.state.insurance.country?.value;
    if (tpa) {
      RemotingService.getRemoteCall(
          '/api/insurance/public/tpa/company',
          {tpaId: tpa.id, country: country},
          insuranceCompanyList => {
            this.setState({insuranceCompanyList}, callback);
          });
    } else {
      this.setState({insuranceCompanyList: [], insuranceNetworkList: [], policyList: []});
    }
  }

  retrieveAcceptedNetworks = (callback) => {
    const company = this.state.insurance.company?.value;
    if (company && company.id) {
      RemotingService.getRemoteCall(
          '/api/insurance/public/company/' + company.id + '/accepted-networks',
          null,
          insuranceNetworkList => {
            this.setState({insuranceNetworkList}, callback);
          });
    } else {
      this.setState({insuranceNetworkList: [], policyList: []});
    }
  }

  retrievePolicies = () => {
    const network = this.state.insurance.network?.value;
    if (this.state.insurance.country?.value === "KUWAIT" && network && network.id) {
      RemotingService.getRemoteCall(
          '/api/insurance/public/network/' + network.id + '/policy',
          null,
          policyList => {
            this.setState({policyList});
          });
    } else {
      this.setState({policyList: []});
    }
  }

  handleCountryChange = (country) => {
    const insurance = {
      country,
      tpa: null,
      company: null,
      network: null,
      policyName: null,
      policyNumber: null,
      memberId: null
    };
    this.setState(prevState => (
        {
          insurance: {
            ...prevState.insurance,
            ...insurance
          }
        }), () => {
      this.retrieveTPAs();
      this.retrieveInsuranceCompanies();
      ValidationUtil.clear(this.formFields);
    });
  }

  handleTpaChange = (tpa) => {
    const insurance = {
      tpa,
      company: null,
      customCompany: null,
      network: null,
      customNetwork: null,
      policyName: null,
      policyNumber: null,
      memberId: null
    };
    this.setState(prevState => (
        {
          insurance: {
            ...prevState.insurance,
            ...insurance,
            customTpa: tpa == null ? null : insurance.customTpa
          }
        }), () => {
      this.retrieveInsuranceCompanies();
      ValidationUtil.clear(this.formFields);
    });
  }

  handleInsuranceCompanyChange = (company) => {
    const prevInsurance = this.state.insurance;

    const insurance = {
      tpa: prevInsurance.tpa,
      company,
      network: null,
      customNetwork: null,
      policyName: null,
      policyNumber: null,
      memberId: null
    };
    this.setState(prevState => (
        {
          insurance: {
            ...prevState.insurance,
            ...insurance,
            customCompany: company == null ? null : insurance.customCompany
          }
        }), () => {
      this.retrieveAcceptedNetworks();
      ValidationUtil.clear(this.formFields);
    });
  }

  handleInsuranceNetworkChange = (network) => {
    const prevInsurance = this.state.insurance;

    const insurance = {
      tpa: prevInsurance.tpa,
      company: prevInsurance.company,
      network,
      policyName: null,
      policyNumber: null,
      memberId: null
    };
    this.setState(prevState => (
        {
          insurance: {
            ...prevState.insurance,
            ...insurance,
            customNetwork: network == null ? null : insurance.customNetwork
          }
        }), () => {
      this.retrievePolicies();
      ValidationUtil.clear(this.formFields);
    });
  }

  handleInsurancePolicyChange = (policyName) => {
    const prevInsurance = this.state.insurance;

    const insurance = {
      ...prevInsurance,
      policyName
    };

    this.setState({
      insurance
    });
  }

  handleChange = (field, value, callback) => {
    this.setState(prevState => (
        {
          insurance: {
            ...prevState.insurance,
            [field]: value
          }
        }), callback);
  }

  formatEmptyLabel = (text) => {
    return (
        <div className="d-flex flex-row justify-content-between">
          <div><FontAwesomeIcon icon={["fas", "info-circle"]} /> <span >{text}</span></div>
          <div><FontAwesomeIcon icon={["fas", "plus-circle"]} /> <span className="optional">Add and Continue</span></div>
        </div>
    )
  }

  renderPolicyInput = () => {

    const {
      insurance,
    } = this.state;

    return (<>
      {insurance.country?.value === 'KUWAIT' ?
          <InputWrapper label="Policy Name">
            <CreatableSelect
                name="policyName"
                className="w-100"
                formatCreateLabel={(text) => `Select Custom Policy Name: ${text}`}
                isClearable
                value={this.state.insurance.policyName}
                onChange={this.handleInsurancePolicyChange}
                onCreateOption={(text) => this.onCreateOption("policyList", this.handleInsurancePolicyChange, text,{name: text})}
                options={this.state.policyList.map(option => {
                  return {
                    label: option.name,
                    value: option,
                  }})
                }
                placeholder="Select or Enter Policy Name"
                classNamePrefix={"react-select"}
            />
          </InputWrapper>
          : null}
      {insurance.country?.value === 'UAE' ?
          <FormField ref={formField => this.formFields.push(formField)} minLength={9} maxLength={9} validateOn={insurance.policyNumber}>
            <InputWrapper
                label="Policy No."
            >
              <input className="w-100 number-input"
                     placeholder="9-Digit Policy Number"
                     value={insurance.policyNumber || ''}
                     onChange={(e) =>
                         this.handleChange('policyNumber', e.target.value)
                     }
              />
            </InputWrapper>
          </FormField>
          : null} </>);
  }

  validateAndConfirm = () => {
    if (!ValidationUtil.isValid(this.formFields)) {
      return;
    }

    const country = this.state.insurance.country?.value;
    const tpa = this.state.insurance.tpa?.value;
    const company = this.state.insurance.company?.value;
    const network = this.state.insurance.network?.value;
    const policyName = this.state.insurance.policyName?.value;

    const customInsurance = {
      ...this.state.insurance,
      country,
      tpa : tpa?.id ? tpa : null,
      customTpa: tpa?.id ? null : tpa?.value,
      company : company?.id ? company : null,
      customCompany: company?.id ? null : company?.value,
      network : network?.id ? network : null,
      customNetwork: network?.id ? null : network?.value,
      policyName: policyName?.name,
    }


    this.props.handler(customInsurance);
  }


  onCreateOption = (optionsName, setValueFunc, label, value) => {
    const newOption = {label, value};
    setValueFunc(newOption);
  }

  render() {
    this.formFields = [];

    const {
      insurance,
    } = this.state;

    return (
        <Grid className="insurance-detail">
          {!this.props.hideCountry &&
          <Grid item className="mx-3">
            <FormField ref={formField => this.formFields.push(formField)} required validateOn={insurance.country}>
              <InputWrapper
                  required
                  label="Please Select Your Location"
              >
                <Select
                    value={insurance.country}
                    onChange={this.handleCountryChange}
                    options={this.countryList}
                    className="w-100"
                    placeholder="Select your Country"
                />
              </InputWrapper>
            </FormField>
          </Grid>
          }
          <Grid item className="mx-3">
            <FormField ref={formField => this.formFields.push(formField)} required
                       validateOn={insurance.tpa || insurance.customTpa}>
              <InputWrapper required label="Confirm Your TPA">
                <CreatableSelect
                    name="tpa"
                    className="w-100"
                    formatCreateLabel={() => this.formatEmptyLabel("Your TPA is not listed")}
                    isClearable
                    value={this.state.insurance.tpa}
                    onChange={this.handleTpaChange}
                    onCreateOption={(text) => this.onCreateOption("tpaList", this.handleTpaChange, text, {value: text})}
                    options={this.state.tpaList.map(option => {
                      return {
                        label: option.name,
                        value: option,
                      }})
                    }
                    placeholder="Select or Enter TPA"
                    classNamePrefix={"react-select"}
                />
              </InputWrapper>
            </FormField>
          </Grid>
          <Grid item className="mx-3">
            <FormField ref={formField => this.formFields.push(formField)} required
                       validateOn={insurance.company || insurance.customCompany}>
              <InputWrapper label="Your Insurance" required>
                <CreatableSelect
                    name="company"
                    className="w-100"
                    formatCreateLabel={() => this.formatEmptyLabel("Your insurance is not listed")}
                    isClearable
                    value={this.state.insurance.company}
                    onChange={this.handleInsuranceCompanyChange}
                    onCreateOption={(text) => this.onCreateOption("insuranceCompanyList", this.handleInsuranceCompanyChange, text, {value: text})}
                    options={this.state.insuranceCompanyList.map(option => {
                      return {
                        label: option.name,
                        value: option,
                      }})
                    }
                    placeholder="Select or Enter Insurance Company"
                    classNamePrefix={"react-select"}
                />
              </InputWrapper>
            </FormField>
          </Grid>
          <Grid item className="mx-3">
            <InputWrapper label="Confirm Your Network">
              <CreatableSelect
                  name="network"
                  className="w-100"
                  formatCreateLabel={() => this.formatEmptyLabel("Your network is not listed")}
                  isClearable
                  value={this.state.insurance.network}
                  onChange={this.handleInsuranceNetworkChange}
                  onCreateOption={(text) => this.onCreateOption("insuranceNetworkList", this.handleInsuranceNetworkChange, text, {value: text})}
                  options={this.state.insuranceNetworkList.map(option => {
                    return {
                      label: option.name,
                      value: option,
                    }})
                  }
                  placeholder="Select or Enter Network"
                  classNamePrefix={"react-select"}
              />
            </InputWrapper>
          </Grid>
          <Grid item className="mx-3">
            <Grid container direction="row" spacing={2}>
              <Grid item md={6} xs={12}>
                {this.renderPolicyInput()}
              </Grid>
              <Grid item md={6} xs={12}>
                <FormField ref={formField => this.formFields.push(formField)} required
                           validateOn={insurance.memberId}>
                  <InputWrapper required label="Member ID">
                    <input
                        className="w-100 number-input"
                        placeholder="Insurance Member ID"
                        value={insurance.memberId || ''}
                        onChange={(e) => this.handleChange('memberId', e.target.value)}
                    />
                  </InputWrapper>
                </FormField>
              </Grid>
            </Grid>
          </Grid>
          <Divider/>
          <Grid item className="mx-3">
            <Grid container justify="flex-end">
              <button className="square-btn btn btn-secondary appointment-button selected px-3 py-3 mt-3"
                      onClick={this.validateAndConfirm}>Confirm
              </button>
            </Grid>
          </Grid>
          <ContactInformation country={insurance?.country?.value} />
        </Grid>
    );
  }
}