import React from 'react';
import {withStyles} from "@material-ui/core";
import clsx from "clsx";

function PWStepIcon(props) {
    const classes = props.classes;
    const { active, completed } = props;


    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {props.icon}
        </div>
    );
}

const styles = {
    root: {
        background: "#FFFFFF",
        border: "2px solid #E2E2E2",
        boxSizing: "border-box",
        zIndex: 1,
        color: '#6D6E72',
        fontWeight: 'normal',
        width: 64,
        height: 64,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontSize: 24,
        lineHeight: '29px',
    },
    active: {
        color: '#8CD0C1',
        border: "2px solid #8CD0C1",
    },
    completed: {
        color: '#ffffff',
        border: "2px solid #8CD0C1",
        background: "#8CD0C1",
    },
};

export default withStyles(styles)(PWStepIcon);