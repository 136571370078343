import React, {Component} from 'react';
import PhoneNumber from "./PhoneNumber";

class DubaiPhoneNumber extends Component {
    render() {
        return (
            <PhoneNumber number={"04 215 6888"} {...this.props}/>
        );
    }
}

export default DubaiPhoneNumber;