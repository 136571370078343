export const getOurServicesData = (service, page, callback, getQuery = getGraphQLQuery) => {

    const header = new Headers();
    header.append("Content-Type", "application/json");
    header.append("Authorization", "Bearer eyJhbGciOiJQQkVTMi1IUzUxMitBMjU2S1ciLCJlbmMiOiJBMjU2Q0JDLUhTNTEyIiwiY3R5IjoiSldUIiwicDJjIjo4MTkyLCJwMnMiOiIzS0h1THg1bTlCZXc3Tk5jIn0.IVYxSo0Z3qqul9hR_xdh-NRoOc692B_l-4w2FMA87aD3C_WvEQJ8GgMWgkNJKiBMzXRDY5ACAKqCmD6E71hDuFSkX8ConlxP.D6RMZFh5SESPqCbBCLc0FQ.wzloRHOlXNFyRYG6E6jsIZIc5RncqRDaDtXbeHy_JukHMPEEEHsWvZtSKJMvVlitvTtYi_fWW3kFDjUogN9pz2Z718HBMD098VJEJ7YkeO7EsgpKozaRWQD8NDaCNr7x9XhkT1Uk1qBXllqqJlUl49HzTKRQuEV2XZ23wff7pw28bOOhhJgz5VEnm7PXPPnImTV45vkyASJburPXU0vAkYl_I_LhS-nvyyyouAxQJ4-s72lLz57-5rVeCZID3IMuSlkQI4gUAJV5T1Dr5y-CpeWgoRjtixH7ycwBR2KyRcYPi-xckhT_rQU7KQ0NLSD1fzykAX8b8Yxwimpjfy6eWg.hCYw4jHBn85ONNG-qycnXp2YK6cWHXBLRcRxZTZZWNA");

    const requestOptions = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(getQuery(service, page)),
        redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_CMS_URL}/api/1/site/graphql?${process.env.REACT_APP_POST_FIX}`, requestOptions)
        .then(response => response.text())
        .then(result => {
            callback(JSON.parse(result))
        })
        .catch(error => console.log('error', error));
}

const getGraphQLQuery = (service, page) => {
    const cmsFields = [
        `internal__name(filter: {equals: "${service}"})`,
        "title_t",
        "banner_s",
        "description_html_raw",
        "details_html_raw",
        "pageURL_s",
        "conditionsWeTreatTitle_t",
        "conditionsWeTreatDescription_html_raw",
        "treatmentsWeOfferTitle_t",
        "treatmentsWeOfferDEscription_html_raw",
        `localId(filter: {regex: ".*${page}.*"})`,
        "medaiSpecialityList_o {item {specialityName_t}}",
        "disclaimerTitle_t",
        "disclaimerDescription_t",
    ];

    return {
        query: `query ServicesQuery {component_speciality{items{${cmsFields.join(" ")}}}}`
    };
}

export const getCorporateWellnessGraphQLQuery = (service, page) => {
    const cmsFields = [
        `internal__name(filter: {equals: "${service}"})`,
        "title_t",
        "banner_s",
        "description_html_raw",
        "whatToExpectTitle_t",
        "whatToExpectDescription_html_raw",
        "programsWeOfferTitle_t",
        "objectivesTitle_t",
        "objectivesDescription_html_raw",
        `localId(filter: {regex: ".*${page}.*"})`,
        "programsWeOfferTitle1_o {item {programName_t programDesc_t}}",
        "programsWeOfferTitle2_o {item {programName_t programDesc_t}}",
        "programsWeOffer3Title_o {item {programName_t programDesc_t}}",
        "programsWeOffer4Title_o {item {programName_t programDesc_t}}",
        "disclaimerTitle_t",
        "disclaimerDescription_t",
    ];

    return {
        query: `query CorporateWellnessQuery {page_specialtycorporatewellness{items{${cmsFields.join(" ")}}}}`
    };
}

