import React, {Component} from 'react'
import DiscWalletItem from './DiscWalletItem'
import RemotingService from "../../services/remoting-service/RemotingService";

class DiscWallet extends Component {

    constructor(props) {
        super(props);
        this.state = {
            discWalletItems: []
        };
    }

    componentDidMount() {
        RemotingService.getRemoteCall(`/api/credit/list`, null, credits => {
            this.setState({discWalletItems: credits});
        });
    }

    render() {
        return (
            <div className="card">
                <div className={'row mx-0 h-100'}>
                    <div className={'col-lg-auto d-flex justify-content-center flex-row flex-lg-column py-lg-4 px-lg-4 profile-title-green-background'}>
                        <i className="icon size-4x icon-wallet align-self-center nile-green"></i>
                        <div className={'font-weight-bolder small-font nile-green align-self-center'}>DISC WALLET</div>
                    </div>
                    <div className={'col-12 col-lg d-flex p-0'}>
                        {this.state.discWalletItems && this.state.discWalletItems.length > 1 ?
                            <DiscWalletCarousel discWalletItems={this.state.discWalletItems}/> :
                            <DiscWalletItem
                                discWallet={this.state.discWalletItems ? this.state.discWalletItems[0] : null}/>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

class DiscWalletCarousel extends Component {
    render() {
        return (
            <div id="discWalletCarousel" className="carousel slide w-100 h-100" data-ride="carousel"
                 data-interval="false">

                <div className="carousel-inner">
                    {this.props.discWalletItems.map((discWallet, index) => {
                        return (
                            <div className={'carousel-item h-100 ' + (index == 0 ? ' active' : '')}>
                                <DiscWalletItem discWallet={discWallet}/>
                            </div>
                        )
                    })}

                </div>
                <ol className="carousel-indicators profile-carousel-indicators mt-2 mb-0">
                    {this.props.discWalletItems.map((discWallet, index) => {
                        return (
                            <li data-target="#discWalletCarousel"
                                data-slide-to={index}
                                className={index == 0 ? ' active' : ''}></li>
                        )
                    })}
                </ol>
                <a className="carousel-control-prev justify-content-start" href="#discWalletCarousel" role="button"
                   data-slide="prev">
                    <i className="icon size-2x icon-chevron-left align-self-center nile-green"></i>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next justify-content-end" href="#discWalletCarousel" role="button"
                   data-slide="next">
                    <i className="icon size-2x icon-chevron-right align-self-center nile-green"></i>
                    <span className="sr-only">Next</span>
                </a>

            </div>
        )
    }
}

export default DiscWallet