import React, {Component} from 'react';
import {Modal} from "react-bootstrap";

class FooterInformationModal extends Component {
    state = {
        data: null
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {retrieveDataFunction} = this.props;
        if (prevProps.retrieveDataFunction !== retrieveDataFunction && retrieveDataFunction != null) {
            retrieveDataFunction((result) => {
                this.setState({data: result[0]?.descriptorDom?.page?.content_html})
            })

        }
    }

    render() {
        const {show, closeModal, title} = this.props;
        const {data} = this.state;

        return (
            <>
                {data && <Modal
                    className="fade"
                    id="information-modal"
                    size={'xl'}
                    show={show}
                    closeAction={closeModal}
                >
                    <Modal.Header>
                        <h5 class="modal-title font-weight-bold" id="ReferredFromModalTitle">
                            <i class="icon icon-documents size-2x"></i>
                            {title}
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={closeModal}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container-fluid">
                            <div dangerouslySetInnerHTML={{__html: data}}/>
                        </div>
                    </Modal.Body>
                </Modal>}
            </>
        );
    }
}

export default FooterInformationModal;