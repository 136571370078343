import React, {Component} from "react";
import ClinicNavigationBar from "../../layout/ClinicNavigationBar";
import TopBar from "../../layout/TopBar";
import {getYourVisitDataDubai} from "../../services/cms-services/OurAppointmentCmsService";
import YourAppointmentSideMenuDubai from "./YourAppointmentSideMenuDubai";

const cmsURL = process.env.REACT_APP_CMS_URL;

class YourAppointmentDetail extends Component {
    state = {
        cmsData: null,
        isLoaded: false
    }

    componentDidMount() {
        getYourVisitDataDubai(this.handleCmsData);

    }

    handleCmsData = (result) => {
        this.setState({cmsData: result, isLoaded: true})
    }

    render() {
        const {cmsData, isLoaded} = this.state;
        let data = null;
        if (isLoaded && cmsData) {
            data = cmsData[0].descriptorDom.page;
        }

        return (
            <>
                <TopBar/>
                <ClinicNavigationBar/>
                {isLoaded ? <div className="your-appointment-container container-fluid py-md-5 xxl-limiter">
                    <div className="row min-vh-100 flex-column-reverse flex-md-row justify-content-end">
                        <div className="col-md-8 offset-md-1">
                            <div className="d-flex flex-column">
                                <h2 className="bordered-heading font-weight-bold">{data.yourVisitTitle_t}</h2>
                                <div dangerouslySetInnerHTML={{__html: data.yourVisitDescription_html}}/>
                                <h5 className={"font-weight-bold mt-2 mb-0"}>{data.yourAppointmentTitle_t}</h5>
                                <div dangerouslySetInnerHTML={{__html: data.yourAppointmentDescription_html}}/>
                            </div>
                            <h5 className="font-weight-bold mt-2">{data.missedAppointmentAndCancellationTitle_t}</h5>
                            <div
                                dangerouslySetInnerHTML={{__html: data.missedAppointmentAndCancellationSectionOneDescription_html}}/>
                            <h6 className="font-weight-bold d-block">{data.cancellationPolicyTitle_t}</h6>
                            <div className={"d-block"}>
                                <a
                                    href={cmsURL + data.cancellationPolicyDubaiEnglish_o.item[0].value + "?crafterSite=disc-corporate"}
                                    target="_blank"
                                    className="btn btn-outline-secondary mr-2"
                                >
                                    English
                                    <i className="icon icon-download-file size-1x"></i>

                                </a>
                                <a
                                    href={cmsURL + data.cancellationPolicyDubaiArabic_o.item[0].value + "?crafterSite=disc-corporate"}
                                    target="_blank"
                                    className="btn btn-outline-secondary">

                                    Arabic
                                    <i className="icon icon-download-file size-1x"></i>
                                </a>
                            </div>
                            <p className="font-italic mt-3"
                               dangerouslySetInnerHTML={{__html: data.missedAppointmentAndCancellationSectionTwoDescription_html}}/>
                            <h5 className="font-weight-bold">{data.dressCodeTitle_t}</h5>
                            <div dangerouslySetInnerHTML={{__html: data.dressCodeDescription_html}}/>
                        </div>
                        <div className="col-md-3 p-0">
                            <YourAppointmentSideMenuDubai visitActive="menu-active"/>
                        </div>
                    </div>
                </div> : null}
            </>
        );
    }

}

export default YourAppointmentDetail;
