import React, {Component} from 'react';
import EnumCountry from "../modules/user/enums/EnumCountry";
import DubaiPhoneNumber from "./DubaiPhoneNumber";
import KuwaitPhoneNumber from "./KuwaitPhoneNumber";

export const getPhoneNumberComponent = (clinicCountry, customProps = {}) => {
    switch (clinicCountry) {
        case EnumCountry.UAE.key:
            return <DubaiPhoneNumber {...customProps}/>;
        case EnumCountry.KUWAIT.key:
            return <KuwaitPhoneNumber {...customProps}/>;
        default:
            return null;
    }
}

class PhoneNumber extends Component {
    render() {
        const {number, containerClass = "mr-2 mr-sm-4"} = this.props;

        return (
            <a href={"tel:" + number} className={containerClass}>
                <i className="icon size-2x icon-phone-circle disc-green"></i>
                <div className="d-sm-flex">
                    <div>
                        <span className="font-weight-bold">Call Us</span>
                    </div>
                    <div>
                        <span className="font-weight-normal ml-1">{number}</span>
                    </div>
                </div>
            </a>
        );
    }
}

export default PhoneNumber;