import React, { Component } from 'react'

class Career extends Component {
  constructor(props) {
    super(props);
    this.publicURL = process.env.PUBLIC_URL;
    this.cmsURL = process.env.REACT_APP_CMS_URL;
    this.postFix = process.env.REACT_APP_POST_FIX;
  }

  render() {
    const {careerTitle, careerDescription, careerBanner, careerURL } = this.props;
    return (
      <div className="container-fluid pt-5 p-3 mb-md-5">
        <div className="row bg-light-grey-100 d-flex align-items-center career-banner
        ">
          <div className={"col-auto p-0"}>
            <img className="img-fluid" src={this.cmsURL + careerBanner + "?" + this.postFix}  alt="career"/>
          </div>
          <div className="col d-flex justify-content-center p-4">
            <div className="d-flex flex-column">
              <h1 className="font-weight-bold" id="career">
                {careerTitle}
              </h1>
              <h4  dangerouslySetInnerHTML={{__html: careerDescription}}></h4>
              <div>
                <a
                    href={careerURL || '#' }
                    className="btn btn-secondary my-4"
                >
                  View Open Positions
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Career;
