import React, {Component} from "react";
import PropTypes from "prop-types";
import _uniqueId from "lodash/uniqueId";
import {Button} from "react-bootstrap";
import {Dialog, DialogActions, DialogContent} from "@material-ui/core";
import DialogTitle from "../dialog/DialogTitle";
import {noop} from "lodash-es";
import "./Modal.css";

export default class Modal extends Component {
    static defaultProps = {
        id: null,
        header: null,
        headerTextVisible: false,
        body: null,
        disableBackdropClick: false,
        footer: null,
        visible: false,
        modal: true,
        style: null,
        paperStyle: null,
        contentStyle: null,
        className: "",
        submitContent: null,
        submitDisabled: false,
        submitAction: null,
        closeContent: null,
        closeDisabled: false,
        closeAction: null,
        maxWidth:"lg",
        additionalActions: null,
        dialogActions: null
    };

    static propTypes = {
        id: PropTypes.string,
        header: PropTypes.any,
        headerTextVisible: PropTypes.bool,
        body: PropTypes.any,
        disableBackdropClick: PropTypes.bool,
        footer: PropTypes.any,
        visible: PropTypes.bool,
        modal: PropTypes.bool,
        style: PropTypes.object,
        paperStyle: PropTypes.object,
        contentStyle: PropTypes.object,
        className: PropTypes.string,
        submitContent: PropTypes.any,
        submitDisabled: PropTypes.bool,
        submitAction: PropTypes.func,
        closeContent: PropTypes.any,
        closeDisabled: PropTypes.bool,
        closeAction: PropTypes.func,
        maxWidth: PropTypes.string,
        additionalActions: PropTypes.object,
        dialogActions: PropTypes.element
    };

    constructor(props){
        super(props);
        if(props.id){
            this.id = props.id;
        }
        else{
            this.id = _uniqueId("dim14-modal-id-");
        }
        if(props.submitButtonId){
            this.submitButtonId = props.submitButtonId;
        }
        else{
            this.submitButtonId = _uniqueId("dim14-modal-submit-button-id-");
        }
        if(props.closeButtonId){
            this.closeButtonId = props.closeButtonId;
        }
        else{
            this.closeButtonId = _uniqueId("dim14-modal-close-button-id-");
        }
    }

    render() {
        let actions, submit, close,headerTextColor;
        headerTextColor = "#000000";
        if (this.props.headerTextVisible){
            headerTextColor = "#F4F4F4";
        }
        if(this.props.footer){
            actions = this.props.footer;

            if (this.props.closeAction) {
                close = this.props.closeAction;
            }

        }
        else {
            if (this.props.submitAction) {
                submit = this.props.submitAction;
            }

            if (this.props.closeAction) {
                close = this.props.closeAction;
            }

            if(this.props.submitContent && this.props.closeContent) {
                actions = <div className="dim14modal dim14modal-footer">
                    <Button className = "modalYesOption" disabled={this.props.submitDisabled} variant="success"
                               onClick={submit} id={this.submitButtonId} >{this.props.submitContent}</Button>
                    <Button  className = "modalNoOption" disabled={this.props.closeDisabled} variant="warning"
                               onClick={close} id={this.closeButtonId} >{this.props.closeContent}</Button>
                </div>;
            }
            if (this.props.submitContent && !this.props.closeContent) {
                actions = <div className="dim14modal dim14modal-footer">
                    <Button disabled={this.props.submitDisabled} variant="success"
                               onClick={submit} id={this.submitButtonId} >{this.props.submitContent}</Button>
                </div>;
            }
            if (this.props.closeContent && !this.props.submitContent) {
                actions = <div className="dim14modal dim14modal-footer">
                    <Button disabled={this.props.closeDisabled} variant="warning"
                               onClick={close} id={this.closeButtonId} >{this.props.closeContent}</Button>
                </div>;
            }
            if (!this.props.closeContent && !this.props.submitContent) {
                actions = null;
            }
        }
        let componentClassName = "dim14modal " + this.props.className;
        let dialogContainer = document.getElementById('dialog-container');

        return (
            <Dialog
                id={this.id}
                fullScreen={this.props.fullScreen}
                open={this.props.visible}
                disableBackdropClick={this.props.disableBackdropClick}
                onClose={this.props.disableBackdropClick ? {noop} : close}
                style={this.props.style}
                className={componentClassName}
                maxWidth={this.props.maxWidth}
                container={dialogContainer}
                PaperProps={{style: {overflowY: 'visible', ...this.props.paperStyle}}}
            >
                {this.props.header ? <DialogTitle textColor={headerTextColor} onClose={close}>{this.props.header}</DialogTitle> : null}
                <DialogContent style={{overflowY: 'visible', ...this.props.contentStyle}}>{this.props.children}</DialogContent>
                {
                    this.props.dialogActions ? this.props.dialogActions : (
                        this.props.submitContent || this.props.additionalActions || actions ?
                            <DialogActions style={{...this.props.footerStyle}}>{actions} {this.props.additionalActions}</DialogActions> : null
                    )
                }
            </Dialog>
        );
    }
}
