import React, {Component} from 'react';
import {Growl as PrimeReactGrowl} from 'primereact/growl';
import {connect} from 'react-redux';

class Growl extends Component {

    handleOnClick = (growlObject) => {
      if(growlObject.onClick){
          growlObject.onClick();
      }
    };

    componentDidUpdate(){
        if(this.props.growlObject){
            let growlObject = this.props.growlObject;
            let severity = "success";
            let sticky = false;
            let life = 6000;
            if(growlObject.sticky){
                sticky = growlObject.sticky;
            }
            if(growlObject.life){
                life = growlObject.life;
            }
            if(growlObject.severity){
                severity = growlObject.severity;
            }

            this.growl.show({sticky : sticky,
                life : life,
                severity: severity,
                summary: growlObject.summary,
                detail: growlObject.detail,
                onClick:() => {this.handleOnClick(growlObject)}
            });
        } else {
            this.growl.clear();
        }

    }

    render(){
        return <PrimeReactGrowl baseZIndex={1000} ref={(el) => this.growl = el} onClick={(e) => {e.onClick()}} />;
    }

}

const mapStateToProps = state => {
    return {
        growlObject : state.alert
    };
};

export default connect(mapStateToProps)(Growl);
