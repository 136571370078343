import React, { Component } from 'react'

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.publicURL = process.env.PUBLIC_URL;
    this.cmsURL = process.env.REACT_APP_CMS_URL;
    this.postFix = process.env.REACT_APP_POST_FIX;
  }

  render() {
    const {aboutUsBanner, aboutUsTitle, aboutUsDescription } = this.props;
    return (
      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-6">
            <img className="img-fluid" src={this.cmsURL + aboutUsBanner + "?" + this.postFix}  alt="about-us"/>
          </div>
          <div className="col-lg-6 pt-5">
            <h2 className="bordered-heading font-weight-bold" id={"aboutUs"}>
              {aboutUsTitle}
            </h2>
            <div dangerouslySetInnerHTML={{__html: aboutUsDescription}}></div>

          </div>
        </div>
      </div>
    );
  }
}

export default AboutUs;
