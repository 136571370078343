import React, {Component} from "react";
import {withRouter} from "react-router";
import {connect} from 'react-redux';
import {userActions} from "../services/actions";
import RemotingService from "../services/remoting-service/RemotingService";
import clsx from 'clsx';
import {getPhoneNumberComponent} from "./PhoneNumber";

const listItems = [
    {key: "DASHBOARD", link: "/dashboard", iconClass: "icon-grid", label: "Dashboard"},
    {key: "MY_PROFILE", link: "/myprofile", iconClass: "icon-person", label: "Profile"},
    {key: "INSURANCE", link: "/insurance", iconClass: "icon-insurance", label: "Insurance"},
    {
        key: "APPOINTMENT_HISTORY",
        link: "/appointment-history",
        iconClass: "icon-appointment-calendar",
        label: "Appointments"
    },
].map(li => {
    return {...li, iconSize: "size-lg", mobileIconSize: "size-sm"}
})


class AppointmentAppNavBar extends Component {

    constructor(props) {
        super(props);
        this.publicURL = process.env.PUBLIC_URL;
        this.state = {
            patient: {}
        };
    }

    componentDidMount() {
        // TODO prevent patient to be fetched for every page navigation
        RemotingService.getRemoteCall(`/api/patient`, null, patient => {
            this.setState({patient});
        });
    }

    handleClick = event => {
        this.setState({anchorEl: event.currentTarget})
    };

    handleClose = () => {
        this.setState({anchorEl: null})
    };

    handleViewProfile = () => {
        this.handleClose();
        this.props.history.push("/dashboard");
    };

    handleLogout = () => {
        this.props.dispatch(userActions.logout());
        this.props.history.push("/login");
    };

    handleListItemClick = () => {
        const {isMobile, handleMenuClick} = this.props;
        if(isMobile) {
            handleMenuClick();
        }
    }

    render() {
        const {clinicCountry, activeItem, isMenuCollapsed} = this.props;
        return (
            <>
                {!isMenuCollapsed &&
                <>
                    <div className={"patient-left-bar"}>
                        <ul className={"link-list mx-3 mx-md-0"}>
                            {listItems.map(li => (
                                <li className={"link-item"} onClick={() => this.handleListItemClick()}>
                                    <a className={li.key == activeItem ? "active" : ""} href={li.link}>
                                        <i className={clsx("icon", li.iconClass, li.iconSize)}/>
                                        <label className="d-block d-md-none mb-0 ml-2">{li.label}</label>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={"d-flex flex-column d-md-none w-100 pb-3 border-bottom"}>
                        <a className="add-appointment mx-5 my-2 py-1 justify-content-center" href="new-appointment">
                            <i className={"icon size-2x icon-calendar-checked"}></i> Add Appointment
                        </a>
                        <div className="align-self-center phone-number">
                            {getPhoneNumberComponent(clinicCountry, {containerClass: "d-flex align-items-center mr-2 mr-sm-4"})}
                        </div>
                    </div>
                </>}
            </>
        );
    }

}

function mapStateToProps(state) {
    return {
        user: state.authentication.user
    };
}

export default withRouter(connect(mapStateToProps)(AppointmentAppNavBar));
