import React from "react";
import ReactDOM from "react-dom";
import {noop} from "lodash-es";
import {Modal} from 'react-bootstrap'

export default function dialog({component, onClose = noop, scrollable = false, size}) {

    const dialogContainer = document.getElementById('dialog-container');
    const dialogDiv = dialogContainer.appendChild(document.createElement('div'));

    function dispose() {
        setTimeout(() => {
            ReactDOM.unmountComponentAtNode(dialogDiv);
            setTimeout(() => {
                if (dialogContainer.contains(dialogDiv)) {
                    dialogContainer.removeChild(dialogDiv)
                }
            });
        }, 200);
    }

    function closeDialog() {
        dispose();
        onClose();
    }

    function modal() {
        return (
            <Modal show={true}
                   scrollable={scrollable}
                   size={size}
                   onHide={() => closeDialog()}>
                {
                    React.cloneElement(component, {
                        close: () => closeDialog()
                    })
                }
            </Modal>
        )
    }

    ReactDOM.render(modal(), dialogDiv);

}