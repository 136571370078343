import './BestPossibleSlots.scss'
import React, { Component } from 'react'
import RemotingService from '../../../services/remoting-service/RemotingService'
import { Image } from 'react-bootstrap'
import DateUtil from '../../../utils/DateUtil'
import { withRouter } from 'react-router-dom'
import TimezoneUtil from '../../../utils/TimezoneUtil'
import Modal from '../../../components/modal/Modal'
import SelectBestPossibleSlot from './SelectBestPossibleSlot'
import AuthService from '../../../services/auth-service/AuthService'
import queryString from 'query-string'
import ObjectUtil from '../../../utils/ObjectUtil'
import PreferencesView from '../new/view/preferences/PreferencesView'
import { cloneDeep, noop } from 'lodash-es'
import WindowUtil from '../../../utils/WindowUtil'
import PatientNavigationBar from '../../../layout/PatientNavigationBar'
import ClinicNavigationBar from '../../../layout/ClinicNavigationBar'
import { connect } from 'react-redux'
import { onlyUnique } from '../../../utils/ArrayUtil'
import NotificationService from '../../../services/notification-service/NotificationService'

class BestPossibleSlots extends Component {

    state = {
        showPreferences: false,
        staffList: [],
        clinicList: [],
        bestSlots: []
    }

    componentDidMount() {
        RemotingService.getRemoteCall('api/staff/public/list', null, (staffList) => {
            this.setState({staffList});
        });

        RemotingService.getRemoteCall('/api/public/clinic/list', null, (clinicList) => {
            this.setState({clinicList});

            const user = AuthService.getUser();
            const authenticatedUserFlow = user && user.token;
            let {
                clinics,
                bestTimes,
                specialities,
                staffs,
                languages,
                genders
            } = queryString.parse(this.props.location.search);

            clinics = clinics ? clinicList.filter(c => clinics.split(',').find(id => id == c.id)) : []; // === breaks search
            bestTimes = bestTimes ? bestTimes.split(',') : [];
            languages = languages ? languages.split(',') : [];
            genders = genders ? genders.split(',') : [];
            specialities = (specialities && specialities.length > 0) ? specialities.split(',') : [];
            staffs = (staffs && staffs.length > 0) ? staffs.split(',') : [];

            if (authenticatedUserFlow) {
                RemotingService.getRemoteCall('api/patient/preferences', null,
                    (preferences) => {
                        // prefs screen cannot display unless otherwise?
                        preferences.dateOfVisit = new Date(preferences.dateOfVisit);

                        // let query parameters override saved preferences
                        preferences.clinics = clinics.length ? clinics : preferences.clinics;
                        preferences.bestTimes = clinics.bestTimes ? clinics : preferences.bestTimes;
                        preferences.languages = clinics.languages ? clinics : preferences.languages;
                        preferences.genders = clinics.genders ? clinics : preferences.genders;
                        preferences.specialities = specialities.length > 0 ? specialities : preferences.specialities;
                        preferences.staffs = staffs.length > 0 ? staffs : preferences.staffs;

                        this.setState({authenticatedUserFlow, preferences, preferencesClone: cloneDeep(preferences)},
                            this.retrieveBestPossibleSlots);
                    });

            } else {
                const preferences = {
                    dateOfVisit: new Date(),
                    clinics,
                    specialities,
                    bestTimes,
                    languages,
                    genders,
                    staffs
                };

                this.setState({authenticatedUserFlow, preferences, preferencesClone: cloneDeep(preferences)},
                    this.retrieveBestPossibleSlots);
            }
        });
    }

    retrieveBestPossibleSlots = () => {
        if (this.state.preferences.clinics
            && this.state.preferences.clinics.map(clinic => clinic.country).filter(onlyUnique).length > 1) {

            NotificationService.showNotification({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'Locations in same country can be selected.'
            });
            return;
        }

        RemotingService.postRemoteCall(
            '/api/appointment/public/best-slots', this.state.preferences, (bestSlots) => {
                bestSlots.map(slot => {
                    slot.startTime = TimezoneUtil.atClinicZone(slot.startTime, slot.clinicName);
                    slot.endTime = TimezoneUtil.atClinicZone(slot.endTime, slot.clinicName);
                });
                this.setState({bestSlots});
            });
    };

    backwardsSearch = () => {
        const date = DateUtil.addDays(this.state.preferences.dateOfVisit, -5);
        const today = new Date();
        this.state.preferences.dateOfVisit = DateUtil.isSameOrAfter(date, today) ? date : today;
        this.retrieveBestPossibleSlots();
    }

    forwardsSearch = () => {
        this.state.preferences.dateOfVisit = DateUtil.addDays(this.state.preferences.dateOfVisit, 5);
        this.retrieveBestPossibleSlots();
    }

    getStaff = (staffId) => {
        return this.state.staffList.find(s => s.id === staffId);
    }

    openInNewTab = (event, url) => {
        event.preventDefault();
        WindowUtil.openInNewTab(url);
    }

    getDirectionsLink = (staff) => {
        return `${staff.dubaiStaff ? "dubai" : "kuwait"}/our-locations`;
    }

    getStaffProfileLink = (staff) => {
        return '/doctorprofile/' + staff.id;
    }

    selectSlot = (slot, staff) => {
        this.setState({selectedSlot: slot, selectedStaff: staff, showSelectedSlotModal: true});
    }

    closeSelectedSlotModal = () => {
        this.setState({ showSelectedSlotModal: false })
        this.retrieveBestPossibleSlots()
    }

    getNumberOfSelectedCriteria = () => {
        let count = 0;
        const preferences = this.state.preferences || {};

        count += preferences.dateOfVisit ? 1 : 0;
        count += preferences.appointmentType ? 1 : 0;
        count += preferences.speciality ? 1 : 0;
        count += preferences.clinics && preferences.clinics.length ? 1 : 0;
        count += preferences.bestTimes && preferences.bestTimes.length ? 1 : 0;
        count += preferences.languages && preferences.languages.length ? 1 : 0;
        count += preferences.genders && preferences.genders.length ? 1 : 0;

        return count;
    }

    togglePatientPreferences = () => {
        this.setState({showPreferences: !this.state.showPreferences});
    }

    // TODO changes methods are duplicated from PatientPreferences component
    handleChange = (field, value, callback = noop) => {
        this.setState(prevState => ({preferencesClone: {...prevState.preferencesClone, [field]: value}}), callback);
    }

    handleClinicChange = (clinics) => {
        this.setState(prevState => ({preferencesClone: {...prevState.preferencesClone, clinics, bestTimes: []}}));
    }

    handleMultiSelectionChange = (field, enumValue, checked, callback) => {
        this.setState(prevState => {
            const selectedItems = prevState.preferencesClone[field];
            if (checked) {
                selectedItems.push(enumValue.key);
            } else {
                selectedItems.splice(selectedItems.indexOf(enumValue.key), 1);
            }
            return ({preferencesClone: {...prevState.preferencesClone, [field]: selectedItems}});
        }, () => {
            if (callback != null && typeof callback == "function") {
                callback(this.state.preferencesClone[field])
            }
        });
    }

    applyPreferences = () => {
        const {authenticatedUserFlow, preferencesClone} = this.state;


        const clinicList = [...this.state.clinicList];
        let clinics = preferencesClone.clinics ? preferencesClone.clinics.map(clinicId => clinicList.find(clinic => clinic.id == clinicId)) : [];
        const preferences = {...cloneDeep(preferencesClone), clinics: clinics};

        if (authenticatedUserFlow) {
            RemotingService.postRemoteCall(`/api/patient/preferences/update`, preferences);
        }

        this.setState({preferences: preferences}, () => {
            this.togglePatientPreferences();
            this.retrieveBestPossibleSlots();
        });
    }

    renderDayHeader = (date) => {
        return <div className="col-2 d-flex flex-column text-center font-weight-bold">
            <span className="small-font nile-green">{DateUtil.formatDate(date, 'ddd')}</span>
            <span className="x-small-font mid-grey-400">{DateUtil.formatDate(date, 'MMM DD')}</span>
        </div>
    }

    renderStaffSlotCardHeader = (title, backgroundColor, startDate) => {
        return (
            <div className={"row align-items-center border-bottom-top " + backgroundColor}>
                <h4 className="col-lg-4 pl-5 py-3 font-weight-bold">{title}</h4>
                <div className="col-lg-7 text-uppercase">
                    <div className="row tight-gutters">
                        <a href="#" className="col-1 p-0" onClick={this.backwardsSearch}>
                            <i className="icon size-2x icon-chevron-left-circle nile-green"/>
                        </a>
                        {this.renderDayHeader(startDate)}
                        {this.renderDayHeader(DateUtil.addDays(startDate, 1))}
                        {this.renderDayHeader(DateUtil.addDays(startDate, 2))}
                        {this.renderDayHeader(DateUtil.addDays(startDate, 3))}
                        {this.renderDayHeader(DateUtil.addDays(startDate, 4))}
                        <a href="#" className="col-1 p-0" onClick={this.forwardsSearch}>
                            <i className="icon size-2x icon-chevron-right-circle nile-green"/>
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    renderDaySlots = (slots, staff, date) => {
        const daySlots = slots ? ObjectUtil.sortByDate(slots.filter(slot => DateUtil.isSameDay(slot.startTime, date)), "startTime") : [];

        return <div className="col-2 d-flex flex-column text-center">
            {!daySlots.length ? <div className="available-slot-separator"/> :
                daySlots.map((slot, i) =>
                    <button key={"slot-" + i}
                            className="btn btn-secondary light-green-button available-slot"
                            onClick={() => this.selectSlot(slot, staff)}>
                        {DateUtil.getHourMinute12H(slot.startTime)}
                    </button>)
            }
        </div>
    }

    renderStaffSlotCard = (staffId, slots, startDate, separator = false) => {
        const staff = this.getStaff(staffId);
        const photoSrc = staff.thumbnail ?
            'data:image/jpeg;base64,' + staff.thumbnail :
            process.env.PUBLIC_URL + '/assets/images/clinic/doctors/default.png';

        const directionsLink = this.getDirectionsLink(staff);
        const staffProfileLink = this.getStaffProfileLink(staff);

        return <>
            <div className="row flex-column flex-lg-row py-3 best-slot-list-item">
                <div className="col-lg-4 d-flex flex-row staff-info">
                    <div className="card">
                        <Image src={photoSrc} className="staff-photo"/><br/>
                        <div className="d-flex flex-column">
                            <span className="font-weight-bold mid-grey-400">{staff.name}</span>
                            <span className="small-font mid-grey-400">{staff.specialityName}</span>
                            <span className="small-font mid-grey-400 mt-1">{staff.address}</span>
                            <div className="mt-3">
                                <a href={directionsLink} className="staff-link-button"
                                   onClick={(event) => this.openInNewTab(event, directionsLink)}>
                                    <i className="icon size-1x icon-directions-linear"/>
                                    Get Directions
                                </a>
                                <a href={staffProfileLink} className="staff-link-button"
                                   onClick={(event) => this.openInNewTab(event, staffProfileLink)}>
                                    <i className="icon size-1x icon-person"/>
                                    View Profile
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col-lg-7 best-slots-time">
                    <div className="row tight-gutters">
                        <span class="col-1"></span>
                        {this.renderDaySlots(slots, staff, startDate)}
                        {this.renderDaySlots(slots, staff, DateUtil.addDays(startDate, 1))}
                        {this.renderDaySlots(slots, staff, DateUtil.addDays(startDate, 2))}
                        {this.renderDaySlots(slots, staff, DateUtil.addDays(startDate, 3))}
                        {this.renderDaySlots(slots, staff, DateUtil.addDays(startDate, 4))}
                    </div>

                </div>
            </div>
        </>
    }

    renderStaffSlotNotFound = (best) => {
        return (
            <div className="d-flex flex-column align-items-center py-3">
                {this.props.remotingOperationCount === 0 ?
                    <>
                        <span>Sorry, no result found based on your preference. Please change preference and retry.</span>
                        <button
                            className="btn btn-secondary light-green-button mt-3"
                            onClick={this.togglePatientPreferences}>
                            <i className="icon icon-preferences size-1x"></i>Change Preferences
                        </button>

                    </> :
                    <span>{`Searching for ${best ? 'the best' : 'alternative'} slots...`}</span>
                }
            </div>
        );
    }

    render() {
        const {
            clinicList, staffList, preferences, preferencesClone, bestSlots, showSelectedSlotModal,
            selectedSlot, selectedStaff, authenticatedUserFlow
        } = this.state;

        if (!clinicList.length || !staffList.length) {
            return <></>;
        }

        const startDate = preferences && preferences.dateOfVisit || new Date();
        const staffSlots = {};
        const staffIds = [];
        const slotsFound = bestSlots && bestSlots.length > 0;
        const numberOfSelectedCriteria = this.getNumberOfSelectedCriteria();

        if (slotsFound) {
            bestSlots.map(slot => {
                if (!staffSlots[slot.staffId]) {
                    staffSlots[slot.staffId] = [];
                    staffIds.push(slot.staffId);
                }
                staffSlots[slot.staffId].push(slot);
            })
        }

        return (
            <>
                <div className="container-fluid">
                    <div className={"row"}>
                        {authenticatedUserFlow ? <PatientNavigationBar hideSideBar/> : <ClinicNavigationBar/>}
                    </div>
                </div>
                <div className={`content-offset mt-3${!authenticatedUserFlow ? " pt-0" : ""}`}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <button className="btn btn-secondary light-green-button"
                                        onClick={this.togglePatientPreferences}>
                                    <i className="icon size-1x icon-preferences"></i>
                                    Change Preferences
                                </button>
                                {numberOfSelectedCriteria > 0 &&
                                    <span className="ml-3 nile-green"
                                          onClick={this.togglePatientPreferences}>{numberOfSelectedCriteria} selected</span>}
                            </div>

                        </div>
                        <div className="flex-row row mt-4 best-slots-wrapper">
                            {this.state.showPreferences &&
                                <div className="col-lg-5 col-12 preferences-drawer">
                                    <PreferencesView preferences={preferencesClone} embeddedMode
                                                     clinicList={clinicList}
                                                     handleChange={this.handleChange}
                                                     handleClinicChange={this.handleClinicChange}
                                                     handleMultiSelectionChange={this.handleMultiSelectionChange}
                                                     updatePreferencesFunc={this.applyPreferences}
                                                     country={preferences?.clinics[0]?.country}/>
                                </div>
                            }
                            <div className={this.state.showPreferences ? "col-12" : "col-12"}>
                                    {this.renderStaffSlotCardHeader(
                                        'Your best slots', 'bg-light-green', startDate)}
                                    {slotsFound ?
                                        this.renderStaffSlotCard(staffIds[0], staffSlots[staffIds[0]], startDate) :
                                        this.renderStaffSlotNotFound(true)
                                    }
                                <div>
                                    {this.renderStaffSlotCardHeader(
                                        'Your alternative slots', 'bg-light-grey-100', startDate)}
                                    {slotsFound ?
                                        staffIds.map((staffId, i) => {
                                            if (i > 0) {
                                                return this.renderStaffSlotCard(
                                                    staffId, staffSlots[staffId], startDate, i !== staffIds.length - 1);
                                            }
                                        }) :
                                        this.renderStaffSlotNotFound(false)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {showSelectedSlotModal &&

                        <Modal visible={true} fullScreen closeAction={this.closeSelectedSlotModal}>
                            <div className="appointment-confirmation">
                            <SelectBestPossibleSlot slot={selectedSlot} staff={selectedStaff}
                                                    preferences={preferences} changeSlotAction={this.closeSelectedSlotModal}/>
                            </div>
                        </Modal>


                }
            </>
        );
    }

}

const mapStateToProps = state => {
    return {
        remotingOperationCount: state.base.remotingOperationCount
    };
};

export default withRouter(connect(mapStateToProps, null)(BestPossibleSlots));
