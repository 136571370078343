// From https://www.gitmemory.com/issue/intljusticemission/react-big-calendar/1478/538528088

import moment from "./moment";

export const currentTimezone = moment.tz.guess();
/**
 * This will create a 'moment' object that *is* moment.tz(), and automatically use the
 * 'timezone' used when you called 'getMoment()'
 */
export const getMoment = (timezone = currentTimezone) => {
    const m = (...args) => moment.tz(...args, timezone);
    m.localeData = moment.localeData;
    return m;
};

/**
 * 'datetime' is a JS Date object
 * 'tzMoment is the 'moment' object you got from 'getMoment()'
 */
export const convertDateTimeToDate = (datetime, tzMoment) => {
    return new Date(tzMoment(datetime).format()); // sets Date using ISO 8601 format
};

// *not* using this
export const convertDateToDateTime = (date, timezone) => {
    const m = moment.tz(date, timezone);
    return moment.tz(
        {
            year: m.year(),
            month: m.month(),
            date: m.date(),
            hour: m.hour(),
            minute: m.minute()
        },
        timezone
    );
};

/*
 * 'now' is your 'getNow' method
 * 'tzMoment is the 'moment' object you got from 'getMoment()'
 */
export const getNow = (now, tzMoment) => convertDateTimeToDate(now, tzMoment);

export const dateRangeHeaderFormat = ({start, end}, culture, localizer) =>
    `${localizer.format(start, "ddd, MM/DD/YY", culture)} - ${localizer.format(
        end,
        "ddd, MM/DD/YY",
        culture
    )}`;
