const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
}

const extract = (arr, prop) => {
    if (!arr) {
        return [];
    }
    let result = [];
    for (let i = 0; i < arr.length; i++) {
        result.push(arr[i][prop]);
    }
    return result;
}

const isEmpty = (value) => {
    return (value == null || value.length === 0);
}

const to2dArray = (arr, partSize) => {
    if (isEmpty(arr)) {
        return arr;
    }

    const result = [];
    for (let i = 0; i < arr.length; i += partSize) {
        result.push(arr.slice(i, i + partSize));
    }

    return result;
}

export {onlyUnique, extract, isEmpty, to2dArray};