import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import TextInput from '../../../components/text-input/TextInput'
import 'react-phone-input-2/lib/style.css'
import { Chronometer } from '../../../components/timer/Chronometer'

class OtpChallenge extends Component {

  constructor (props) {
    super(props)

    this.state = {
      otpCode: ''
    }

    this.onVerify = this.onVerify.bind(this)
    this.onResend = this.onResend.bind(this)
  }

  onVerify () {
    this.props.verify(this.state.otpCode)
  }

  onResend () {
    this.props.resend()
    this.setState({otpCode: ''});
  }

  render () {
    return (
      <div className="container ">
          <div className={"row min-vh-100 pt-5"}>
              <div className={"col-lg-5 mx-auto mt-5"}>
                  <div className={"row mb-4"}>
                      <div className={"col-auto p-0"}>
                          <i className={"icon-mobile-phone-time icon size-6x mid-grey-400 mt-n3"}></i>
                      </div>
                    <div className={"col"}>
                        <h4 className="bordered-heading font-weight-bold">Verify your phone number</h4>
                        <p>We have sent a verification code to your mobile number</p>
                    </div>

                  </div>

              <label className={"font-weight-bold mid-grey-400"}>Please enter 6 digit codes here</label>
              <TextInput
                  className={"form-control"}
                  autoFocus
                  maxLength={6}
                  value={this.state.otpCode}
                  onChange={(code) => this.setState({ otpCode: code })}
              />
              <Chronometer seconds={120} onResend={this.onResend}/>
              <Button className="btn btn-secondary btn-lg btn-block mt-4"
                      onClick={this.onVerify}>
                  Verify
              </Button>
              </div>
          </div>


      </div>
    )
  }
}

export default OtpChallenge