import React from "react";
import Tooltip from "../../../components/tooltip/Tooltip";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles({
  arrow: {
    display: 'none',
  },
  tooltip: {
    backgroundColor: 'white',
  },
})

export default (
  {
    helperText,
    label = "",
    labelRight,
    tooltip,
    optionalLabel="",
    required = false,
    widthClassName = "w-100",
    children,
  }) => {
  const classes = useStyles();
  return (
    <div className={clsx("p-field mb-3", widthClassName)}>

      <div className="label mb-1 font-weight-bold">
        <div className="d-flex flex-row justify-content-between">
          <div>
            {label + (required && !optionalLabel ? " *" : "")}
            {optionalLabel && <span className="optional"> ({optionalLabel}) {(required ? " *" : "")}</span>}
          </div>
          <small>{labelRight}</small>
        </div>
      </div>
        {children}
      <div className={clsx("d-flex flex-row align-items-center", widthClassName)}>
        {
          tooltip &&
          <Tooltip title={tooltip} placement="bottom-start" classes={{
            arrow: classes.arrow, tooltip: classes.tooltip
          }}>
            <i className="icon size-2x icon-circle-question-mark nile-green"/>
          </Tooltip>
        }
        {
          helperText && <small style={{fontSize: 12}}> {helperText}</small>
        }
      </div>
    </div>
  );
};