import React, {Component} from 'react';
import ClinicianCard from "../../../corporatelandingpage/ClinicianCard";

class OurServicesCommonDetail extends Component {
    render() {
        const {cmsData, service, page, clinicianList} = this.props;

        return (
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-12" dangerouslySetInnerHTML={{__html: cmsData.details_html_raw}}/>
                </div>

                <div className="row mt-3">
                    <div className="col-md-6 mb-3">
                        <h4 className="bordered-heading font-weight-bold">
                            {cmsData.conditionsWeTreatTitle_t}
                        </h4>
                        <div className="check-list"
                             dangerouslySetInnerHTML={{__html: cmsData.conditionsWeTreatDescription_html_raw}}/>
                    </div>
                    <div className="col-md-6">
                        <h4 className="bordered-heading font-weight-bold">
                            {cmsData.treatmentsWeOfferTitle_t}
                        </h4>
                        <div className="check-list"
                             dangerouslySetInnerHTML={{__html: cmsData.treatmentsWeOfferDEscription_html_raw}}/>
                        {cmsData.disclaimerDescription_t && <div className="row mt-3">
                            <div className="col-12">
                                <p>{cmsData.disclaimerDescription_t}</p>
                            </div>
                        </div>}
                    </div>
                </div>

                {page.shouldRetrieveStaff && clinicianList?.length > 0 && <div className="row">
                    <div className="col-md-12">
                  <h4 className="font-weight-bold mt-5">{service} Specialists</h4>
                        <div className="row mt-3 no-gutters">
                            {clinicianList?.map(clinician => <ClinicianCard clinician={clinician}/>)}
                        </div>
                    </div>
                </div>}
            </div>
        );
    }

}

export default OurServicesCommonDetail;