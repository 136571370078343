import validator from 'validator';
const StringUtil = validator;
/* https://www.npmjs.com/package/validator#validators */

StringUtil.pad = (n, width, z) => {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

StringUtil.formatMoney = (amount, currency) => {
    return parseFloat(amount).toFixed(getFractionDigitsAccordingToCurrency(currency)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

StringUtil.isNullOrEmpty = (str) => {
    return str == null || str == "";
}

StringUtil.getFileExtension = (fileName) => {
    const regExp = /(?:\.([^.]+))?$/;
    const fileExtension = regExp.exec(fileName)[1];

    return fileExtension;
}

StringUtil.isValidAlphabeticText = (value) => {
    const englishLettersAndSpace = /^[a-z,\s]*$/i;
    const arabicLettersAndSpace = /^[\u0621-\u064A,\s]*$/i;
    return value.match(englishLettersAndSpace) || value.match(arabicLettersAndSpace);
}
StringUtil.sortByField = (a, b, field) => {
    if (!a && !b) {
        return 0;
    }
    if (!a) {
        return -1;
    }
    if (!b) {
        return 1;
    }

    if (a[field] < b[field]) {
        return -1;
    }
    if (a[field] > b[field]) {
        return 1;
    }
    return 0;
};

const getFractionDigitsAccordingToCurrency = (currency) => {
    switch (currency) {
        case 'AED':
            return 2;
        case 'KWD':
            return 3;
        default:
            return 2
    }
}

export default StringUtil;
