import CorporateLandingPage from "./modules/corporatelandingpage/CorporateLandingPage";
import DubaiClinic from "./modules/pages/DubaiClinic";
import DoctorProfile from "./modules/pages/DoctorProfile";
import IntegrationDubai from "./modules/pages/IntegrationDubai";
import QualityAndSafetyDubai from "./modules/pages/QualityAndSafetyDubai";
import DubaiLocations from "./modules/pages/DubaiLocations";
import KuwaitLocations from "./modules/pages/KuwaitLocations";
import YourAppointmentDetail from "./modules/pages/YourAppointmentDetail";
import YourAppointmentDetailKuwait from "./modules/pages/YourAppointmentDetailKuwait";
import LoginRegistration from "./modules/user/login/LoginRegistration";
import Career from "./modules/pages/Career";
import Profile from "./modules/profile/Profile";
import Dashboard from "./modules/user/dashboard/Dashboard";
import Appointment from "./modules/appointment/new/Appointment";
import BestPossibleSlots from "./modules/appointment/bestslots/BestPossibleSlots";
import Insurance from "./modules/insurance/Insurance";
import AppointmentHistory from "./modules/appointment/history/AppointmentHistory";
import InnovationDubai from "./modules/pages/InnovationDubai";
import KuwaitClinic from "./modules/pages/KuwaitClinic";
import YourAppointmentInsurance from "./modules/pages/YourAppointmentInsurance";
import YourAppointmentInsuranceKuwait from "./modules/pages/YourAppointmentInsuranceKuwait";
import IntegrationKuwait from "./modules/pages/IntegrationKuwait";
import InnovationKuwait from "./modules/pages/InnovationKuwait";
import QualityAndSafetyKuwait from "./modules/pages/QualityAndSafetyKuwait";
import OurServicesGeneralPage from "./modules/pages/ourservices/OurServicesGeneralPage";
import OurServicesGeneralPageDubai from "./modules/pages/ourservices/OurServicesGeneralPageDubai";
import OurServicesGeneralPageKuwait from "./modules/pages/ourservices/OurServicesGeneralPageKuwait";
import IntegrationLanding from "./modules/pages/IntegrationLanding";
import InnovationLanding from "./modules/pages/InnovationLanding";
import QualityAndSafetyLanding from "./modules/pages/QualityAndSafetyLanding";
import ContactUs from "./modules/pages/ContactUs";

export const clinicBasedPage = {GENERAL: "GENERAL", DUBAI: "DUBAI", KUWAIT: "KUWAIT"};
const {GENERAL, DUBAI, KUWAIT} = clinicBasedPage;

const generalPages = [
    {path: "/", exact: true, component: CorporateLandingPage},
    {path: "/integration", exact: true, component: IntegrationLanding},
    {path: "/innovation", exact: true, component: InnovationLanding},
    {path: "/quality-and-safety", exact: true, component: QualityAndSafetyLanding},
    {path: "/best-slots", exact: true, component: BestPossibleSlots},
    {path: "/career", exact: true, component: Career},
    {path: "/our-services/:serviceName", exact: true, component: OurServicesGeneralPage},
    {path: "/contact-us", exact: true, component: ContactUs},
].map(i => {
    i.basePage = GENERAL;
    i.footer = true;
    return i;
});

const noFooterGeneralPages = [
    //PatientPortalHide : {path: "/dashboard", authority: "PATIENT", exact: true, component: Dashboard},
    //PatientPortalHide : {path: "/myprofile", authority: "PATIENT", exact: true, component: Profile},
    //PatientPortalHide : {path: "/insurance", authority: "PATIENT", exact: true, component: Insurance},
    //PatientPortalHide : {path: "/appointment-history", authority: "PATIENT", exact: true, component: AppointmentHistory},
    //PatientPortalHide : {path: "/new-appointment", authority: "PATIENT", exact: true, component: Appointment},
    //PatientPortalHide : {path: "/new-appointment-kuwait", authority: "PATIENT", exact: true, component: Appointment},
    {path: "/doctorprofile/:staffId", exact: true, component: DoctorProfile},
].map(i => {
    i.basePage = GENERAL;
    i.footer = false;
    return i;
})

const dubaiPages = [
    {path: "/dubai", exact: true, component: DubaiClinic},
    {path: "/dubai/our-locations", exact: true, component: DubaiLocations},
    {path: "/dubai/your-appointment", exact: true, component: YourAppointmentDetail},
    {path: "/dubai/your-appointment/insurance", exact: true, component: YourAppointmentInsurance},
    //PatientPortalHide : {path: "/login", exact: true, component: LoginRegistration},
    {path: "/integration-dubai", exact: true, component: IntegrationDubai},
    {path: "/innovation-dubai", exact: true, component: InnovationDubai},
    {path: "/quality-and-safety-dubai", exact: true, component: QualityAndSafetyDubai},
    {path: "/our-services-dubai/:serviceName", exact: true, component: OurServicesGeneralPageDubai},
].map(i => {
    i.basePage = DUBAI;
    i.footer = true;
    return i;
});

const kuwaitPages = [
    {path: "/kuwait", exact: true, component: KuwaitClinic},
    {path: "/kuwait/our-locations", exact: true, component: KuwaitLocations},
    {path: "/kuwait/your-appointment", exact: true, component: YourAppointmentDetailKuwait},
    {path: "/kuwait/your-appointment/insurance", exact: true, component: YourAppointmentInsuranceKuwait},
    //PatientPortalHide : {path: "/login-kuwait", exact: true, component: LoginRegistration},
    {path: "/integration-kuwait", exact: true, component: IntegrationKuwait},
    {path: "/innovation-kuwait", exact: true, component: InnovationKuwait},
    {path: "/quality-and-safety-kuwait", exact: true, component: QualityAndSafetyKuwait},
    {path: "/our-services-kuwait/:serviceName", exact: true, component: OurServicesGeneralPageKuwait},
].map(i => {
    i.basePage = KUWAIT;
    i.footer = true;
    return i;
});

const routes = [
    ...generalPages,
    ...dubaiPages,
    ...kuwaitPages,
    ...noFooterGeneralPages
];

export default routes;
