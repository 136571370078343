import React, {Component} from "react";
import './GreenSwitchWithEmptyMessage.css';
import GreenSwitch from "./GreenSwitch";
import clsx from "clsx";

export default class GreenSwitchWithEmptyMessage extends Component {

    isChecked = (checked) => {
        return checked !== undefined && checked !== null && checked;
    }

    render() {
        const value = this.isChecked(this.props.checked);
        return(
            <div className={clsx("green-switch-container", this.props.className)}>
                {!this.props.checked &&
                <div className={"empty-message"}>
                    {this.props.emptyMessage}
                </div>
                }
                <div>
                    <GreenSwitch checked={value}
                                 onChange={this.props.onChange}/>
                </div>
            </div>
        );
    }

}
