import React, {Component} from "react";
import ClinicNavigationBar from "../../layout/ClinicNavigationBar";
import TopBar from "../../layout/TopBar";
import Subscription from "../../modules/corporatelandingpage/Subscription";
import RemotingService from "../../services/remoting-service/RemotingService";
import StringUtil from "../../utils/StringUtil";
import KuwaitTopBar from "../../layout/KuwaitTopBar";
import {getFooter} from "../../layout/Layout";
import EnumCountry from "../user/enums/EnumCountry";
import KuwaitClinicNavigationBar from "../../layout/KuwaitClinicNavigationBar";

class DoctorProfile extends Component {
  constructor(props) {
    super(props);
    this.bioRef = React.createRef();
    this.state = {
      provider: {
      }
    }
  }

  componentDidMount() {
    const {staffId} = this.props.match.params;

    if (staffId) {
      RemotingService.getRemoteCall(`/api/staff/public/${staffId}`, null , (provider)=>{
        this.setState({provider});
        const bio = provider.biography ? provider.biography : 'Not Specified';
        this.bioRef.current.innerHTML = bio;
      });
    }
  }

  render() {
    const {provider} = this.state;
    return (
        <>
          {!provider?.dubaiStaff ? <KuwaitTopBar/> : <TopBar/>}
          {!provider?.dubaiStaff ? <KuwaitClinicNavigationBar/> : <ClinicNavigationBar/>}

        <div className="container">
          <div class="row mt-5">
            <div class="col-md-8 text-center mx-auto">
              <ul className="list-inline justify-content-end align-items-center d-flex m-lg-0 social-links">
                <li>
                  <a href={provider.facebookLink} target="_blank">
                    <i className="icon size-2x icon-facebook mid-grey-400"></i>
                  </a>
                </li>
                <li>
                  <a href={provider.instagramLink} target="_blank">
                    <i className="icon size-2x icon-instagram mid-grey-400"></i>
                  </a>
                </li>
                <li>
                  <a href={provider.linkedinLink} target="_blank">
                    <i className="icon size-2x icon-linkedin mid-grey-400"></i>
                  </a>
                </li>
              </ul>

              {provider.thumbnail ?
                  <img
                      src={'data:image/jpeg;base64,' + provider.thumbnail}
                      width={220} height={220}
                      className="rounded-circle p-3"
                  /> :
                  <div className="d-flex justify-content-center mb-3">
                    <div className="rounded-circle bg-light-grey-200 fit-width p-3">
                      <i className="icon size-5x icon-person align-self-center mid-grey-400"/>
                    </div>
                  </div>
              }
                <h4 class="font-weight-bold mb-1">{`${provider.title ? provider.title + '. ' : ''}${provider.name}`}</h4>
              <h6>{provider.specialityName}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mx-auto">
              <h4 class="bordered-heading font-weight-bold">About Me</h4>
              <div ref={this.bioRef}></div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              {provider.skills && provider.skills.length > 0 &&
              <>
                <h4 class="font-weight-bold mt-5">Skills</h4>
                <div className="check-list">
                  <ul>
                    {provider.skills.sort((a, b) => StringUtil.sortByField(a, b, "name")).map(skill =>
                        <li>{skill.name}</li>)}
                  </ul>
                </div>
              </>
              }
            </div>
            <div className="col-md-6">
              {provider.specializedConditions && provider.specializedConditions.length > 0 &&
              <>
                <h4 class="font-weight-bold mt-5">
                  Specializations
                </h4>
                <div className="check-list">
                  <ul>
                    {provider.specializedConditions.sort((a, b) => StringUtil.sortByField(a, b, "name")).map(condition =>
                        <li>{condition.name}</li>)}
                  </ul>
                </div>
              </>
              }
            </div>
          </div>
        </div>
        <Subscription />
        {getFooter(!provider?.dubaiStaff ? EnumCountry.KUWAIT.key : EnumCountry.UAE.key)}
      </>
    );
  }
}

export default DoctorProfile;
