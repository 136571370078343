import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Stepper as MuiStepper } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import {StepLabel as MuiStepLabel} from '@material-ui/core';
import PWConnector from "./PWConnector";
import PWStepIcon from "./PWStepIcon";
import StepButton from "@material-ui/core/StepButton";


const CustomStepButton = withStyles((theme) => ({
    root: {
        '&:hover': {
            borderRadius: 10,
            background: '#F2FDFB',
        },
        '&:focus': {
            outline: 'none',
        },
    }
}))(StepButton);


const StepLabel = withStyles({
    root: {
        zIndex: 2,
    },
    label: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '1rem',
        lineHeight: '20px',
        color: '#6D6E72',
        '&.MuiStepLabel-completed': {
            fontWeight: 'bold',
            fontSize: 16,
            lineHeight: '20px',
            color: '#8CD0C1'
        },
        '&.MuiStepLabel-active': {
            fontWeight: 'bold',
            fontSize: 16,
            lineHeight: '20px',
            color: '#8CD0C1'
        },
    },
    completed: {
    }
}, {name: 'MPWStepLabel'})(MuiStepLabel);

class Stepper extends Component {
    constructor (props) {
        super(props)
    }

    render() {
        const {classes, steps, className, goToStep, ...others} = this.props;
        return (
            <MuiStepper className={className} activeStep={this.props.activeStep} alternativeLabel  connector={<PWConnector />}>
                {steps.map((label, index) => (
                    <Step key={label}>
                        <CustomStepButton onClick={() => goToStep(index)}>
                            <StepLabel StepIconComponent={PWStepIcon}>{label}</StepLabel>
                        </CustomStepButton>
                    </Step>
                ))}
            </MuiStepper>
        );
    }
}

export default withStyles(() => ({
    root: {
    },
}))(Stepper);

Stepper.propTypes = {
    initialStep: PropTypes.number,
    goToStep: PropTypes.func,
    steps: PropTypes.array.isRequired,
};