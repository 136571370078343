import React, {Component} from "react";
import {Tab, Tabs} from "react-bootstrap";
import AppointmentTable from "../../user/dashboard/AppointmentTable";
import Paper from "@material-ui/core/Paper";
import VisitTable from "../../user/dashboard/VisitTable";
import PatientPageWrapper from "../../profile/PatientPageWrapper";

export default class AppointmentHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: "appointment"
        }
    }

    render() {
        return (
            <PatientPageWrapper activeItem={"APPOINTMENT_HISTORY"}>
                <Paper>
                    <div className={'row mb-md-5'}>
                        <div className={'col-md-12'}>
                            <Tabs className="mpw-tabs"
                                  defaultActiveKey="appointment"
                                  onSelect={selectedTab => this.setState({selectedTab})}>
                                <Tab eventKey="appointment" title={<b>UPCOMING APPOINTMENTS</b>}>
                                    {this.state.selectedTab === "appointment" && <AppointmentTable/>}
                                </Tab>
                                <Tab eventKey="visit" title={<b>VISIT HISTORY</b>}>
                                    {this.state.selectedTab === "visit" && <VisitTable/>}
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </Paper>
            </PatientPageWrapper>
        );
    }
}