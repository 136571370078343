import React, {Component} from 'react';
import {scrollTo} from "../utils/LayoutUtil";
import {withRouter} from "react-router";

// Since Chromium has a bug related with this, we need some timeout to scroll to correct place.
class DelayedLink extends Component {

    render() {
        const {hrefPrefix, href, title, history} = this.props;
        const link = `${hrefPrefix || ""}#${href}`;
        return (
            <a className="nav-link" href={link} onClick={event => {
                event.stopPropagation();
                event.preventDefault();
                event.persist();
                history.push(link)
                setTimeout(() => {
                    scrollTo(href)
                }, 400)
            }}>
                {title}
            </a>
        );
    }
}

export default withRouter(DelayedLink);