import {withStyles} from "@material-ui/core";
import {AccordionSummary as MUIAccordionSummary} from "@material-ui/core";

const AccordionSummary = withStyles({
  expandIcon: {
    "&$expanded": {
      transform: "rotate(45deg)"
    }
  },
  expanded: {}
})(MUIAccordionSummary);

export default AccordionSummary;