import { getData } from './CmsService'

export const getKuwaitLandingPageData = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/item.json?${process.env.REACT_APP_POST_FIX}&url=/site/items/SitePages/kwipage/index.xml`, callBack)
}
export const getYourAppointmentDataKuwait = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/YourAppointment/Kuwait`, callBack)
}
export const getSharingOurKnowledge = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/SharingOurKnowledge`, callBack)
}
export const getSpecialityItemsKuwait = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/SpecialtyCarousel/Kuwait`, callBack)
}

export const getOurApproachItemsKuwait = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/OurApproach/Kuwait`, callBack)
}

export const getOurTeamKWIPageData = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/OurTeam/Kuwait`, callBack)
}

export const getIntegrationKWIData = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/SitePages/OurApproach/Kuwait/integrationkuwait`, callBack)
}
export const getInnovationKWIData = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/SitePages/OurApproach/Kuwait/innovationkuwait`, callBack)
}
export const getQualityAndSafetyKWIData = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/SitePages/OurApproach/Kuwait/qualityandsafetykuwait`, callBack)
}
