import React, {Component} from "react";
import PropTypes from "prop-types";
import _uniqueId from "lodash/uniqueId";

export default class TextInput extends Component {

    constructor(props) {
        super(props);
    }

    static propTypes = {
        id: PropTypes.string,
        className: PropTypes.string,
        onChange: PropTypes.func,
        value: PropTypes.string,
        alphabeticOnly: PropTypes.bool,
        alphaNumericOnly: PropTypes.bool,
        latinNumericOnly: PropTypes.bool
    }

    static defaultProps = {
        alphabeticOnly: false
    }

    onlyEnglishOrArabicLetters = (value) => {
        const lettersAndSpace = /^[a-z,\u0621-\u064A,\s]+$/i;
        return value.match(lettersAndSpace);
    }

    onlyNumberAndEnglishOrArabicLetters = (value) => {
        const lettersNumbersAndSpace = /^[0-9,a-z,\u0621-\u064A,\s]+$/i;
        return value.match(lettersNumbersAndSpace);
    }

    onlyNumberAndEnglishLetters = (value) => {
        const lettersAndNumbers = /^[0-9,a-z]+$/i;
        return value.match(lettersAndNumbers);
    }

    handleOnChange(e) {
        let value = e.target.value;

        if (this.props.onChange) {
            if(this.props.alphabeticOnly && value && !this.onlyEnglishOrArabicLetters(value)) {
                return;
            } else if(this.props.alphaNumericOnly && value && !this.onlyNumberAndEnglishOrArabicLetters(value)) {
                return;
            } else if(this.props.latinNumericOnly && value && !this.onlyNumberAndEnglishLetters(value)) {
                return;
            }

            this.props.onChange(value);
        }
    }

    render() {
        const {id, className, onChange, value, labelText, ...rest} = this.props;

        let componentClassName = "dim14textinput";
        if (className) {
            componentClassName += " " + className;
        }

        return (
            <>
                <input
                    type="text"
                    id={id || _uniqueId("textinput-id-")}
                    className={componentClassName}
                    onChange={e => this.handleOnChange(e)}
                    value={value || ''}
                    {...rest}
                />
                <label htmlFor={id}>{labelText}</label>
            </>
        )
    }
}