import React, {Component} from "react";

class YourAppointmentSideMenuKuwait extends Component {
    render() {
        const {visitActive, insuranceActive, locationActive} = this.props
        return (
            <div
                id="LeftSideMenu"
                className="sticky-side-menu"
                data-spy="scroll"
                data-target="#YourAppointmentInfo"
                data-offset="0"
            >
                <ul>
                    <li>
                        <a className={visitActive} href="/kuwait/your-appointment">Your Visit</a>
                    </li>
                    <li>
                        <a className={insuranceActive} href="/kuwait/your-appointment/insurance">Insurance</a>
                    </li>
                    <li>
                        <a className={locationActive} href="/kuwait/our-locations">Location</a>
                    </li>
                </ul>
            </div>
        );
    }

}

export default YourAppointmentSideMenuKuwait;
