import React, { Component } from 'react'

class BlogSection extends Component {
    constructor(props) {
        super(props);
        this.publicURL = process.env.PUBLIC_URL;
    }


    state = {
        cmsData: null,
        isLoaded: false,
    }

    componentDidMount() {
        this.props.getData(this.handleCmsData);
    }

    handleCmsData = (result) => {
        this.setState({cmsData: result, isLoaded: true})
    }

    render() {
        const cmsURL = process.env.REACT_APP_CMS_URL;
        const postFix = process.env.REACT_APP_POST_FIX;
        const publicURL = process.env.PUBLIC_URL
        const {cmsData, isLoaded} = this.state;
        let component = null;
        if (isLoaded) {
            component = cmsData[0].descriptorDom.component;
        }

        return (
            <>
                {isLoaded ?
                    <>
                        <div id="BlogSection">
                            <div className="col-lg-6 mx-auto text-center mt-5 mb-5">
                                <h1 className="font-weight-bold">{component.sharingOurKnowledgeTitle_t}</h1>
                                <p className="mt-3"
                                   dangerouslySetInnerHTML={{__html: component.sharingOurKnowledgeDescription_html}}>
                                </p>
                                <a className="btn btn-secondary" href={component.sharingOurKnowledgeURL_t || "#"}
                                   target="_blank">
                                    SEE MORE <i className="icon icon-right-arrow size-1x"></i>
                                </a>
                            </div>

                            <div className="container">

                                <div className="row">
                                    <div className="scrolling-wrapper">
                                        {
                                            component?.sharingOurKnowledgeSubSections_o?.item?.map((i, index) =>
                                                <div className="col-lg-4 col-9">
                                                    <div className="blog-card">
                                                        <div
                                                            className="blog-card-image"
                                                            style={{
                                                                backgroundImage: "url(" + cmsURL + i.subSectionImage_s + "?" + postFix,
                                                            }}
                                                        ></div>
                                                        <div className="blog-card-body">
                                                            <span class="time-stamp">{i.subSectionDate_t}</span>
                                                            <h6 className="font-weight-bold">
                                                                {i.subSectionTitle_t}
                                                            </h6>
                                                            <div
                                                                dangerouslySetInnerHTML={{__html: i.subSectionDescription_html}}>
                                                            </div>
                                                        </div>
                                                        <div className="blog-card-footer">
                                                            <div class="d-flex">
                                                                <a className="btn btn-secondary btn-sm" target={'blank'}
                                                                   href={i.subSectionURL_t || '#'}>
                                                                    READ MORE
                                                                    <i className="icon icon-right-arrow size-1x"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </> : null}
            </>
        );
    }

}

export default BlogSection;
