import {withStyles} from "@material-ui/core/styles";
import {StepConnector} from "@material-ui/core";

export default withStyles({
    root: {
        zIndex: 1,
    },
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            background: "#8CD0C1",
        },
    },
    completed: {
        '& $line': {
            background: "#8CD0C1",
        },
    },
    line: {
        height: 2,
        border: 0,
        background: "#E2E2E2",
        borderRadius: 1,
    },
})(StepConnector);