import React, {Component} from "react";
import Paper from "@material-ui/core/Paper";
import FormField from "../../../../../components/form/FormField";
import ValidationUtil from "../../../../../components/form/validator/ValidationUtil";
import NotificationService from "../../../../../services/notification-service/NotificationService";
import InsuranceFullyCoversViewDetail from "./InsuranceFullyCoversViewDetail";
import {
    getDubaiDirectBillingData,
    getKuwaitDirectBillingData
} from "../../../../../services/cms-services/PatientCmsService";

export default class InsuranceFullyCoversView extends Component {

    constructor(props) {
        super(props);
        this.isUAE = this.props.country === 'UAE';
        this.state = {
            agreed: false,
        }
    }

    validateAndConfirm = () => {
        if (!ValidationUtil.isValid(this.formFields)) {
            return;
        }
        const {bestTimesToContact, communicationMethods} = this.props.preferences;

        if (communicationMethods.length === 0) {
            NotificationService.showNotification({
                severity: 'warn',
                summary: 'Missing Information',
                detail: 'Please specify your preferred communication method.'
            });
            return;
        }

        if (bestTimesToContact.length === 0) {
            NotificationService.showNotification({
                severity: 'warn',
                summary: 'Missing Information',
                detail: "Please specify your preferred contact time."
            });
            return;
        }
        this.props.confirmButtonClicked();
    }

    render() {
        this.formFields = [];

        return (
            <div className="container" style={{width: "60%"}}>
                <Paper variant={"outlined"} className="paper mt-4 pt-2 pb-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-1">
                                <i className="icon size-5x icon-check-circle-linear disc-green"/>
                            </div>
                            <div className="col-11 pt-2 pl-3 d-flex align-items-center mid-grey-400">
                                <InsuranceFullyCoversViewDetail {...this.props}
                                                                getData={this.isUAE ? getDubaiDirectBillingData : getKuwaitDirectBillingData}/>
                            </div>
                            <div className="row mx-3 w-100 d-flex align-items-center justify-content-end mid-grey-400">
                                <FormField ref={formField => this.formFields.push(formField)} checked
                                           validateOn={this.state.agreed}>
                                    <div className="custom-control custom-checkbox">
                                        <input id="agree-checkbox"
                                               className="custom-control-input"
                                               edge="end"
                                               type="checkbox" checked={this.state.agreed}
                                               onChange={(e) => this.setState({agreed: e.target.checked})}/>
                                        <label className="custom-control-label" htmlFor="agree-checkbox"><span
                                            className="pl-2 pr-5">I have read and agree</span></label>
                                    </div>
                                </FormField>
                                <button
                                    className="square-btn btn btn-secondary appointment-button selected px-3 py-3 mt-3"
                                    onClick={this.validateAndConfirm}>Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </Paper>
            </div>);
    }
}