import { getData } from './CmsService'

export const getDubaiDirectBillingData = (callBack) => {
    getData(`${process.env.REACT_APP_POST_FIX}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/PatientSite/Dubai/dubaidirectbilling`, callBack)
}

export const getKuwaitDirectBillingData = (callBack) => {
    getData(`${process.env.REACT_APP_POST_FIX}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/PatientSite/Kuwait/kwidirectbilling`, callBack)
}

export const getDubaiNoInsuranceData = (callBack) => {
    getData(`${process.env.REACT_APP_POST_FIX}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/PatientSite/Dubai/dubainoinsurancepatient`, callBack)
}

export const getKuwaitNoInsuranceData = (callBack) => {
    getData(`${process.env.REACT_APP_POST_FIX}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/PatientSite/Kuwait/kwinoinsurancepatient`, callBack)
}

export const getDubaiPayAndClaimData = (callBack) => {
    getData(`${process.env.REACT_APP_POST_FIX}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/PatientSite/Dubai/dubaipayandclaim`, callBack)
}

export const getKuwaitPayAndClaimData = (callBack) => {
    getData(`${process.env.REACT_APP_POST_FIX}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/PatientSite/Kuwait/kwipayandclaim`, callBack)
}
