import React, {Component} from 'react'
import NotificationService from '../../services/notification-service/NotificationService'

class FileUpload extends Component {

  static defaultProps = {
    multiple: false,
    specificFileTypes: [],
    maxFileSize: 1048576
  };

  constructor (props) {
    super(props)
    this.state = {
      inputValue: ''
    }
  }

  handleChange (event) {
    this.setState({ inputValue: event.target.value })
    if (this.props.onChange) this.props.onChange(event.target.files)
  }

  async addItem (event) {
    if (!this.props.uploadHandler) {
      let tempFiles = [];
      this.setState({inputValue: event.target.value})
      // If file input is multiple, then keep previously selected files
      // and keep adding to them
      if (this.props.multiple && this.props.value) {
        if (this.props.value.constructor === Array) {
          tempFiles = this.props.value;
        } else {
          tempFiles.push(this.props.value);
        }
      }
      for(let i=0; i<event.target.files.length; i++) {
        event.persist();
        let file = event.target.files[i];
        let filetype = file.type || 'application/octet-stream';
        let name = file.name || 'file';
        let filesize = file.size;
        let specificType;
        if (name.lastIndexOf(".") > 0){
          specificType = name.substr(name.lastIndexOf(".") + 1);
        }
        /**
         * If you want to do very strong file type checking, then you can use file headers.
         * Either convert HEX values of specific file types and check if they exist in base64String of files
         * or convert base64String of files to HEX, and check if they match with given HEX values of file types
         */
        if ((this.props.maxFileSize && (filesize > this.props.maxFileSize)) || (filesize <= 0)) {
          let temp = this.state.errors;
          let message = "Maximum allowed upload size is " + this.props.maxFileSize + " bytes. (Size of " + name + " is " + (filesize/(1024*1024)).toFixed(2) + " megabytes)";
          if (this.props.invalidFileSizeMessage) {
            message = this.props.invalidFileSizeMessage;
          }
          NotificationService.showNotification({
            severity: 'error', summary: 'File Update Failed',
            detail: message
          });
        } else {
          // If string of file types are given in a array, checks whether file type matches with any of them.
          // if there is no file extansion, then accept the file
          if ((this.props.specificFileTypes.length > 0) && specificType && !this.props.specificFileTypes.includes(specificType.toLowerCase())) {
            let message = "You can only upload files with extension " + this.props.specificFileTypes + ". (File you upload has extension of " + specificType + ")";
            if (this.props.invalidFileTypeMessage) {
              message = this.props.invalidFileTypeMessage;
            }
            NotificationService.showNotification({
              severity: 'error', summary: 'File Update Failed',
              detail: message
            });
          } else {
            let reader = new FileReader();
            await new Promise ((resolve) => {
              reader.readAsDataURL(file);
              reader.onloadend = (evt) => {
                let dataAsDataUrl = evt.target.result;
                let base64String = dataAsDataUrl.replace(/^data:.+(;base64)?,/,'');
                let processedFile = {content: base64String, mimeType: filetype, name: name, fileSize: filesize};
                // Check if newly loaded file already exists in the list, if not add it
                if (tempFiles.every(item => {return (item.content !== processedFile.content);})){
                  tempFiles.push(processedFile);
                }
                NotificationService.showNotification({
                  severity: 'success', summary: 'File Updated',
                  detail: "File successfully updated."
                });
                resolve();
              };
            });
          }
        }
      }
      if (tempFiles.length === 1) {
        this.props.onChange(tempFiles[0], event.target.files[0]);
      } else {
        if (tempFiles.length === 0) {
          this.props.onChange(null, null);
        } else {
          this.props.onChange(tempFiles, event.target.files);
        }
      }
    } else {
      this.props.uploadHandler(event);
    }
  };

  render () {
    return (
      <>
        <div class="file-upload">
          <span className="file-name">{this.state.inputValue}</span>
          <input
            id={this.props.id}
            type="file"
            value={this.state.inputValue}
            name={this.props.name}
            onChange={event => this.addItem(event)}
            className="form-control-file"
            labelText={this.props.labelText}
            required={this.props.required}
            multiple={this.props.multiple}
          />
          <label for={this.props.id}>{this.props.labelText}</label>
          <i className="icon size-2x icon-attach"></i>
        </div>
        <span class="file-format">
        {this.props.specificFileTypes && this.props.specificFileTypes.length > 0 && (<><b>Accepted Formats:</b> {this.props.specificFileTypes.join(", ")} </>)}
        {this.props.maxFileSize && <><b>Max:</b> {(this.props.maxFileSize/(1024*1024)).toFixed(0) + " MB"}</>}
      </span>
      </>
    )
  }

}

export default FileUpload
