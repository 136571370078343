import moment from "moment";

const now = () => {
    return moment();
};

const parse = (dateStr, format = "DD-MM-YYYY") => {
    return moment(dateStr, format);
};

/* https://momentjs.com/docs/#/displaying/ */
const formatDate = (date, format = "DD-MM-YYYY") => {
    return date ? moment(date).format(format) : null;
};

const formatDateWithSeparator = (date, format = "DD / MM / YYYY") => {
    return date ? moment(date).format(format) : null;
};

const formatDateDayAndMonth = (date, format = "DD/MM") => {
    return date ? moment(date).format(format) : null;
};

const formatDateMonthAndYear = (date, format = "MMMM, YYYY") => {
    return date ? moment(date).format(format) : null;
};

const formatDateMMM = (date) => {
    return formatDate(date, "DD-MMM-YYYY");
};

const formatDateMMMSeparately = (date) => {
    return formatDate(date, "DD MMM YYYY");
};

const formatDateDo = (date) => {
    return formatDate(date, "MMMM Do YYYY");
};

const formatDateDoMMYY = (date) => {
    return formatDate(date, "Do MMMM YYYY");
};

const formatDateTime12H = (date) => {
    return formatDate(date, "DD-MMM-YYYY hh:mm A");
};

const formatDateTimeSeparately = (date) => {
    return formatDate(date, "DD MMMM YYYY | hh:mm A");
};

const formatDateTimeSeparatelyForReports = (date) => {
    return formatDate(date, "Do MMMM YYYY / hh:mm:ss A");
};

const getHourMinute12H = (date) => {
    return formatDate(date, "hh:mm A");
};

const getHourMinute24H = (date) => {
    return formatDate(date, "HH:mm");
};

const diff = (start, end, units) => {
    return moment(end).diff(moment(start), units);
};

const getAge = (birthDate) => {
    return diff(birthDate, now(), 'years');
};

const isToday = (date) => {
    const current = now();
    return current.isSame(date, 'day');
};

const getAgeWithMonths = (birthDate) => {
    const current = now();
    const months = current.diff(moment(birthDate), 'months', true);
    const birthSpan = {
        year: Math.floor(months / 12),
        month: Math.floor(months) % 12,
        day: Math.round((months % 1) * current.daysInMonth(), 0)
    };

    if (birthSpan.year < 1 && birthSpan.month < 1) {
        return birthSpan.day + ' day' + (birthSpan.day > 1 ? 's' : '')
    }
    if (birthSpan.year < 1) {
        return birthSpan.month + ' month' + (birthSpan.month > 1 ? 's ' : ' ') + birthSpan.day + ' day' + (birthSpan.day > 1 ? 's' : '')
    }
    if (birthSpan.month < 1) {
        return birthSpan.year + ' year' + (birthSpan.year > 1 ? 's' : '');
    }
    return birthSpan.year + ' year' + (birthSpan.year > 1 ? 's ' : ' ') + birthSpan.month + ' month' + (birthSpan.month > 1 ? 's ' : '')
};

const add = (date, value, unit) => {
    return moment(date).add(value, unit).toDate();
}

const addMinutes = (date, minutes) => {
    return moment(date).add(minutes, 'minute').toDate();
}

const addHours = (date, hours) => {
    return moment(date).add(hours, 'hour').toDate();
}

const addDays = (date, days) => {
    return moment(date).add(days, 'day').toDate();
}

const addWeeks = (date, weeks) => {
    return moment(date).add(weeks, 'week').toDate();
}

const addMonths = (date, months) => {
    return moment(date).add(months, 'month').toDate();
}

const addYears = (date, years) => {
    return moment(date).add(years, 'year').toDate();
}

// Note: startOf and endOf 'week' functions are local aware, do not use them
const startOfWeek = (date) => {
    return moment(date).day('Sunday').toDate();
}

const startOfPreviousWeek = (date) => {
    return startOfWeek(addDays(startOfWeek(date), -1));
}

const startOfNextWeek = (date) => {
    return addDays(endOfWeek(date), 1);
}

const endOfWeek = (date) => {
    return moment(date).day('Saturday').toDate();
}

const startOfMonth = (date) => {
    return moment(date).startOf('month').toDate();
}

const startOfPreviousMonth = (date) => {
    return startOfMonth(addDays(startOfMonth(date), -1));
}

const startOfNextMonth = (date) => {
    return addDays(endOfMonth(date), 1);
}

const endOfMonth = (date) => {
    return moment(date).endOf('month').toDate();
}

const yesterday = () => {
    return addDays(new Date(), -1);
}

const today = () => {
    return new Date();
}

const tomorrow = () => {
    return addDays(new Date(), 1);
}

const isYesterday = (date) => {
    return moment(yesterday()).isSame(moment(date), 'd');
}

const isTomorrow = (date) => {
    return moment(tomorrow()).isSame(moment(date), 'd');
}

const timeOf = (hour, minute, second = 0) => {
    return moment().hour(hour).minute(minute).second(second).toDate();
}

const parseTime = (timeStr, format = "HH:mm") => {
    return timeStr ? moment(timeStr, format).toDate() : null;
}

const isSameOrBefore = (date1, date2) => {
    return moment(date1).isSameOrBefore(date2);
}

const isAfter = (date1, date2) => {
    return moment(date1).isAfter(date2);
}

const isBefore = (date1, date2) => {
    return moment(date1).isBefore(date2);
}

const isSameOrAfter = (date1, date2) => {
    return moment(date1).isSameOrAfter(date2);
}

const isInRange = (date, startDate, endDate) => {
    return moment(date).isSameOrAfter(startDate) &&
        moment(date).isBefore(endDate);
}

const isSame = (date1, date2, granularity = "millisecond") => {
    return moment(date1).isSame(date2, granularity);
}

const isSameDay = (date1, date2) => {
    return moment(date1).isSame(date2, 'day');
}

const currentYear = () => {
    return now().year();
}

const firstSundayAfterNMonths = (date, n) => {
    const curr = moment(date).clone().add(n, 'months').startOf('month');
    return curr.day() === 0 ? curr : curr.add(7 - curr.day(), 'days');
}

const switchZone = (date, zone) => {
    const mmnt = moment(date);
    return moment.tz([mmnt.year(), mmnt.month(), mmnt.date(), mmnt.hour(), mmnt.minute(), mmnt.second()], zone);
}

const getUtcDateAtStartOfDay = (date) => {
    if (!date) {
        return null;
    }
    const momentDate = moment(date);
    return new Date(Date.UTC(momentDate.year(), momentDate.month(), momentDate.date()));
}

export default {
    now, moment, parse, diff, formatDate, formatDateDayAndMonth, formatDateMonthAndYear, formatDateMMM, formatDateDo,
    formatDateTime12H, getHourMinute24H, formatDateTimeSeparately, getHourMinute12H, isToday, getAge, getAgeWithMonths,
    add, addMinutes, addHours, addDays, addWeeks, addMonths, addYears, startOfWeek, endOfWeek, startOfPreviousWeek,
    startOfNextWeek, startOfMonth, endOfMonth, yesterday, today, tomorrow, isYesterday, isTomorrow, startOfPreviousMonth,
    startOfNextMonth, timeOf, parseTime, isSameOrBefore, isSameOrAfter, isSame, isSameDay, currentYear, isInRange,
    firstSundayAfterNMonths, formatDateDoMMYY, formatDateTimeSeparatelyForReports, switchZone, formatDateMMMSeparately,
    formatDateWithSeparator, getUtcDateAtStartOfDay, isAfter, isBefore
};
