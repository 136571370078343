import React, {Component} from 'react';
import PhoneNumber from "./PhoneNumber";

class KuwaitPhoneNumber extends Component {
    render() {
        return (
            <PhoneNumber number={"+965 222 05331"} {...this.props}/>
        );
    }
}

export default KuwaitPhoneNumber;