export default class MinValidator {

    getErrorMessage(prop) {
        return "Field should be more than or equal to " + prop;
    }

    validate(value, prop) {
        return value >= prop;
    }

}