import React, { Component } from 'react'
import NavigationBar from '../../layout/NavigationBar'
import CareerModal from '../../layout/CareerModal'
import { getCareerPageData } from '../../services/cms-services/CorporateCmsService'

class Career extends Component {


    state = {
        cmsData: null,
        isLoaded: false,
    }

    componentDidMount() {
        getCareerPageData(this.handleCmsData);
    }

    handleCmsData = (result) => {
        this.setState({cmsData: result, isLoaded: true})
    }

    render() {
        const publicURL = process.env.PUBLIC_URL
        const cmsURL = process.env.REACT_APP_CMS_URL;
        const postFix = process.env.REACT_APP_POST_FIX;
        const {cmsData, isLoaded} = this.state;
        const data = cmsData?.descriptorDom?.page;

        return (
            <>
                {isLoaded ?
                    <>
                        <NavigationBar/>
                        <div className="container-fluid introduction-section">
                            <div className="row align-items-center justify-content-end">
                                <div className="col-md-5">
                                    <h1 className="font-weight-bold text-center text-sm-left">
                                        {data.careerPageTitle_t}
                                    </h1>
                                    <h4 className="text-center text-sm-left">
                                        {data.careerPageDescription_t}
                                    </h4>

                                    <a
                                        href="#open-positions"
                                        className="btn btn-secondary my-4"
                                    >
                                        View Open Positions
                                    </a>
                                </div>
                                <div className="col-md-6 text-right">
                                    <img className="img-fluid mb-4"
                                         src={cmsURL + data.careerPageImage_s + "?" + postFix}/>
                                </div>
                            </div>
                        </div>
                        <div className="container mt-5">
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                    <h2 className="bordered-heading font-weight-bold mb-2"> {data.ourCultureTitle_t}</h2>
                                    <p>{data.ourCultureDescription_t}</p>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-10 offset-md-1">
                                    <h2 className="bordered-heading font-weight-bold mb-2">{data.ourCommitmentTitle_t}</h2>

                                    <p>
                                        {data.ourCommitmentDescription_t}
                                    </p>
                                </div>
                            </div>
                            <div className="row mt-4 ">
                                <div className="col-md-10 offset-md-1 even-odd-row">
                                    {
                                        data?.ourCommitmentSubSections_o?.item?.map((i, index) =>
                                            <div key={index.toString()} className="row d-flex align-items-center mb-4">
                                                <div className="col-md-4">
                                                    <img className="img-fluid mb-4"
                                                         src={cmsURL + i.subSectionImage_s + "?" + postFix}/>
                                                </div>
                                                <div className="col-md-8">
                                                    <h4 className="font-weight-bold">{i.subSectionTitle_t}</h4>
                                                    <p>
                                                        {i.subSectionDescription_t}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>

                        </div>
                        <div className="container mt-4" id="open-positions">
                            <div className="row">
                                <div className="col-md-10 offset-md-1 mb-4">
                                    <h2 class="bordered-heading font-weight-bold">Open Positions</h2>
                                </div>
                            </div>
                        </div>
                        <div class="container">
                            {
                                data?.openPositionListing_o.item.map((i, index) =>
                                    <div className="row mb-3">
                                        <div className="col-md-10 offset-md-1">
                                            <div className="card">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col">
                                                        <div className="card-body">
                                                            <h6 className="m-0">
                                                                {i.nameOfOpenPosition_t}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className="col pr-3 d-flex justify-content-end">
                                                        <button
                                                            type="button"
                                                            className="btn btn-secondary"
                                                            data-toggle="modal"
                                                            data-target="#CareerModal"
                                                            onClick={() => this.openCareerModal(i.nameOfOpenPosition_t)}
                                                        >
                                                            Apply Now
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }


                        </div>
                    </>
                    : null}
                {
                    this.state.careerModalVisible &&
                    <CareerModal
                        show={this.state.careerModalVisible}
                        appliedPosition={this.state.appliedPosition}
                        closeModal={this.closeCareerModal}/>
                }

            </>
        )
    }

    openCareerModal = (appliedPosition) => {
        this.setState({appliedPosition: appliedPosition, careerModalVisible: true})
    }

    closeCareerModal = () => {
        this.setState({appliedPosition: null, careerModalVisible: false})
    }
}

export default Career
