const getPatientName = (firstName, middleName, lastName) => {
    return (firstName != null && firstName.length != 0 ? firstName : "") +
           (middleName != null && middleName.length != 0 ? " " + middleName : "") +
           (lastName != null && lastName.length != 0 ? " " + lastName : "")
}

const getPatientsName = (patient) => {
    return getPatientName(patient.firstName, patient.middleName, patient.lastName);
}

export default {getPatientName, getPatientsName};
