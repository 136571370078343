const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export const postContactUsData = (data, callback) => {

  const header = new Headers()
  header.append('Content-Type', 'application/json')
  delete data.captcha

  const requestOptions = {
    method: 'POST',
    headers: header,
    body: JSON.stringify(data),
    redirect: 'follow'
  }

  fetch(API_BASE_URL + 'api/public/contact-info', requestOptions)
    .then(response => response.text())
    .then(result => {
      alert('Your Message is Submitted')
      callback()
    })
    .catch(error => console.log('error', error))
}
