import React, { Component } from 'react'
import FormField from '../../components/form/FormField'
import NotificationService from '../../services/notification-service/NotificationService'
import ValidationUtil from '../../components/form/validator/ValidationUtil'
import RemotingService from '../../services/remoting-service/RemotingService'

class Subscription extends Component {

  state = {}

  constructor (props) {
    super(props)
    this.publicURL = process.env.PUBLIC_URL
  }

  render () {
    this.formFields = []
    return (
      <>
        <div id="subscription" className="mt-5">
          <div class="container py-5 text-center">
            <h2 class="font-weight-bold">Let’s Keep in Touch</h2>
            <p>
              Join the DISC digital tribe and stay up-to-date with the clinic
              and the community.
            </p>
            <div class="col-lg-7 mx-auto px-sm-auto px-0">
              <div class="input-group mb-3 input-group-lg subscription-inputs">
                <FormField ref={formField => this.formFields.push(formField)} email
                           validateOn={this.state.subscriptionEmail}>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Join with your email address"
                    value={this.state.subscriptionEmail}
                    onChange={(event) => {this.setState({ subscriptionEmail: event.target.value })}}
                  />
                </FormField>
                <div class="input-group-append">
                  <button
                    class="btn btn-secondary mt-sm-0 mt-2"
                    onClick={this.registerCommunity}>
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  registerCommunity = () => {
    if (!ValidationUtil.isValid(this.formFields)) {
      NotificationService.showNotification({
        severity: 'error',
        summary: 'Subscription error',
        detail: 'Email format is not correct.'
      })
    }
    else if (this.state.subscriptionEmail == null || this.state.subscriptionEmail.length == 0){
      NotificationService.showNotification({
        severity: 'error',
        summary: 'Subscription error',
        detail: 'Email required to subscribe.'
      })
    }
    else if (this.state.subscriptionEmail != null && this.state.subscriptionEmail.length != 0) {
      const dto = {
        subscriptionEmail: this.state.subscriptionEmail
      }

      RemotingService.postRemoteCall(`/api/public/subscription/subscribe`, dto, result => {
      })
    }
  }
}

export default Subscription
