import React, { Component } from 'react'
import { getOurTeamLandingPageData } from '../../services/cms-services/CorporateCmsService'

class OurGoalAndTeam extends Component {

  state = {
    isLoaded: false,
    teamData: null,
  }

  componentDidMount() {
    getOurTeamLandingPageData(this.handleTeamData);
  }

  handleTeamData = (result) => {
    this.setState({teamData:result, isLoaded:true})
  }


  render() {

    const {teamData, isLoaded} = this.state;
    let component = null;
    if (isLoaded){
      component = teamData[0].descriptorDom.component;
    }


    const {missionTitle, visionTitle, visionDescription, missionDescription, missionImage} = this.props;
    const cmsURL = process.env.REACT_APP_CMS_URL;
    const postFix = process.env.REACT_APP_POST_FIX;
    return (
      <div className="container mt-lg-5">
        <div className="row align-items-start py-5 flex-column-reverse flex-lg-row">
          <div className="col-lg-6">
            <h2 className="bordered-heading font-weight-bold">
              {missionTitle}
            </h2>
            <p dangerouslySetInnerHTML={{__html: missionDescription}}></p>
            <h2 className="bordered-heading font-weight-bold mt-5">
              {visionTitle}
            </h2>
              <p dangerouslySetInnerHTML={{__html: visionDescription}}>
              </p>
            {isLoaded?
                <>
              <h2 className="bordered-heading font-weight-bold mt-5">
                {component.ourTeamTitle_t}
              </h2>
              <p dangerouslySetInnerHTML={{__html: component.ourTeamDescription_html}}>
              </p>
                </>
              :null}
          </div>


          <div className="col-lg-5 ml-lg-5 mb-5">
            <img className="img-fluid"
                 src={cmsURL + missionImage + "?" + postFix}/>
          </div>
        </div>
      </div>
    );
  }
}

export default OurGoalAndTeam;
