import React, {Component} from 'react';

const timeOptions = {
    MORNING: {key: "morning", title: "MORNING"},
    AFTERNOON: {key: "afternoon", title: "AFTERNOON"}
};

class BestTimeInputs extends Component {
    render() {
        const {time, bestTimes, bestTimesToContact, handleMultiSelectionChange} = this.props;
        const selectedTime = timeOptions[time];

        return (
            <>
                <h6 className="font-weight-bold nile-green mt-3 mb-0">{selectedTime.title}</h6>
                <div className="col-12 p-0 d-flex flex-row">
                    {bestTimes.map((timeOfDay, idx) => (
                        <div className="custom-control custom-checkbox" key={idx}>
                            <input id={`${timeOfDay.key}-${selectedTime.key}-checkbox`}
                                   className="custom-control-input"
                                   edge="end"
                                   type="checkbox"
                                   checked={bestTimesToContact.includes(timeOfDay.key)}
                                   onChange={(e) => handleMultiSelectionChange("bestTimesToContact", timeOfDay, e.target.checked)}
                            />
                            <label className="custom-control-label"
                                   htmlFor={`${timeOfDay.key}-${selectedTime.key}-checkbox`}>
                                <div className="d-flex flex-column">
                                    <h6 className="pl-2 pr-5 font-weight-bold">{timeOfDay.name}</h6>
                                    <h6 className="pl-2 pr-5 small">{timeOfDay.timeRange}</h6>
                                </div>
                            </label>
                        </div>
                    ))}
                </div>
            </>
        );
    }
}

export default BestTimeInputs;