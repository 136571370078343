import React, { Component } from 'react'

class OurServicesKuwait extends Component {

    state = {
        cmsData: null,
        isLoaded: false,
    }

    componentDidMount() {
        this.props.getData(this.handleCmsData);
    }

    handleCmsData = (result) => {
        this.setState({cmsData: result, isLoaded: true})
    }

    rePositionArrayElementsNext = () => {
        const serviceList = [...this.state.cmsData]
        this.rePositionArrayElements(serviceList.length - 1, 0)
    }

    rePositionArrayElementsPrev = () => {
        const serviceList = [...this.state.cmsData]
        this.rePositionArrayElements(0, serviceList.length - 1)
    }

    rePositionArrayElements = (fromIndex, toIndex) => {
        const serviceList = [...this.state.cmsData]
        const element = serviceList.splice(fromIndex, 1)[0]
        serviceList.splice(toIndex, 0, element)
        this.setState({cmsData: serviceList})
    }

    render() {

        const {cmsData, isLoaded} = this.state;
        const data = cmsData?.map(e => e.descriptorDom.component)
        const cmsURL = process.env.REACT_APP_CMS_URL;
        const postFix = process.env.REACT_APP_POST_FIX;
        const {ourServicesTitle, ourServicesDescription} = this.props;

        return (
            <>
                {isLoaded ?
                    <div className="container-fluid overflow-hidden country-service-carousel">
                        <div className="row bg-light-grey-100">
                            <div className="container">
                                <div className="col-lg-6 mx-auto text-center mt-5">
                                    <h1 className="font-weight-bold"> {ourServicesTitle}</h1>
                                    <p dangerouslySetInnerHTML={{__html: ourServicesDescription}}></p>
                                </div>
                                <div className="service-carousel my-5">
                                    <div className="carousel-actions col-lg-4">
                                        <div className="prev">
                                            <a
                                                className="circle-button"
                                                onClick={this.rePositionArrayElementsPrev}
                                            >
                                                <i className="icon icon-chevron-left size-2x"></i>
                                            </a>
                                        </div>
                                        <div className="next">
                                            <a
                                                className="circle-button"
                                                onClick={this.rePositionArrayElementsNext}
                                            >
                                                <i className="icon icon-chevron-right size-2x"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <ul className="service-carousel-list">
                                        {
                                            data.map(
                                                serviceItem =>
                                                    <li key={serviceItem.objectId}
                                                        className="service-carousel-item item-card">
                                                        <div className="carousel-image-wrapper">
                                                            <img className="carousel-img"
                                                                 src={cmsURL + serviceItem.specialtyListingImage_s + "?" + postFix}
                                                                 alt={serviceItem.title_t}/>
                                                        </div>
                                                        <div className="carousel-content">
                                                            <div className="carousel-body">
                                                                <h5>{serviceItem.specialtyListingTitle_t}</h5>
                                                                <p>{serviceItem.specialtyListingDescription_t}</p>

                                                            </div>
                                                            <a className="btn btn-secondary btn-sm"
                                                               href={serviceItem.specialtyListingURL_t || '#'}>
                                                                SEE MORE <i
                                                                className="icon icon-right-arrow size-1x"></i>
                                                            </a>
                                                        </div>
                                                    </li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}
            </>
        );
    }

}

export default OurServicesKuwait;
