import React, {Component} from 'react'
import MyProfile from './MyProfile'
import RemotingService from '../../services/remoting-service/RemotingService'
import PatientPageWrapper from "./PatientPageWrapper";

class Profile extends Component {

    constructor(props) {
        super(props)
        this.state = {
            patient: {}
        }
    }

    componentDidMount() {
        this.retrievePatient();
        this.retrieveProfileStatus();
        this.retrieveFirstAppointmentDate();
    }

    retrievePatient() {
        RemotingService.getRemoteCall(`/api/patient`, null, patient => {
            this.setState({patient})
        })
    }

    retrieveProfileStatus = () => {
        RemotingService.getRemoteCall(`/api/profile/status`, null, status => {
            this.setState({status})
        })
    }

    retrieveFirstAppointmentDate = () => {
        RemotingService.getRemoteCall(`/api/appointment/is-first-appointment-started`, null, isFirstAppointmentStarted => {
            this.isUpdatable = !isFirstAppointmentStarted;
        })
    }

    render() {
        const {patient} = this.state;

        return (
            <PatientPageWrapper activeItem={"MY_PROFILE"}>
                <div className={'row mb-md-4'}>
                    <div className={'col-md-12'}>
                        <MyProfile patient={patient}
                                   onPatientUpdated={patient => this.setState({patient})}/>
                    </div>
                </div>
            </PatientPageWrapper>
        )
    }
}

export default Profile