import React, {Component} from "react";
import {Column} from "primereact/column";
import DateUtil from "../../../utils/DateUtil";
import EnumAppointmentType from "../../appointment/enums/EnumAppointmentType";
import EnumAppointmentStatus from "../../appointment/enums/EnumAppointmentStatus";
import QueryPanel from "../../../components/query/QueryPanel";
import ResultTable from "../../../components/query/ResultTable";
import {Image} from "react-bootstrap";
import "./VisitTable.scss";
import VisitDocumentsModal from "./VisitDocumentsModal";
import EnumAppointmentSource from "../../appointment/enums/EnumAppointmentSource";

export default class VisitTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visits: []
        };
    }

    render() {
        const {showDocumentsModal, selectedVisit} = this.state;

        return (
            <>
                <QueryPanel url="/api/patient/visit-history/list"
                            queryOptions={this.state.queryOptions}
                            orderBy="appointment.startTime">
                    <ResultTable className="mpw-datatable visit-datatable">
                        <Column header="Clinician" body={visit => this.clinicianTemplate(visit)}/>
                        <Column header="Date/Time" field="appointment.startTime" sortable body={visit => this.dateTimeTemplate(visit)}/>
                        <Column header="Type" body={visit => this.typeTemplate(visit)}/>
                        <Column header="Source" body={visit => this.sourceTemplate(visit)}/>
                        <Column header="Insurance" body={visit => this.insuranceTemplate(visit)}/>
                        <Column header="Clinic" body={visit => this.clinicTemplate(visit)}/>
                        <Column header="Status" body={visit => this.statusTemplate(visit)}/>
                        <Column header="My Documents" body={visit => this.myDocumentsTemplate(visit)}/>
                    </ResultTable>
                </QueryPanel>
                {showDocumentsModal &&
                <VisitDocumentsModal visit={selectedVisit}
                                     onClose={() => this.setState({showDocumentsModal: false})}/>
                }
            </>
        );
    }

    clinicianTemplate(visit) {
        const staff = visit.staff;
        const thumbnail = staff.thumbnail ?
            'data:image/jpeg;base64,' + staff.thumbnail :
            process.env.PUBLIC_URL + '/assets/images/clinic/doctors/default.png';

        return <div className="d-flex">
            <Image src={thumbnail} className="staff-photo-smaller"/>
            <div className="align-self-center">
                <div className="font-weight-bold">{staff.name}</div>
                <div className="">{staff.specialityName}</div>
            </div>
        </div>
    }

    dateTimeTemplate(visit) {
        return DateUtil.formatDate(visit.dateTime, "DD-MM-YYYY / hh:mm A");
    }

    typeTemplate(visit) {
        return EnumAppointmentType[visit.type].name;
    }

    sourceTemplate(visit) {
        return visit.source ? EnumAppointmentSource[visit.source] : '';
    }

    insuranceTemplate(visit) {
        return null;
    }

    clinicTemplate(visit) {
        return visit.clinic.name;
    }

    statusTemplate(visit) {
        return EnumAppointmentStatus[visit.status].name;
    }

    myDocumentsTemplate(visit) {
        return <div className="d-flex">
            <button className="btn visit-button mt-2"
                    onClick={() => {
                        this.setState({showDocumentsModal: true, selectedVisit: visit});
                    }}>
                <i className="icon size-1x icon-attach"/>
                My Documents
            </button>
        </div>
    }

}
