import React, {Component} from "react";
import InsuranceNoAgreementView from "./confirmation/InsuranceNoAgreementView";
import InsuranceFullyCoversView from "./confirmation/InsuranceFullyCoversView";
import InsurancePayNClaimView from "./confirmation/InsurancePayNClaimView";

export default class InsuranceDetailView extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let view;
        if (this.props.preferences.haveInsurancePlan) {
            if (this.props.fullyCovers) {
                view = <InsuranceFullyCoversView {...this.props} />;
            } else {
                view = <InsurancePayNClaimView {...this.props} />;
            }
        } else {
            view = <InsuranceNoAgreementView {...this.props} />
        }
        return view;
    }
}