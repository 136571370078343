import React, {Component} from "react";
import PhoneInput from "../../../components/phone-input/PhoneInput";
import {Button} from "react-bootstrap";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.login = this.login.bind(this);

    this.state = {
      mobilePhone: "",
    };
  }

  login() {
    this.props.onLogin(this.state.mobilePhone);
  }

  render() {
    return (
      <div className={"col-lg-8 offset-lg-1"}>
        <p className={"mb-0"}>Registered Visitors</p>
        <h4 className={"mb-4 font-weight-bolder"}>Login Here</h4>
        <div class="phone-input-wrapper">
          <div class="form-group">
            <PhoneInput
              value={this.state.mobilePhone}
              searchPlaceholder="Search"
              inputClass={"phone-input"}
              onChange={(value) => this.setState({ mobilePhone: value })}
            />
            <i className="icon size-2x icon-phone form-icon light-grey-300"></i>
          </div>
        </div>
        <Button
          className="btn btn-secondary btn-block mt-4"
          onClick={this.login}
        >
          Login
        </Button>
      </div>
    );
  }
}
