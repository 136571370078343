import React, { Component } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import RemotingService from '../../services/remoting-service/RemotingService'
import ClinicianCard from './ClinicianCard'
import { Carousel } from 'primereact/carousel'
import { to2dArray } from '../../utils/ArrayUtil'
import { getOurTeamKWIPageData } from '../../services/cms-services/KuwaitCmsService'

class KuwaitTeam extends Component {

  constructor (props) {
    super(props)
    this.state = {
      departmentNames: [],
      departmentCliniciansMap: null,
      partSizeMap: null,
      isLoaded: false,
      teamData: null,
    };
  }

  componentDidMount() {

    RemotingService.getRemoteCall("/api/staff/public/Kuwait/list/clinician", null, (clinicians) => {
      const departmentNames = [];
      const departmentCliniciansMap = new Map();
      const partSizeMap = new Map();
      clinicians.forEach(clinician => {
        if (clinician.departments) {
          clinician.departments.forEach(department => {
            if (!departmentCliniciansMap.get(department.name)) {
              departmentNames.push(department.name)
              departmentCliniciansMap.set(department.name, [])
            }
            if (!this.listContainsClinician(departmentCliniciansMap.get(department.name), clinician)) {
              departmentCliniciansMap.get(department.name).push(clinician)
            }
          });
        }
      });
      departmentCliniciansMap.forEach ((value, key) => {
        const totalSize = value?.length;
        const partSize = totalSize % 6 == 1 ? 7 : 6;
        partSizeMap.set(key, partSize)
      });
      this.setState({
        departmentNames: departmentNames.filter(departmentName => departmentName != "Management").sort(),
        departmentCliniciansMap: departmentCliniciansMap,
        partSizeMap : partSizeMap
      });
    });

    getOurTeamKWIPageData(this.handleTeamData);
  }

  listContainsClinician = (list, clinician) => {
    return list.find(c => c.id === clinician.id)
  }

  handleTeamData = (result) => {
    this.setState({teamData:result, isLoaded:true})
  }


  render() {
    const {teamData, isLoaded} = this.state;
    let component = null;
    if (isLoaded){
      component = teamData[0].descriptorDom.component;
    }
    return (
        <>
          <div className="row overflow-hidden"  id="OurTeam">
            <div className="col-lg-6 pb-5 d-block d-lg-none ">
              {isLoaded ?
                  <>
                    <h2 className="bordered-heading font-weight-bold">{component.ourTeamTitle_t}</h2>
                    <p dangerouslySetInnerHTML={{__html: component.ourTeamDescription_html}}>
                    </p>
                  </>
                  : null }
            </div>
            <div className="col-md-12" id="OurTeamSection">
              <div className="row" id="OurTeamContent">
                {
                  this.state.departmentNames && this.state.departmentNames.length > 0 &&
                  <Tabs>
                    {
                      this.state.departmentNames.map((departmentName, index) =>
                          <Tab className={"row"} eventKey={departmentName} title={departmentName}>
                            <Carousel id={`carousel-${index}`} className="mpw-carousel col-lg-6" circular={true} autoplayInterval={2000}
                                      value={to2dArray(this.state.departmentCliniciansMap.get(departmentName), this.state.partSizeMap.get(departmentName))}
                                      itemTemplate={this.clinicianTemplate}/>
                            <div className="col-lg-6 pt-5 d-none d-lg-block">
                              {isLoaded?
                              <>
                              <h2 className="bordered-heading font-weight-bold">{component.ourTeamTitle_t}</h2>
                                <p dangerouslySetInnerHTML={{__html: component.ourTeamDescription_html}}></p>
                              </>
                                  :null}
                            </div>
                          </Tab>
                      )
                    }
                  </Tabs>
                }
              </div>
            </div>
          </div>
        </>
    );
  }

  clinicianTemplate(clinicians) {
    return (
        <div className="row mt-3 no-gutters">
          {clinicians.map(clinician => <ClinicianCard clinician={clinician}/>)}
        </div>
    )
  }

}

export default KuwaitTeam;
