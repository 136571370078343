import React, { Component } from 'react'
import FormInput from '../modules/formcomponents/FormInput'
import FileUpload from '../modules/formcomponents/FileUpload'
import { Modal } from 'react-bootstrap'
import NotificationService from '../services/notification-service/NotificationService'
import { EmailValidator } from '../components/form/validator'
import { postData, uploadFile } from '../services/cms-services/CmsService'

class CareerModal extends Component {
    constructor(props) {
        super(props);
        this.publicURL = process.env.PUBLIC_URL;
        this.cmsURL = process.env.REACT_APP_CMS_URL;
        this.emailValidator = new EmailValidator();

        this.state = {
            careerDTO: {
                appliedPosition: props.appliedPosition
            }
        }
    }

    handleChange = (value, field) => {
        this.setState(state => {
            state.careerDTO[field] = value;
            return state;
        });
    }

    render() {
        return (
            <>
                <Modal
                    className="fade"
                    id="CareerModal"
                    data-toggle="modal"
                    aria-labelledby="CamOfChrioKuwait"
                    tabindex="-1"
                    aria-hidden="true"
                    size={'lg'}
                    show={this.props.show}
                >
                    <Modal.Header>
                        <h5 className="modal-title" id="CamOfChrioKuwait">
                            <i className="icon icon-documents size-2x"></i>
                            Job Application
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={this.props.closeModal}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <FormInput
                                type="text"
                                id="Name"
                                className="form-control"
                                labelText="Name"
                                required="required"
                                value={this.state.careerDTO.firstName}
                                onChange={(value) => {
                                    this.handleChange(value, "firstName")
                                }}
                            />
                        </div>
                        <div className="form-row">
                            <div className="col">
                                <div className="form-group">
                                    <FormInput
                                        type="text"
                                        id="MiddleName"
                                        className="form-control"
                                        labelText="Middle Name"
                                        required="required"
                                        value={this.state.careerDTO.middleName}
                                        onChange={(value) => {
                                            this.handleChange(value, "middleName")
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <FormInput
                                        type="text"
                                        id="SurName"
                                        className="form-control"
                                        labelText="Surname"
                                        required="required"
                                        value={this.state.careerDTO.surname}
                                        onChange={(value) => {
                                            this.handleChange(value, "surname")
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <FormInput
                                type="text"
                                id="Email"
                                className="form-control"
                                labelText="E-mail"
                                required="required"
                                value={this.state.careerDTO.email}
                                onChange={(value) => {
                                    this.handleChange(value, "email")
                                }}
                            />
                            <i className="icon icon-mail size-2x form-icon"></i>
                        </div>
                        <div className="form-group">
                            <FileUpload
                                id="File"
                                className="form-control"
                                labelText="CV or Resume"
                                required="required"
                                multiple={false}
                                specificFileTypes={["pdf", "doc"]}
                                onChange={(file) => {
                                    if (file != null) {
                                        this.setState(state => {
                                            state.careerDTO.file = file;
                                            return state;
                                        })
                                    }
                                }}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary" onClick={this.saveCareer}>
                            Save changes
                        </button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    saveCareer = () => {
        if (this.state.careerDTO.firstName == null ||
            this.state.careerDTO.firstName.length == 0) {
            NotificationService.showNotification({
                severity: "error",
                summary: "Validation Error",
                detail: "Name and surname are both mandatory.",
            });

        } else if (this.state.careerDTO.surname == null ||
            this.state.careerDTO.surname.length == 0) {
            NotificationService.showNotification({
                severity: "error",
                summary: "Validation Error",
                detail: "Name and surname are both mandatory.",
            });

        } else if (this.state.careerDTO.email == null ||
            this.state.careerDTO.email.length == 0 ||
            !this.emailValidator.validate(this.state.careerDTO.email)) {
            NotificationService.showNotification({
                severity: "error",
                summary: "Validation Error",
                detail: "E-mail is not valid.",
            });

        } else {
            const {careerDTO} = this.state;
            const fileUploadLink = `${this.cmsURL}/studio/api/1/services/api/1/content/write-content.json`;
            uploadFile(fileUploadLink, careerDTO.file, (fileName) => this.submitForm(fileName))
        }
    }

    submitForm = (uploadedFileName) => {
        postData(this.state.careerDTO, () => this.props.closeModal(), uploadedFileName)
    }
}

export default CareerModal;
