import React from 'react'

export class Chronometer extends React.Component {

  constructor (props) {
    super(props)
    this.state = { counter: this.props.seconds }
    this.onResend = this.onResend.bind(this)
  }

  resetState () {
    this.setState({ counter: this.props.seconds })
  }

  onResend () {
    this.props.onResend()
    this.resetState()
    this.startTimer()
  }

  startTimer = () => {
    setTimeout(() => {
      if (this.state.counter > 0) {
        this.setState({ counter: this.state.counter - 1 }, () => {
          this.startTimer()
        })
      }
    }, 1000)
  }

  twoDigitNumber (number) {
    return ('0' + number).slice(-2)
  }

  componentDidMount () {
    this.startTimer()
  }

  render () {
    const minutes = Math.floor(this.state.counter / 60)
    const seconds = this.state.counter % 60

    return (
 <>
   <div className={"row"}>
    <div className={"col d-flex justify-content-start align-items-center my-3"}>
        <span>
          {this.state.counter > 0 && <i className="icon icon-stopwatch size-2x mid-grey-400"></i> }
          {this.state.counter > 0 && (this.twoDigitNumber(minutes) + ':' + this.twoDigitNumber(seconds))}
        </span>
      {this.state.counter <= 0 &&
      <span>Didn't receive code?
            <a href='#' onClick={this.onResend} className={'disc-link-button'}>Resend Code</a>
          </span>
      }
    </div>
   </div>
 </>
    )
  }

}
