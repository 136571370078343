import React, {Component} from "react";
import RemotingService from "../../services/remoting-service/RemotingService";
import {Modal} from "react-bootstrap";
import InsuranceDetailView from "../appointment/new/view/InsuranceDetailView";

export default class InsuranceModal extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    save(insurance) {
        let insuranceDTO = {
            ...insurance,
            tpaId: insurance.tpa?.id,
            companyId: insurance.company?.id,
            networkId: insurance.network?.id,
            customTpa: insurance.customTpa,
            customCompany: insurance.customCompany,
            customNetwork: insurance.customNetwork
        };

        RemotingService.postRemoteCall('api/insurance', insuranceDTO, (result) => {
            this.props.onInsuranceUpdated(result);
            this.props.close();
        });
    };

    render() {
        return (
            <>
                <Modal.Header>
                    <h5 class="modal-title" id="AllergiesModalTitle">
                        <i className="icon icon-insurance size-2x mid-grey-400 align-self-center"></i>
                        Insurance Information
                    </h5>
                    <button type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => this.props.close()}>
                        <span aria-hidden>&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <InsuranceDetailView handler={insurance => this.save(insurance)}/>
                </Modal.Body>
            </>
        );
    }

}