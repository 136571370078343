export default class CheckedValidator {

  getErrorMessage(prop) {
    return "You must agree to continue";
  }

  validate(value, prop) {
    return value === true;
  }

}