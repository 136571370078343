import React, { Component } from 'react'
import { getLandingPageData } from '../../services/cms-services/CorporateCmsService'

class OurClinics extends Component {

  state = {
    cmsData: null,
    isLoaded: false,
  }

  componentDidMount() {
    getLandingPageData(this.handleCmsData);
  }

  handleCmsData = (result) => {
    this.setState({cmsData: result, isLoaded: true})
  }


  render() {
    const {cmsData, isLoaded} = this.state;
    let component = null;

    if (isLoaded) {
      component = cmsData.descriptorDom.page;
    }

    const cmsURL = process.env.REACT_APP_CMS_URL;
    const postFix = process.env.REACT_APP_POST_FIX;

    return (
        <>
          {isLoaded ?
              <>

                <div className="col-lg-6 mx-auto text-center mt-5" id="ourClinics">
                  <h1 className="font-weight-bold">{component.ourClinicTitle_t}</h1>
                  <p className="mt-3" dangerouslySetInnerHTML={{__html: component?.ourClinicDescription_html}}>
                  </p>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="scrolling-wrapper">
                      {
                        component?.clinicDetails_o?.item?.map( (i, index) =>
                            <div className="col-lg-4 col-9">
                              <div className="square-card">

                                <div
                                    className="square-card-image"
                                    style={{
                                      backgroundImage: "url(" + cmsURL + i.clinicImage_s + "?" + postFix,
                                    }}
                                ></div>
                                <div className="square-card-footer">
                                  <h6 className="font-weight-bold pl-2">{i.clinicTitle_t}</h6>

                                  <a className="btn btn-card-link btn-sm" href={i.clinicURL_t || '#' }>
                                    SEE MORE <i className="icon icon-right-arrow size-1x"></i>
                                  </a>
                                </div>
                              </div>
                              <p dangerouslySetInnerHTML={{__html: i.clinicDescription_html}}>
                              </p>

                            </div>
                        )
                      }
                    </div>
                  </div>
                </div>


              </> : null}
        </>

    );
  }
}

export default OurClinics;
