import RemotingService from '../services/remoting-service/RemotingService'

const ignoredDepartments = ["Management", "Reception"];

const getClinicianListBySpeciality = (clinicNames, specialityName, onCallBack) => {
    const params = {clinicNames : clinicNames, specialityName: specialityName}
    RemotingService.getRemoteCall("/api/staff/public/list/clinician-by-speciality", params, clinicianList => {
        onCallBack(clinicianList)
    });
}

const getClinicianListByDepartment = (clinicNames, departmentName, onCallBack) => {
    const params = {clinicNames : clinicNames, departmentName: departmentName}
    RemotingService.getRemoteCall("/api/staff/public/list/clinician-by-department", params, clinicianList => {
        onCallBack(clinicianList)
    });
}

const filterManagementStaff = (staffList) => {
    return staffList.filter(s => {
        const staffDepartments = s?.departments;
        if (staffDepartments && staffDepartments?.length > 0) {
            // Only one department returns for all the providers.
            return !ignoredDepartments.includes(staffDepartments[0]?.name)
        }
        else return false;
    });
}

export default {getClinicianListBySpeciality, filterManagementStaff, getClinicianListByDepartment};
