import React, { Component } from 'react'
import RemotingService from '../../services/remoting-service/RemotingService'
import { Tab, Tabs } from 'react-bootstrap'
import ClinicianCard from './ClinicianCard'
import { Carousel } from 'primereact/carousel'
import { to2dArray } from '../../utils/ArrayUtil'
import { getOurTeamUAEPageData } from '../../services/cms-services/UAECmsService'

class OurTeam extends Component {

  constructor (props) {
    super(props)
    this.state = {
      departmentNames: [],
      departmentCliniciansMap: new Map(),
      partSizeMap: new Map(),
      isLoaded: false,
      teamData: null,
    }
  }

  componentDidMount () {
    const { clinicNames } = this.props

    for (let clinicName of clinicNames) {
      RemotingService.getRemoteCall(`/api/staff/public/${clinicName}/list/clinician`, null, (clinicians) => {
        const departmentNames = this.state.departmentNames
        const departmentCliniciansMap = this.state.departmentCliniciansMap
        const partSizeMap = this.state.partSizeMap
        clinicians.forEach(clinician => {
          if (clinician.departments) {
            clinician.departments.forEach(department => {
              if (!departmentCliniciansMap.get(department.name)) {
                departmentNames.push(department.name)
                departmentCliniciansMap.set(department.name, [])
              }
              if (!this.listContainsClinician(departmentCliniciansMap.get(department.name), clinician)) {
                departmentCliniciansMap.get(department.name).push(clinician)
              }
            })
          }
        })
        departmentCliniciansMap.forEach((value, key) => {
          const totalSize = value?.length
          const partSize = totalSize % 6 == 1 ? 7 : 6
          partSizeMap.set(key, partSize)
          departmentCliniciansMap[key] = value.sort((a, b) => a.name.localeCompare(b.name))
        })

        this.setState({
          departmentNames: departmentNames.sort(),
          departmentCliniciansMap: departmentCliniciansMap,
          partSizeMap
        })
      })
    }

    getOurTeamUAEPageData(this.handleTeamData)
  }

  listContainsClinician = (list, clinician) => {
    return list.find(c => c.id === clinician.id)
  }

  handleTeamData = (result) => {
    this.setState({ teamData: result, isLoaded: true })
  }

  render () {
    const { teamData, isLoaded } = this.state
    let component = null
    if (isLoaded) {
      component = teamData[0].descriptorDom.component
    }

    return (
      <>
        <div className="row overflow-hidden" id="OurTeam">
          <div className="col-lg-6 pb-5 d-block d-lg-none ">
            {isLoaded ?
              <>
                <h2 className="bordered-heading font-weight-bold">{component.ourTeamTitle_t}</h2>
                <p dangerouslySetInnerHTML={{ __html: component.ourTeamDescription_html }}>
                </p>
              </>
              : null}
          </div>
          <div class="col-md-12" id="OurTeamSection">
            <div class="row" id="OurTeamContent">
              {
                this.state.departmentNames && this.state.departmentNames.length > 0 &&
                <Tabs>
                  {
                    this.state.departmentNames.map((departmentName, index) =>
                      <Tab className={'row'} eventKey={departmentName} title={departmentName}>
                        <Carousel id={`carousel-${index}`} className="mpw-carousel col-lg-6"
                                  circular={true}
                                  value={to2dArray(this.state.departmentCliniciansMap.get(departmentName), this.state.partSizeMap.get(departmentName))}
                                  itemTemplate={this.clinicianTemplate}/>
                        <div className="col-lg-6 pt-5 d-none d-lg-block">
                          {isLoaded ?
                            <>
                              <h2 className="bordered-heading font-weight-bold">{component.ourTeamTitle_t}</h2>
                              <p dangerouslySetInnerHTML={{ __html: component.ourTeamDescription_html }}>
                              </p>
                            </>
                            : null}
                        </div>
                      </Tab>
                    )
                  }
                </Tabs>
              }
            </div>
          </div>
        </div>
      </>
    )
  }

  clinicianTemplate (clinicians) {
    return (
      <div className="row mt-3 no-gutters">
        {clinicians.map(clinician => <ClinicianCard clinician={clinician}/>)}
      </div>
    )
  }

}

export default OurTeam
