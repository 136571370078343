import React, { Component } from 'react'
import DateUtil from '../../utils/DateUtil'

class DiscWalletItem extends Component {
  render () {
    return (
      <div className={'row small-font w-100 h-100 m-0'}>
        <div className={'col-4 d-flex flex-column align-items-center'}>
          <i className="icon size-2x icon-insurance-card light-grey-200 p-0 mt-4"></i>
          <div className={'font-weight-bold'}>Balance</div>
          <div className="text-center">{this.getBalance()}</div>
        </div>
        <div className={'col-4 d-flex flex-column align-items-center'}>
          <i className="icon size-2x icon-discount light-grey-200 p-0 mt-4"></i>
          <div className={'font-weight-bold'}>Discount</div>
          <div className="text-center">{this.getDiscount()}</div>
        </div>
        <div className={'col-4 d-flex flex-column align-items-center'}>
          <i className="icon size-2x icon-validty light-grey-200 p-0 mt-4"></i>
          <div className={'font-weight-bold'}>Validity</div>
          <div className="text-center">{this.getValidityDate()}</div>
        </div>
      </div>
    )
  }

  getBalance = () => {
    const { discWallet } = this.props

    if (this.isDiscWalletObjectNull())
      return '-'

    return [discWallet.balance, this.getCurrency(discWallet.country)].join(' ')
  }

  getDiscount = () => {
    const { discWallet } = this.props

    if (this.isDiscWalletObjectNull())
      return '-'

    return [discWallet.discountRate, '%'].join('')
  }

  getValidityDate = () => {
    const { discWallet } = this.props

    if (this.isDiscWalletObjectNull())
      return '-'

    return DateUtil.formatDateMMMSeparately(discWallet.validityDate)
  }

  getCurrency = (country) => {
    switch (country) {
      case 'UAE':
        return 'AED'
      case 'KUWAIT':
        return 'KWD'
      default:
        return 'Unknown'
    }
  }

  isDiscWalletObjectNull () {
    const { discWallet } = this.props
    return discWallet == null || !Object.keys(discWallet).length
  }
}

export default DiscWalletItem