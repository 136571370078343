import React, {Component} from "react";
import {Dropdown} from 'primereact/dropdown';
import KuwaitTopBar from "../../layout/KuwaitTopBar";
import KuwaitClinicNavigationBar from "../../layout/KuwaitClinicNavigationBar";
import {getInsuranceDataKuwait} from "../../services/cms-services/OurAppointmentCmsService";
import {
    retrieveAcceptedNetworks,
    retrieveInsuranceCompanies,
    retrievePolicies,
    retrieveTPAs
} from "./util/InsuranceUtil";
import YourAppointmentSideMenuKuwait from "./YourAppointmentSideMenuKuwait";
import EnumCountry from "../user/enums/EnumCountry";


class YourAppointmentDetailKuwait extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tpaList: [],
            companyList: [],
            networkList: [],
            policyList: [],
            selectedTPA: null,
            selectedInsuranceCompany: null,
            selectedNetwork: null,
            cmsData: null,
            isLoaded: false
        };

        this.onTPAChange = this.onTPAChange.bind(this);
        this.onNetworkChange = this.onNetworkChange.bind(this);
        this.onInsuranceCompanyChange = this.onInsuranceCompanyChange.bind(this);
        this.onPolicyChange = this.onPolicyChange.bind(this);

    }

    componentDidMount() {
        getInsuranceDataKuwait(this.handleCmsData);
        retrieveTPAs("KUWAIT", (result) => this.handleTPARetrieve(result));
    }

    handleCmsData = (result) => {
        this.setState({cmsData: result, isLoaded: true})
    }

    onTPAChange(e) {
        const selectedTPA = e.value;
        if (selectedTPA == null) {
            this.setState({
                ...this.state,
                selectedTPA,
                companyList: [],
                networkList: [],
                policyList: [],
                selectedInsuranceCompany: null,
                selectedNetwork: null,
                selectedPolicy: null
            });
            return;
        }

        this.setState({
            ...this.state,
            selectedTPA
        }, retrieveInsuranceCompanies(selectedTPA, EnumCountry.KUWAIT.key, (result) => this.handleCompanyRetrieve(result)));
    }

    handleTPARetrieve(tpaList) {
        this.setState({
            ...this.state,
            tpaList,
            companyList: [],
            networkList: [],
            policyList: [],
            selectedTPA: null,
            selectedInsuranceCompany: null,
            selectedNetwork: null,
            selectedPolicy: null
        });
    }

    onInsuranceCompanyChange(e) {
        const selectedInsuranceCompany = e.value;
        if (selectedInsuranceCompany == null) {
            this.setState({
                ...this.state,
                selectedInsuranceCompany,
                networkList: [],
                policyList: [],
                selectedNetwork: null,
                selectedPolicy: null
            });
            return;
        }

        this.setState({
            ...this.state,
            selectedInsuranceCompany
        }, retrieveAcceptedNetworks(selectedInsuranceCompany, (result) => this.handleNetworkRetrieve(result)));
    }

    handleCompanyRetrieve(companyList) {
        this.setState({
            ...this.state,
            companyList,
            networkList: [],
            policyList: [],
            selectedInsuranceCompany: null,
            selectedNetwork: null,
            selectedPolicy: null
        });
    }

    onNetworkChange(e) {
        const selectedNetwork = e.value;
        if (selectedNetwork == null) {
            this.setState({
                ...this.state,
                selectedNetwork,
                policyList: [],
                selectedPolicy: null
            });
            return;
        }

        this.setState({
            ...this.state,
            selectedNetwork
        }, retrievePolicies(selectedNetwork, (result) => this.handlePolicyRetrieve(result)));
    }

    handleNetworkRetrieve(networkList) {
        this.setState({...this.state, networkList, policyList: [], selectedNetwork: null, selectedPolicy: null});
    }

    handlePolicyRetrieve(policyList) {
        this.setState({
            ...this.state,
            policyList,
            selectedPolicy: null
        });
    }

    onPolicyChange(e) {
        const selectedPolicy = e.value;
        this.setState({...this.state, selectedPolicy});
    }

    selectedOptionTemplate(option, props) {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.name}</div>
                </div>
            );
        }

        return (
            <span>{props.placeholder}</span>
        );
    }

    optionTemplate(option) {
        return (
            <div className="country-item">
                <div>{option.name}</div>
            </div>
        );
    }


    render() {
        const {cmsData, isLoaded} = this.state;
        let data = null;
        if (isLoaded && cmsData) {
            data = cmsData[0].descriptorDom.page;
        }

        return (
            <>
                <KuwaitTopBar/>

                <KuwaitClinicNavigationBar/>
                {isLoaded ? <div className="your-appointment-container container-fluid py-md-5 xxl-limiter">
                    <div className="row min-vh-100 flex-column-reverse flex-md-row justify-content-end">
                        <div className="col-md-8 offset-md-1 ">
                            <div className="col p-0">
                                <h2 className="bordered-heading font-weight-bold">{data.insuranceTitle_t}</h2>
                            </div>
                            <h4 className="font-weight-bold mt-4">{data.directBillingTitle_t}</h4>
                            <div dangerouslySetInnerHTML={{__html: data.directBillingSectionOne_html}}/>
                            <div className="row">
                                <div className={"col-lg-5"}>
                                    <ul className={"list-unstyled"}>
                                        <li className={"mb-3"}>
                                            <Dropdown value={this.state.selectedTPA} options={this.state.tpaList}
                                                      onChange={this.onTPAChange} optionLabel="name" filter showClear
                                                      filterBy="name" placeholder="Select a TPA"
                                                      valueTemplate={this.selectedOptionTemplate}
                                                      itemTemplate={this.optionTemplate}/>
                                        </li>
                                        <li className={"mb-3"}>
                                            <Dropdown value={this.state.selectedInsuranceCompany}
                                                      options={this.state.companyList}
                                                      onChange={this.onInsuranceCompanyChange} optionLabel="name" filter
                                                      showClear filterBy="name"
                                                      placeholder="Select an Insurance Company"
                                                      valueTemplate={this.selectedOptionTemplate}
                                                      itemTemplate={this.optionTemplate}/>
                                        </li>
                                        <li className={"mb-3"}>
                                            <Dropdown value={this.state.selectedNetwork}
                                                      options={this.state.networkList}
                                                      onChange={this.onNetworkChange} optionLabel="name" filter
                                                      showClear filterBy="name" placeholder="Select a Network"
                                                      valueTemplate={this.selectedOptionTemplate}
                                                      itemTemplate={this.optionTemplate}/>
                                        </li>
                                        <li className={"mb-3"}>
                                            <Dropdown value={this.state.selectedPolicy} options={this.state.policyList}
                                                      onChange={this.onPolicyChange} optionLabel="name" filter showClear
                                                      filterBy="name" placeholder="Select a Policy"
                                                      valueTemplate={this.selectedOptionTemplate}
                                                      itemTemplate={this.optionTemplate}/>
                                        </li>
                                    </ul>
                                </div>


                            </div>
                            <div className="row">
                                <div className="col mt-4">
                                    <div className="check-list"
                                         dangerouslySetInnerHTML={{__html: data.directBillingSectionTwo_html}}/>
                                </div>
                            </div>

                            <div id="pay-and-claim" className="row">
                                <div className="col">
                                    <h4 className="font-weight-bold mt-4">{data.payAndClaimTitle_t}</h4>
                                    <div className="check-list"
                                         dangerouslySetInnerHTML={{__html: data.payAndClaimDescription_html}}/>
                                </div>
                            </div>

                            <div id="guarantee-of-payment" className="row">
                                <div className="col">
                                    <h4 className="font-weight-bold mt-4">{data.guaranteeOfPaymentTitle_t}</h4>
                                    <div dangerouslySetInnerHTML={{__html: data.guaranteeOfPaymentDescription_html}}/>
                                </div>
                            </div>

                            <div id="clinic-access" className="row">
                                <div className="col">
                                    <h4 className="font-weight-bold mt-4">{data.clinicAccessTitle_t}</h4>
                                    <div dangerouslySetInnerHTML={{__html: data.clinicAccessDescription_html}}/>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-3 p-0">
                            <YourAppointmentSideMenuKuwait insuranceActive="menu-active"/>
                        </div>
                    </div>

                </div> : null}
            </>
        );
    }
}

export default YourAppointmentDetailKuwait;
