export default class RequiredValidator {

    getErrorMessage(prop) {
        return "Field cannot be left blank";
    }

    validate(value, prop) {
        if(prop === false){
            return true;
        }

        if (value === null || value === undefined) {
            return false;
        }

        if (typeof value === "string" || Array.isArray(value)) {
            return value.length !== 0;
        }

        return true;
    }

}