const EMAIL_VALIDATOR = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

export default class EmailValidator {

    getErrorMessage(prop) {
        return "Field should be a valid email";
    }

    validate(value, prop) {
        if (value === null || value === undefined || value === "") {
            return true;
        }
        return EMAIL_VALIDATOR.test(value);
    }
}