import React, { Component } from 'react'
import KuwaitTopBar from '../../../../layout/KuwaitTopBar'
import TopBar from '../../../../layout/TopBar'
import KuwaitClinicNavigationBar from '../../../../layout/KuwaitClinicNavigationBar'
import ClinicNavigationBar from '../../../../layout/ClinicNavigationBar'
import OurServicesCmsPageEnum from '../OurServicesCmsPageEnum'
import NavigationBar from '../../../../layout/NavigationBar'

const cmsURL = process.env.REACT_APP_CMS_URL;
const postFix = process.env.REACT_APP_POST_FIX;

class OurServicesCommonTitle extends Component {
    render() {
        const {page, cmsData} = this.props;

        return (
            <>
                {page?.key === OurServicesCmsPageEnum.KUWAIT?.key ?
                    <KuwaitTopBar/> : (page?.key === OurServicesCmsPageEnum.DUBAI?.key ? <TopBar/> : null)}
                {page?.key === OurServicesCmsPageEnum.KUWAIT?.key ? <KuwaitClinicNavigationBar/> :
                    page?.key === OurServicesCmsPageEnum.DUBAI?.key ? <ClinicNavigationBar/> : <NavigationBar/>}

                <div className="container-fluid introduction-section">
                    <div className="row align-items-center justify-content-end">
                        <div className="col-md-5 py-5">
                            <h1 className="font-weight-bold">
                                {cmsData.title_t}
                            </h1>
                            <h4
                                dangerouslySetInnerHTML={{__html: cmsData.description_html_raw}}>
                            </h4>
                        </div>
                        <div className="col-md-6 text-right">
                            <img className="img-fluid" src={cmsURL + cmsData.banner_s + "?" +postFix}/>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

export default OurServicesCommonTitle;
