import React, { useState } from "react";

function FormInput(props) {
  const [inputValue, setInputValue] = useState("");

  function handleChange(event) {
    setInputValue(event.target.value);
    if (props.onChange) props.onChange(event.target.value);
  }
  return (
    <>
      <input
        id={props.id}
        type={props.type}
        value={inputValue}
        name={props.name}
        onChange={handleChange}
        className={props.className}
        labelText={props.labelText}
        required={props.required}
      />
      <label for={props.id}>{props.labelText}</label>
    </>
  );
}
export default FormInput;
