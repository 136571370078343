import React, {Component} from "react";
import ObjectUtil from "../../../utils/ObjectUtil";
import EnumGender from "../enums/EnumGender";
import {SelectButton} from "primereact/selectbutton";
import DateUtil from "../../../utils/DateUtil";
import {Dropdown} from "primereact/dropdown";
import RemotingService from "../../../services/remoting-service/RemotingService";
import PhoneInput from "../../../components/phone-input/PhoneInput";
import EnumVisaStatus from "../enums/EnumVisaStatus";
import {Modal} from "react-bootstrap";
import InputText from "../../../components/text-input/InputText";
import DateInput from "../../../components/date-input/DateInput";
import "./UpdateProfileModal.scss"

export default class UpdateProfileModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patient: ObjectUtil.clone(props.patient),
            nationalities: []
        };
    }

    componentDidMount() {
        RemotingService.getRemoteCall("/api/reference/nationality", null, nationalities => {
            this.setState({nationalities});
        });
    }

    setField(fieldName, value) {
        this.setState(state =>  {
            state.patient[fieldName] = value;
            return {...state};
        });
    }

    updatePersonalInformation() {
        RemotingService.postRemoteCall("/api/patient/update", this.state.patient, (patient) => {
            this.props.close();
            this.props.onPatientUpdated(patient);
        });
    }

    render() {
        return (
            <>
                <Modal.Header>
                    <h5 className="modal-title">
                        <i className="icon size-2x icon-person"/>
                        <span>Personal Information</span>
                    </h5>
                    <button type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => this.props.close()}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div id="update-profile-modal">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <InputText id="firstName" className="form-control"
                                               alphabeticOnly value={this.state.patient.firstName}
                                               onChange={e => this.setField("firstName", e.target.value)}/>
                                    <label htmlFor="firstName">First Name</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <InputText id="middleName" className="form-control"
                                               alphabeticOnly value={this.state.patient.middleName || ""}
                                               onChange={e => this.setField("middleName", e.target.value)}/>
                                    <label htmlFor="middleName">Middle Name</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group ">
                                    <InputText id="lastName" className="form-control"
                                               alphabeticOnly value={this.state.patient.lastName || ""}
                                               onChange={e => this.setField("lastName", e.target.value)}/>
                                    <label htmlFor="lastName">Last Name</label>
                                </div>
                            </div>


                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <InputText id="eid" className="form-control"
                                               value={this.state.patient.eid || ""}
                                               onChange={e => this.setField("eid", e.target.value)}/>
                                    <label htmlFor="eid">ID No</label>
                                </div>
                            </div>


                        </div>
                        <div className="row my-4">
                            <div className="col-md-12">
                                <label className="p-form-label">Gender</label>
                                <div className="mt-2">
                                    <SelectButton className="nile-green-select-button"
                                                  optionLabel="name"
                                                  optionValue="key"
                                                  value={this.state.patient.gender}
                                                  options={EnumGender.toArray()}
                                                  onChange={(e) => this.setField("gender", e.value)}/>
                                </div>

                            </div>

                        </div>
                        <div className="row ">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <DateInput id="birthday"
                                               className="form-control"
                                               disableFuture={true}
                                               value={this.state.patient.birthday}
                                               onChange={date => this.setField("birthday", date)}
                                    />
                                    <label htmlFor="birthday"className="p-form-label">Date of Birth</label>
                                    <div className="small-font mt-2">
                                        <span className="font-weight-bold">Age: </span>
                                        <span>{this.state.patient.birthday ? DateUtil.getAgeWithMonths(this.state.patient.birthday) : "-"}</span>
                                    </div>
                                </div>

                            </div>


                        </div>
                        <div className="row mt-2">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Dropdown inputId="nationalities" className="form-control"
                                              options={this.state.nationalities}
                                              optionLabel="nameInEnglish"
                                              filter filterBy="nameInEnglish"
                                              value={this.state.patient.nationality}
                                              onChange={e => this.setField("nationality", e.value)}/>
                                    <label htmlFor="nationalities" className="p-form-label">Nationality</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group ">
                                    <Dropdown inputId="visaStatus" className="form-control" showClear
                                              options={EnumVisaStatus.toArray()}
                                              optionLabel="name"
                                              optionValue="key"
                                              value={this.state.patient.visaStatus}
                                              onChange={e => this.setField("visaStatus", e.value)}/>
                                    <label htmlFor="visaStatus" className="p-form-label">Visa Status</label>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="phone-input-wrapper form-group w-100">
                                    <PhoneInput
                                        disabled={true}
                                        value={this.state.patient.mobilePhone}
                                        searchPlaceholder="Search"
                                        inputClass={"phone-input"}
                                        onChange={(value) => this.setField("mobilePhone", value)}
                                    />
                                    <i className="icon size-2x icon-phone form-icon light-grey-300"></i>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <InputText id="email" className="form-control"
                                               value={this.state.patient.email || ""}
                                               onChange={e => this.setField("email", e.target.value)}/>
                                    <label htmlFor="email">E-mail Address</label>
                                </div>

                            </div>
                        </div>
                        <div className="row  mt-2">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <InputText id="address" className="form-control"
                                               value={this.state.patient.address || ""}
                                               onChange={e => this.setField("address", e.target.value)}/>
                                    <label htmlFor="address">Address</label>
                                </div>

                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button"
                            className="btn btn-secondary"
                            onClick={() => this.updatePersonalInformation()}>Update</button>
                </Modal.Footer>
            </>
        )
    }

}
