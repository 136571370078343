import {history} from '../../utils/history';
import {alertActions} from './index';
import {UserConstants} from '../constants';
import AuthService from '../auth-service/AuthService';

export const userActions = {
    login,
    logout
};

function login(username, password) {
    return dispatch => {
        dispatch(request({username, name: username}));

        AuthService.login(username, password, "")
            .then(
                user => {
                    AuthService.retrieveUserData(userData => {
                        Object.assign(user, userData);
                        localStorage.setItem('user', JSON.stringify(user));
                        localStorage.setItem('clinics', JSON.stringify(user.clinics));
                        dispatch(success(user));
                        history.push('/');
                    });
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error('Authentication Error', error));
                }
            );
    };

    function request(user) {
        return {type: UserConstants.LOGIN_REQUEST, user}
    }

    function success(user) {
        return {type: UserConstants.LOGIN_SUCCESS, user}
    }

    function failure(error) {
        return {type: UserConstants.LOGIN_FAILURE, error}
    }
}

function logout() {
    AuthService.logout();
    return {type: UserConstants.LOGOUT};
}