import { base64ToBlob } from '../../utils/FileDownloader'
import DateUtil from '../../utils/DateUtil'

export const getData = (link, callBack) => {

    const header = new Headers();
    header.append("Authorization", "Bearer eyJhbGciOiJQQkVTMi1IUzUxMitBMjU2S1ciLCJlbmMiOiJBMjU2Q0JDLUhTNTEyIiwiY3R5IjoiSldUIiwicDJjIjo4MTkyLCJwMnMiOiIzS0h1THg1bTlCZXc3Tk5jIn0.IVYxSo0Z3qqul9hR_xdh-NRoOc692B_l-4w2FMA87aD3C_WvEQJ8GgMWgkNJKiBMzXRDY5ACAKqCmD6E71hDuFSkX8ConlxP.D6RMZFh5SESPqCbBCLc0FQ.wzloRHOlXNFyRYG6E6jsIZIc5RncqRDaDtXbeHy_JukHMPEEEHsWvZtSKJMvVlitvTtYi_fWW3kFDjUogN9pz2Z718HBMD098VJEJ7YkeO7EsgpKozaRWQD8NDaCNr7x9XhkT1Uk1qBXllqqJlUl49HzTKRQuEV2XZ23wff7pw28bOOhhJgz5VEnm7PXPPnImTV45vkyASJburPXU0vAkYl_I_LhS-nvyyyouAxQJ4-s72lLz57-5rVeCZID3IMuSlkQI4gUAJV5T1Dr5y-CpeWgoRjtixH7ycwBR2KyRcYPi-xckhT_rQU7KQ0NLSD1fzykAX8b8Yxwimpjfy6eWg.hCYw4jHBn85ONNG-qycnXp2YK6cWHXBLRcRxZTZZWNA");

    const requestOptions = {
        method: 'GET',
        headers: header,
        redirect: 'follow'
    };

    fetch(link, requestOptions)
        .then(response => response.json())
        .then(result => callBack(result))
        .catch(error => console.log('error', error));
}

export const postData = (data, callback, uploadedFileName) => {

    const header = new Headers();
    header.append("Content-Type", "application/json");
    header.append("Authorization", "Bearer eyJhbGciOiJQQkVTMi1IUzUxMitBMjU2S1ciLCJlbmMiOiJBMjU2Q0JDLUhTNTEyIiwiY3R5IjoiSldUIiwicDJjIjo4MTkyLCJwMnMiOiIzS0h1THg1bTlCZXc3Tk5jIn0.IVYxSo0Z3qqul9hR_xdh-NRoOc692B_l-4w2FMA87aD3C_WvEQJ8GgMWgkNJKiBMzXRDY5ACAKqCmD6E71hDuFSkX8ConlxP.D6RMZFh5SESPqCbBCLc0FQ.wzloRHOlXNFyRYG6E6jsIZIc5RncqRDaDtXbeHy_JukHMPEEEHsWvZtSKJMvVlitvTtYi_fWW3kFDjUogN9pz2Z718HBMD098VJEJ7YkeO7EsgpKozaRWQD8NDaCNr7x9XhkT1Uk1qBXllqqJlUl49HzTKRQuEV2XZ23wff7pw28bOOhhJgz5VEnm7PXPPnImTV45vkyASJburPXU0vAkYl_I_LhS-nvyyyouAxQJ4-s72lLz57-5rVeCZID3IMuSlkQI4gUAJV5T1Dr5y-CpeWgoRjtixH7ycwBR2KyRcYPi-xckhT_rQU7KQ0NLSD1fzykAX8b8Yxwimpjfy6eWg.hCYw4jHBn85ONNG-qycnXp2YK6cWHXBLRcRxZTZZWNA");

    const applicantFileName = (data.appliedPosition + "-" + data.email + "-" + DateUtil.formatDateDoMMYY(DateUtil.now())).replace(/\s/g, '_');

    const urlOptions = {
        site_id: "disc-corporate",
        phase: "onSave",
        path: "/site/items/CareerForm/" + applicantFileName + ".xml",
        fileName: applicantFileName + '.xml',
        unlock: true,
        edit: false
    }

    const requestOptions = {
        method: 'POST',
        headers: header,
        body: getXMLData(data, uploadedFileName),
        redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_CMS_URL}/ai/studio/api/1/services/api/1/content/write-content.json?` + serialize(urlOptions), requestOptions)
        .then(response => response.text())
        .then(result => {
            alert('Your Message is Submitted')
            callback()
        })
        .catch(error => console.log('error', error));
}

export const uploadFile = (link, file, callback) => {
    const header = new Headers();
    header.append("Authorization", "Bearer eyJhbGciOiJQQkVTMi1IUzUxMitBMjU2S1ciLCJlbmMiOiJBMjU2Q0JDLUhTNTEyIiwiY3R5IjoiSldUIiwicDJjIjo4MTkyLCJwMnMiOiIzS0h1THg1bTlCZXc3Tk5jIn0.IVYxSo0Z3qqul9hR_xdh-NRoOc692B_l-4w2FMA87aD3C_WvEQJ8GgMWgkNJKiBMzXRDY5ACAKqCmD6E71hDuFSkX8ConlxP.D6RMZFh5SESPqCbBCLc0FQ.wzloRHOlXNFyRYG6E6jsIZIc5RncqRDaDtXbeHy_JukHMPEEEHsWvZtSKJMvVlitvTtYi_fWW3kFDjUogN9pz2Z718HBMD098VJEJ7YkeO7EsgpKozaRWQD8NDaCNr7x9XhkT1Uk1qBXllqqJlUl49HzTKRQuEV2XZ23wff7pw28bOOhhJgz5VEnm7PXPPnImTV45vkyASJburPXU0vAkYl_I_LhS-nvyyyouAxQJ4-s72lLz57-5rVeCZID3IMuSlkQI4gUAJV5T1Dr5y-CpeWgoRjtixH7ycwBR2KyRcYPi-xckhT_rQU7KQ0NLSD1fzykAX8b8Yxwimpjfy6eWg.hCYw4jHBn85ONNG-qycnXp2YK6cWHXBLRcRxZTZZWNA");

    const formData = new FormData();
    const fileName = DateUtil.formatDateDoMMYY(DateUtil.now()).replace(/\s/g, '_') + "-" + file.name;
    formData.append("path", "/site/items/CareerForm/CVs");
    formData.append("name", fileName);
    formData.append("type", file.mimeType);
    formData.append("site", "disc-corporate");
    formData.append("file", base64ToBlob(file.content), fileName);

    const requestOptions = {
        method: 'POST',
        headers: header,
        body: formData,
        redirect: 'follow'
    };

    fetch(link, requestOptions)
        .then(response => response.text())
        .then(result => {
            callback(fileName)
        })
        .catch(error => console.log('error', error));
}

const getXMLData = (data, uploadedFileName) => {
    return "<component>\n" +
        `<content-type>/component/careerform</content-type>\n ` +
        `<name_t>${data.firstName}</name_t>\n    ` +
        `<middleName_t>${data.middleName}</middleName_t>\n    ` +
        `<surname_t>${data.surname}</surname_t>\n    ` +
        `<email_t>${data.email}</email_t>\n` +
        `<appliedPosition_t>${data.appliedPosition}</appliedPosition_t>\n` +
        `<cV_o item-list="true">
            <item datasource="file">
                <key>${uploadedFileName}</key>
                <value>${uploadedFileName}</value>
            </item>
        </cV_o>\n` +
        "</component>";
}

export const serialize = (obj, prefix) => {
    const str = []
    for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
            const k = prefix ? prefix + "[" + p + "]" : p,
                v = obj[p];
            str.push((v !== null && typeof v === "object") ?
                serialize(v, k) :
                encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
    }
    return str.join("&");
}
