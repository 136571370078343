import React, {Component} from 'react'
import PatientNameUtil from '../../utils/PatientNameUtil'
import DateUtil from '../../utils/DateUtil'
import EnumGender from '../user/enums/EnumGender'
import EnumVisaStatus from '../user/enums/EnumVisaStatus'
import dialog from '../../components/dialog/Dialog'
import moment from "moment";
import UpdateProfileModal from '../user/modals/UpdateProfileModal'
import PatientProfileImage from './PatientProfileImage'
import PhoneNumberUtil from "../../utils/PhoneNumberUtil";

class MyProfile extends Component {
  constructor (props) {
    super(props);
    this.publicURL = process.env.PUBLIC_URL;
  }

  findActiveEmergencyContact = (emergencyContact) => {
    return (
      emergencyContact && emergencyContact.length
        ? emergencyContact.reduce((prev, current) => (moment(prev.updateDate).isAfter(moment(current.updateDate))) ? prev : current)
        : {
          relationshipType: "",
          name: "",
          mobilePhone: ""
        }
    )
  }

  render () {

    const {patient} = this.props;
    const emergencyContact = patient.emergencyContact ? this.findActiveEmergencyContact(patient.emergencyContact) : null;

    return (
      <div className={"card h-100"}>
        <div className={"card-header d-flex justify-content-between p-1 bg-white"}>
          <div className={"card-header-title d-flex ml-2"}>
            <i className="icon size-2x icon-person align-self-center mid-grey-400"></i>
            <h6 className={"font-weight-bold mb-0 align-self-center"}>MY PROFILE</h6>
          </div>
          <div className={"card-header-actions"}>
            <i className="icon size-2x icon-edit mid-grey-400 btn p-0" onClick={() => {this.openEditProfileModal()}}></i>
          </div>
        </div>
        <div className={"card-body p-0"}>
          <div className={"row w-100 m-0"}>
            <div className={"col-md-3 h-100 my-profile-border py-5 px-3"}>
              <div className={"row"}>
                <div className={"col d-flex justify-content-center"}>
                  <i className="icon size-1x icon-verified disc-green"></i>
                  <h6 className={"font-weight-bolder"}>MRN&nbsp;</h6>
                  <div>{patient.mrn ? patient.mrn : '-'}</div>
                </div>
              </div>
              <div className={"row"}>
                <div className={"col d-flex justify-content-center"}>
                  <PatientProfileImage />
                </div>
              </div>
              <div className={"row"}>
                <div className={"col d-flex justify-content-center"}>
                  <h6 className={"font-weight-bold"}>
                    {PatientNameUtil.getPatientName(patient.firstName, patient.middleName, patient.lastName)}
                  </h6>
                </div>
              </div>
            </div>
            <div className={"col-md-9 py-4 px-4 small-font"}>
              <div className={"row mb-2"}>
                <div className={"col-4 font-weight-bold"}>Id No.</div>
                <div className={"col-8"}>{patient.eid ? patient.eid : '-'}</div>
              </div>
              <div className={"row mb-2"}>
                <div className={"col-4 font-weight-bold"}>Date of Birth</div>
                <div className={"col-8"}>{patient.birthday ? `${DateUtil.formatDateMMM(patient.birthday)} / ${DateUtil.getAge(patient.birthday)}` : '-'}</div>
              </div>
              <div className={"row mb-2"}>
                <div className={"col-4 font-weight-bold"}>Gender</div>
                <div className={"col-8"}>{patient.gender ? EnumGender[patient.gender] : '-'}</div>
              </div>
              <div className={"row mb-2"}>
                <div className={"col-4 font-weight-bold"}>Nationality</div>
                <div className={"col-8"}>{patient.nationality ? patient.nationality.nameInEnglish : '-'}</div>
              </div>
              <div className={"row mb-2"}>
                <div className={"col-4 font-weight-bold"}>Visa Status</div>
                <div className={"col-8"}>{patient.visaStatus ? EnumVisaStatus[patient.visaStatus] : '-'}</div>
              </div>
              <div className={"row mb-2"}>
                <div className={"col-4 font-weight-bold"}>Phone</div>
                <div className={"col-8"}>{patient.mobilePhone ? PhoneNumberUtil.formatInternational(patient.mobilePhone) : '-'}</div>
              </div>
              <div className={"row mb-2"}>
                <div className={"col-4 font-weight-bold"}>E-mail</div>
                <div className={"col-8"}>{patient.email ? patient.email : '-'}</div>
              </div>
              <div className={"row mb-2"}>
                <div className={"col-4 font-weight-bold"}>Address</div>
                <div className={"col-8"}>{patient.address ? patient.address : '-'}</div>
              </div>
              <div className={"row"}>
                <div className={"col-4 font-weight-bold"}>Emergency Contact</div>
                <div className={"col-8"}>{emergencyContact ? emergencyContact.name + " / " + PhoneNumberUtil.formatInternational(emergencyContact.mobilePhone) : "-"}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  openEditProfileModal() {
    dialog({
      component: <UpdateProfileModal patient={this.props.patient}
                                     onPatientUpdated={patient => this.props.onPatientUpdated(patient)}/>,
      scrollable: true
    });
  }
}

export default MyProfile