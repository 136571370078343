import React, {Component} from "react";
import {withRouter} from "react-router";
import {connect} from 'react-redux';
import {Image} from "react-bootstrap";
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {userActions} from "../services/actions";
import RemotingService from "../services/remoting-service/RemotingService";
import {getPhoneNumberComponent} from "./PhoneNumber";

class PatientNavigationBar extends Component {

    constructor(props) {
        super(props);
        this.publicURL = process.env.PUBLIC_URL;
        this.state = {
            patient: {}
        };
    }

    componentDidMount() {
        // TODO prevent patient to be fetched for every page navigation
        RemotingService.getRemoteCall(`/api/patient`, null, patient => {
            this.setState({patient});
        });
    }

    handleClick = event => {
        this.setState({anchorEl: event.currentTarget})
    };

    handleClose = () => {
        this.setState({anchorEl: null})
    };

    handleViewProfile = () => {
        this.handleClose();
        this.props.history.push("/dashboard");
    };

    handleLogout = () => {
        this.props.dispatch(userActions.logout());
        this.props.history.push("/login");
    };

    render() {
        const {clinicCountry, handleMenuClick, hideSideBar} = this.props;

        return (
            <nav className="patient-top-nav top-bar navbar navbar-expand-md navbar-light py-0">
                {!hideSideBar && <button
                    className="hamburger-menu-button"
                    type="button"
                    data-toggle="collapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={handleMenuClick}
                >
                    <i className="icon size-3x icon-hamburger"></i>
                </button>}
                <div className={"patient-nav-brand"}>
                    <a className="navbar-brand" href="/myprofile">
                        <img height="36"
                             src={this.publicURL + "assets/images/disc-corp-logo.png"}/>
                    </a>
                </div>
                <div className={"col pr-0 d-flex justify-content-end"}>
                    <div className="align-self-center d-none d-md-block">
                        {getPhoneNumberComponent(clinicCountry)}
                    </div>
                    <div className={"patient-nav-profile"}>
                        <Image className="staff-photo-smaller" onClick={this.handleClick}
                               src={this.state.patient.thumbnail ?
                                   'data:image/jpeg;base64,' + this.state.patient.thumbnail :
                                   this.publicURL + "../assets/images/clinic/visitor-registration.svg"}/>

                        <Popper id="user-menu"
                                open={Boolean(this.state.anchorEl)}
                                anchorEl={this.state.anchorEl}
                                role={undefined} transition disablePortal>
                            {({TransitionProps, placement}) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                                >
                                    <Paper elevation={3}>
                                        <ClickAwayListener onClickAway={this.handleClose}>
                                            <MenuList autoFocusItem={Boolean(this.state.anchorEl)}
                                                      id="menu-list-grow">
                                                <MenuItem onClick={this.handleViewProfile}>View
                                                    Profile</MenuItem>
                                                <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                        <a className="add-appointment d-none d-md-block" href="new-appointment">
                            <i className={"icon size-2x icon-calendar-checked"}></i> Add Appointment
                        </a>
                    </div>
                </div>
            </nav>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user
    };
}

export default withRouter(connect(mapStateToProps)(PatientNavigationBar));
