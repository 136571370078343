import {
    RequiredValidator,
    MinLengthValidator,
    MaxLengthValidator,
    MinValidator,
    MaxValidator,
    GreaterValidator,
    EmailValidator,
    MaxSelectionValidator,
    MinTimeValidator,
    MaxTimeValidator,
    MaxDateValidator,
    CheckedValidator,
} from ".";

export const ValidatorRegistry = {
    required: new RequiredValidator(),
    minLength: new MinLengthValidator(),
    maxLength: new MaxLengthValidator(),
    min: new MinValidator(),
    max: new MaxValidator(),
    greater: new GreaterValidator(),
    maxSelection: new MaxSelectionValidator(),
    minTime: new MinTimeValidator(),
    maxTime: new MaxTimeValidator(),
    maxDate: new MaxDateValidator(),
    email: new EmailValidator(),
    checked: new CheckedValidator(),
};