import React, {Component} from "react";
import "./InsuranceInformation.css";
import "./InsuranceCardUpload.css";
import RemotingService from "../../services/remoting-service/RemotingService";
import EnumDocumentType from "./enums/EnumDocumentType";
import {base64ToBlob} from "../../utils/FileDownloader";
import FileInput from "../../components/file-input/FileInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import confirmDialog from "../../components/dialog/ConfirmDialog";

export default class InsuranceCardUpload extends Component {

    constructor(props) {
        super(props);
        this.publicURL = process.env.PUBLIC_URL;
        this.state= {};
    }

    componentDidMount() {
        RemotingService.getRemoteCall(`api/patient/document/${EnumDocumentType.INSURANCE_FRONT.key}`, null, file => {
            this.setState({insuranceFrontUrl: this.fileToUrl(file)});
        });
        RemotingService.getRemoteCall(`api/patient/document/${EnumDocumentType.INSURANCE_BACK.key}`, null, file => {
            this.setState({insuranceBackUrl: this.fileToUrl(file)});
        });
    }

    remove(documentType) {
        confirmDialog(<>&nbsp;</>, "The image will be deleted. Are you sure?", () => {
            RemotingService.deleteRemoteCall(`api/patient/document/${documentType}`, null, () => {
                if (documentType === EnumDocumentType.INSURANCE_FRONT.key) {
                    this.setState({insuranceFrontUrl: null});
                } else {
                    this.setState({insuranceBackUrl: null});
                }
            });
        });
    }

    upload(fileDto, documentType) {
        if (fileDto) {
            RemotingService.postRemoteCall(`api/patient/document/${documentType}`, fileDto, () => {
                if (documentType === EnumDocumentType.INSURANCE_FRONT.key) {
                    this.setState({insuranceFrontUrl: this.fileToUrl(fileDto)});
                } else {
                    this.setState({insuranceBackUrl: this.fileToUrl(fileDto)});
                }
            });
        }
    }

    render() {
        return (
            <div className={"card h-100"}>
                <div className={"card-header d-flex justify-content-between p-1 bg-white"}>
                    <div className={"card-header-title d-flex ml-2"}>
                        <i className="icon icon-insurance-card size-2x mid-grey-400 align-self-center"></i>
                        <h6 className={"font-weight-bold mb-0 align-self-center"}>INSURANCE CARD</h6>
                    </div>
                </div>
                <div className="card-body content-font">
                    <div className="row">
                        <div className="col-sm-6">
                            {this.state.insuranceFrontUrl ?
                                <CreditCardRatioDiv className="insurance-card-img-container">
                                    <div style={{position: "absolute", right: 24}}>
                                        <FontAwesomeIcon icon={["fas", "times"]} onClick={() => this.remove(EnumDocumentType.INSURANCE_FRONT.key)}/>
                                    </div>
                                    <img src={this.state.insuranceFrontUrl} style={{objectFit: "contain", width: "100%", height: "100%"}} alt=""/>
                                </CreditCardRatioDiv> :
                                <CreditCardRatioDiv className="insurance-card-upload-container">
                                    <InsuranceCardUploadButton id="front" onUpload={file => this.upload(file, EnumDocumentType.INSURANCE_FRONT.key)}/>
                                </CreditCardRatioDiv>
                            }
                            <div className="mt-1 d-flex justify-content-center">
                                Front of Insurance Card
                            </div>
                        </div>

                        <div className="col-sm-6">
                            {this.state.insuranceBackUrl ?
                                <CreditCardRatioDiv className="insurance-card-img-container">
                                    <div style={{position: "absolute", right: 24}}>
                                        <FontAwesomeIcon icon={["fas", "times"]} onClick={() => this.remove(EnumDocumentType.INSURANCE_BACK.key)}/>
                                    </div>
                                    <img src={this.state.insuranceBackUrl} style={{objectFit: "contain", width: "100%", height: "100%"}} alt=""/>
                                </CreditCardRatioDiv> :
                                <CreditCardRatioDiv className="insurance-card-upload-container">
                                    <InsuranceCardUploadButton id="back" onUpload={file => this.upload(file, EnumDocumentType.INSURANCE_BACK.key)}/>
                                </CreditCardRatioDiv>
                            }
                            <div className="mt-1 d-flex justify-content-center">
                                Back of Insurance Card
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    fileToUrl(file) {
        return file ? URL.createObjectURL(base64ToBlob(file.content, file.mimeType)) : null;
    }
}

class InsuranceCardUploadButton extends Component {

    render() {
        return (
            <FileInput id={this.props.id}
                       className="insurance-card-upload-button"
                       accept="image/*"
                       onChange={file => this.props.onUpload(file)}>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/icons/upload.svg`}/>
                </div>
                <div style={{marginTop: "12px"}}>
                    Upload
                </div>
            </FileInput>
        )
    }
}

class CreditCardRatioDiv extends Component {

    render() {
        const {children, style, className} = this.props;
        const height = this.myDiv ? this.myDiv.getBoundingClientRect().width * 0.6279 : null;

        return (
            <div ref={el => this.myDiv = el} style={{height: height, ...style}} className={className}>
                {children}
            </div>
        )
    }

}
