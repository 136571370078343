import React, {Component} from "react";
import {Calendar} from "primereact/calendar";
import DateUtil from "../../utils/DateUtil";

class PrimeDateInput extends Component {

    static defaultProps = {
        showIcon: false,
    };

    constructor(props) {
        super(props);
        this.calendarRef = React.createRef();
    }

    handleOnChange(event) {
        const utcDate = DateUtil.getUtcDateAtStartOfDay(event.value);
        this.props.onChange(utcDate);
    }

    handleClear(event) {
        this.calendarRef.current.updateModel(event, null);
        this.calendarRef.current.updateInputfield(null);
        this.calendarRef.current.hideOverlay();
    }

    render() {
        const {
            disablePast,
            disableFuture,
            minDate,
            maxDate,
            clearable,
            showCalendarIcon,
            showIcon,
            value,
            ...rest
        } = this.props;

        const dialogContainer = document.getElementById("dialog-container");

        return (
            <Calendar ref={this.calendarRef}
                      dateFormat="dd-M-yy"
                      yearRange={`${DateUtil.currentYear() - 100}:${DateUtil.currentYear() + 100}`}
                      appendTo={dialogContainer}
                      value={value != null ? new Date(value) : value}
                      showButtonBar
                      showIcon={showIcon}
                      {...rest}
                      monthNavigator yearNavigator
                      minDate={disablePast ? DateUtil.now().toDate() : new Date(minDate)}
                      maxDate={disableFuture ? DateUtil.now().toDate() : new Date(maxDate)}
                      onChange={event => this.handleOnChange(event)}
                      onClearButtonClick={event => this.handleClear(event)}
            />
        )
    }
}

export default PrimeDateInput;