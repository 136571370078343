export const scrollTo = (elementId) => {
    let d = document.getElementById(elementId);

    if (d) {
        let gx = d.offsetLeft;
        let e = d;
        if (d.offsetParent) while (e = e.offsetParent) gx += e.offsetLeft;

        let gy = d.offsetTop;
        let t = d;
        if (d.offsetParent) while (t = t.offsetParent) gy += t.offsetTop;

        window.scrollTo(gx, gy);
    }
}

export default {scrollTo};
