import React, {Component} from "react";
import PhoneInput from "../../../components/phone-input/PhoneInput";
import {Button} from "react-bootstrap";
import NotificationService from "../../../services/notification-service/NotificationService";
import FormInput from "../../formcomponents/FormInput";
import {EmailValidator} from "../../../components/form/validator";
import TextInput from "../../../components/text-input/TextInput";

class Registration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fullName: "",
      mobilePhone: "",
      email: "",
      agreedTerms: false,
      olderThanEighteen: false
    };

    this.onFullNameChange = this.onFullNameChange.bind(this);
    this.emailValidator = new EmailValidator();
  }

  onFullNameChange(value) {
      this.setState({ fullName: value });
  }

  register() {
    if (!this.state.fullName || this.state.fullName.split(' ').length < 2) {
      NotificationService.showNotification({
        severity: "error",
        summary: "Validation Error",
        detail: "Name and surname are both mandatory.",
      });
    } else if (!this.state.mobilePhone) {
      NotificationService.showNotification({
        severity: "error",
        summary: "Validation Error",
        detail: "Phone number is mandatory.",
      });
    } else if (!this.state.agreedTerms) {
      NotificationService.showNotification({
        severity: "error",
        summary: "Validation Error",
        detail: "Please agree on the terms.",
      });
    }
    else if (!this.state.olderThanEighteen) {
      NotificationService.showNotification({
        severity: "error",
        summary: "Validation Error",
        detail: "If you are over 18, please select the checkbox. However, if you are not over 18, you cannot register online.",
      });
    } else if (
      this.state.email &&
      this.state.email.length !== 0 &&
      !this.emailValidator.validate(this.state.email)
    ) {
      NotificationService.showNotification({
        severity: "error",
        summary: "Validation Error",
        detail: "E-mail is not valid.",
      });
    } else {
      this.props.onRegister(
        this.state.mobilePhone,
        this.state.email,
        this.state.fullName
      );
    }
  }

  render() {
    this.formFields = [];

    return (
      <div className={"container"}>
        <div className={"row"}>
          <div className={"col mb-md-5"}>
            <h3 style={{ fontWeight: "bolder" }}>New Visitor Registration</h3>
            <p>Please register to benefit more from our services</p>
          </div>
        </div>

        <div className={"row mb-2"}>
          <div className={"col"}>
            <div className="form-group">
              <TextInput
                id="fullName"
                className="form-control"
                labelText="Full Name"
                required="required"
                value={this.state.fullName}
                onChange={this.onFullNameChange}
                alphabeticOnly
              />
            </div>
          </div>
        </div>

        <div className={"row"}>
          <div className={"col-lg-6"}>
            <div className="form-group">
              <FormInput
                  type="email"
                  id="eMail"
                  className="form-control"
                  labelText="E-mail"
                  required="required"
                  onChange={(val) => this.setState({ email: val })}
              />
              <i className="icon icon-mail size-2x form-icon light-grey-300"></i>
            </div>
          </div>
          <div className={"col-lg-6"}>
            <div class="phone-input-wrapper">
              <div class="form-group">
                <PhoneInput
                  value={this.state.mobilePhone}
                  inputClass={"phone-input w-100"}
                  onChange={(value) => this.setState({ mobilePhone: value })}
                />
                <i className="icon icon-phone size-2x form-icon light-grey-300"></i>
              </div>
            </div>
          </div>
        </div>
        <div className={"row"}>
          <div className={"col mb-2"}>
            <div class="custom-control custom-checkbox">
              <input
                class="custom-control-input"
                type="checkbox"
                value=""
                id="defaultCheck1"
                edge="end"
                onChange={(event) =>
                  this.setState({
                    agreedTerms: event.target.checked,
                  })
                }
                checked={this.state.agreedTerms}
              />
              <label class="custom-control-label" for="defaultCheck1">
                I have read and agree to privacy policy and terms of use
              </label>
            </div>
          </div>
        </div>
        <div className={"row"}>
          <div className={"col mb-2"}>
            <div className="custom-control custom-checkbox">
              <input
                  className="custom-control-input"
                  type="checkbox"
                  value=""
                  id="ageCheck"
                  edge="end"
                  onChange={(event) =>
                      this.setState({
                        olderThanEighteen: event.target.checked,
                      })
                  }
                  checked={this.state.olderThanEighteen}
              />
              <label className="custom-control-label" htmlFor="ageCheck">
                I CERTIFY THAT I AM 18 YEARS OF AGE OR OLDER
              </label>
            </div>
          </div>
        </div>

        <div className={"row"}>
          <div className={"col-md-6"}>
            <Button
              className="btn btn-secondary w-100 mt-3 btn-block"
              onClick={this.register.bind(this)}
            >
              Register
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Registration;
