import React, { Component } from 'react'
import NavigationBar from '../../layout/NavigationBar'
import AboutUs from './AboutUs'
import OurGoalAndTeam from './OurGoalAndTeam'
import OurServices from './OurServices'
import WhyChooseDisc from './WhyChooseDISC'
import OurClinics from './OurClinics'
import Career from './Career'
import {
  getLandingPageData,
  getOurApproachItems,
  getSpecialityItemsLanding,
} from '../../services/cms-services/CorporateCmsService'

class CorporateLandingPage extends Component {

    state = {
        cmsData: null,
        isLoaded: false,
    }

    componentDidMount() {
        getLandingPageData(this.handleCmsData);
    }

    handleCmsData = (result) => {
        this.setState({cmsData: result, isLoaded: true})
    }

    render() {
        const cmsURL = process.env.REACT_APP_CMS_URL;
        const postFix = process.env.REACT_APP_POST_FIX;
        const {cmsData, isLoaded} = this.state;
        const data = cmsData?.descriptorDom?.page;


        return (

            <>
                {isLoaded ?
                    <>
                        <NavigationBar/>
                        <div className="container-fluid introduction-section">
                            <div className="row align-items-center justify-content-end">
                                <div className="col-md-5 py-5">
                                    <h1 className="font-weight-bold">
                                        {data.landingPageTitle_t}
                                    </h1>
                                    <h4
                                        dangerouslySetInnerHTML={{__html: data.landingPageDescription_html}}>
                                    </h4>
                                </div>
                                <div className="col-md-6 text-right">
                                    <img className="img-fluid"
                                         src={cmsURL + data.landingPageMainBanner_s + "?" + postFix}/>
                                </div>
                            </div>
                        </div>

                        <AboutUs
                            aboutUsTitle={data.aboutUsTitle_t}
                            aboutUsBanner={data.aboutUsBanner_s}
                            aboutUsDescription={data.aboutUsDescription_html}
                        />

                        <OurGoalAndTeam
                            missionTitle={data.missionTitle_t}
                            missionDescription={data.missionDescription_html}
                            visionTitle={data.visionTitle_t}
                            visionDescription={data.visionDescription_html}
                            missionImage={data.missionImage_s}
                        />
                        <OurServices ourServicesTitle={data.ourServicesTitle_s}
                                     ourServicesDescription={data.ourServicesDescription_html}
                                     getData={getSpecialityItemsLanding}/>
                        <WhyChooseDisc getData={getOurApproachItems}/>
                        <OurClinics/>
                        <Career careerTitle={data.careerTitle_t}
                                careerDescription={data.careerDescription_html}
                                careerBanner={data.careerBanner_s}
                                careerURL={data.careerURL_t}
                        /> </> : null}
            </>
        );
    }

}

export default CorporateLandingPage;
