import moment from "moment";
import DateUtil from "./DateUtil";

export default class ObjectUtil {

    // https://stackoverflow.com/questions/728360/how-do-i-correctly-clone-a-javascript-object/728694#728694
    static clone(obj) {
        var copy;

        // Handle the 3 simple types, and null or undefined
        if (null == obj || "object" != typeof obj)
            return obj;

        // Handle Date
        if (obj instanceof Date) {
            copy = new Date(obj.getTime());
            return copy;
        }

        // Handle Moment
        if (obj instanceof moment) {
            copy = moment(obj);
            return copy;
        }

        // Handle Array
        if (obj instanceof Array) {
            copy = [];
            for (var i = 0; i < obj.length; i++) {
                copy[i] = ObjectUtil.clone(obj[i]);
            }
            return copy;
        }

        // Handle Object
        if (obj instanceof Object) {
            copy = {};
            for (var attr in obj) {
                if (obj.hasOwnProperty(attr)) copy[attr] = ObjectUtil.clone(obj[attr]);
            }
            return copy;
        }

        throw new Error("Unable to copy obj! Its type isn't supported.");
    }

    static sort(arr, field) {
        if (!arr || !Array.isArray(arr) || arr.length === 0) {
            return arr;
        }

        return arr.sort((a, b) => {
            const fieldA = a[field];
            const fieldB = b[field];

            if (fieldA < fieldB) {
                return -1;

            } else if (fieldA > fieldB) {
                return 1;
            }

            return 0;
        });
    }

    static sortByDate(arr, field) {
        if (!arr || !Array.isArray(arr) || arr.length === 0) {
            return arr;
        }

        return arr.sort((a, b) => {
            const fieldA = a[field];
            const fieldB = b[field];

            if (DateUtil.isBefore(fieldA, fieldB)) {
                return -1;

            } else if (DateUtil.isAfter(fieldA, fieldB)) {
                return 1;
            }

            return 0;
        });
    }

    static removeNullFields(value) {
        for (let prop in value) {
            if (value.hasOwnProperty(prop)) {
                if(value[prop] === null) {
                    delete value[prop];
                };
            }
        }
        return value;
    }

    static nvl(value, alternativeValue) {
        return value !== null && value !== undefined ? value : alternativeValue;
    }

}
