import React, { Component } from 'react'
import { getIntegrationLandingPageData } from '../../services/cms-services/CorporateCmsService'
import NavigationBar from '../../layout/NavigationBar'

class IntegrationLanding extends Component {

    state = {
        isLoaded: false,
        cmsData: null,
    }

    componentDidMount() {
        getIntegrationLandingPageData(this.handleCmsData);
    }

    handleCmsData = (result) => {
        this.setState({cmsData: result, isLoaded: true})
    }

    render() {
        const {cmsData, isLoaded} = this.state;
        let component = null;
        if (isLoaded) {
            component = cmsData[0].descriptorDom.page;
        }

        const cmsURL = process.env.REACT_APP_CMS_URL;
        const postFix = process.env.REACT_APP_POST_FIX;
        return (
            <>

                <NavigationBar/>
                {isLoaded ?
                    <>
                        <div className="container-fluid introduction-section">
                            <div className="row align-items-center justify-content-end">
                                <div className="col-md-5 py-5">
                                    <h1 className="font-weight-bold">
                                        {component.integrationTitle_t}
                                    </h1>
                                    <h4>
                                        {component.integrationSubtitle_t}
                                    </h4>
                                </div>
                                <div className="col-md-6 text-right">
                                    <img className="img-fluid" src={cmsURL + component.integrationImage_s + "?" + postFix}/>


                                </div>
                            </div>
                        </div>

                        <div className="container mt-5">
                            <div className="row">
                                <div className="col-md-12">
                                    <div dangerouslySetInnerHTML={{__html: component.integrationDescription_html}}/>
                                    <h4 className="bordered-heading font-weight-bold mt-5">
                                        {component.communitySocialResponsibilityTitle_t}
                                    </h4>
                                    <p dangerouslySetInnerHTML={{__html: component.communitySocialResponsibilityDescription_html}}>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </>
                    : null}

            </>


        );
    }

}

export default IntegrationLanding;
