import { getData } from './CmsService'

export const getYourVisitDataDubai = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/SitePages/OurAppointment/Dubai/yourvisit`, callBack)
}

export const getInsuranceDataDubai = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/SitePages/OurAppointment/Dubai/insurancepagedubai`, callBack)
}

export const getLocationsDataDubai = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/SitePages/OurAppointment/Dubai/locationspage`, callBack)
}

export const getYourVisitDataKuwait = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/SitePages/OurAppointment/Kuwait/yourvisitkuwait`, callBack)
}

export const getInsuranceDataKuwait = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/SitePages/OurAppointment/Kuwait/insurancepagekuwait`, callBack)
}

export const getLocationsDataKuwait = (callBack) => {
    getData(`${process.env.REACT_APP_CMS_URL}/api/1/site/content_store/children?${process.env.REACT_APP_POST_FIX}&url=/site/items/SitePages/OurAppointment/Kuwait/locationspage`, callBack)
}
