import React, { Component } from "react";
import AuthService from "../services/auth-service/AuthService";
import TopBar from "./TopBar";
import DelayedLink from "./DelayedLink";
import {scrollTo} from "../utils/LayoutUtil";
import NotificationService from "../services/notification-service/NotificationService";

class NavigationBar extends Component {
  constructor(props) {
    super(props);
    this.publicURL = process.env.PUBLIC_URL;
  }

  componentDidMount() {
    const elementId = window.location.hash.substr(1);
    if(elementId != null) {
      setTimeout(() => {
        scrollTo(elementId);
      }, 400)
    }
  }

  render() {

    return (
        <>
        <TopBar shouldRenderGetDirections={false}/>
          <nav className="navbar navbar-expand-md navbar-light">
            <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
              <i className="icon size-3x icon-hamburger"></i>
            </button>
            <a className="navbar-brand" href="/">
              <img
                  height="48"
                  src={this.publicURL + "/assets/images/disc-corp-logo.png"}
              />
            </a>

            <div className="collapse navbar-collapse justify-content-between pr-3" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <DelayedLink hrefPrefix="/" href="aboutUs" title="About Us"/>
                </li>
                <li className="nav-item">
                  <DelayedLink hrefPrefix="/" href="ourServices" title="Our Services"/>
                </li>
                <li className="nav-item">
                  <DelayedLink hrefPrefix="/" href="ourClinics" title="Our Clinics"/>
                </li>
                <li className="nav-item">
                  <DelayedLink hrefPrefix="/" href="career" title="Career"/>
                </li>
              </ul>
              <ul className="navbar-nav">
                {!AuthService.getAuthToken() &&
                <li className="nav-item ">
                  <a className="nav-link" href="/login" onClick={(event) => {
                    {/* PatientPortalHide : Remove onClick function */}
                    event.preventDefault();
                    NotificationService.showComingSoonNotification();
                  }}>
                    Login
                  </a>
                </li>
                }
                {!AuthService.getAuthToken() &&
                <li className="nav-item ">
                  <a className="nav-link" href="/login" onClick={(event) => {
                    {/* PatientPortalHide : Remove onClick function */}
                    event.preventDefault();
                    NotificationService.showComingSoonNotification();
                  }}>
                    Register
                  </a>
                </li>
                }
                <li className="nav-item ">
                  <a className="nav-link btn btn-secondary ml-2" href="/new-appointment" onClick={(event) => {
                    {/* PatientPortalHide : Remove onClick function */}
                    event.preventDefault();
                    NotificationService.showComingSoonNotification();
                  }}>
                    Add Appointment
                  </a>
                </li>
              </ul>
              <ul className="navbar-nav d-flex flex-row d-md-none list-inline mt-5 align-items-left m-lg-0 social-links">
                <li>
                  <a target="_blank" href="https://www.facebook.com/discdubai">
                    <i className="icon size-2x icon-facebook disc-grey"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.instagram.com/disc_dubai/">
                    <i className="icon size-2x icon-instagram disc-grey"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.linkedin.com/company/diversified-integrated-sports-clinic/">
                    <i className="icon size-2x icon-linkedin disc-grey"></i>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </>
    );
  }
}

export default NavigationBar;
