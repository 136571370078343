import React, {Component} from "react";
import ClinicNavigationBar from "../../layout/ClinicNavigationBar";
import TopBar from "../../layout/TopBar";
import {Dropdown} from 'primereact/dropdown';
import {getInsuranceDataDubai} from "../../services/cms-services/OurAppointmentCmsService";
import {retrieveAcceptedNetworks, retrieveInsuranceCompanies, retrieveTPAs} from "./util/InsuranceUtil";
import YourAppointmentSideMenuDubai from "./YourAppointmentSideMenuDubai";
import EnumCountry from "../user/enums/EnumCountry";


class YourAppointmentDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tpaList: [],
            companyList: [],
            networkList: [],
            selectedTPA: null,
            selectedInsuranceCompany: null,
            selectedNetwork: null,
            cmsData: null,
            isLoaded: false
        };
        this.onTPAChange = this.onTPAChange.bind(this);
        this.onNetworkChange = this.onNetworkChange.bind(this);
        this.onInsuranceCompanyChange = this.onInsuranceCompanyChange.bind(this);
    }

    componentDidMount() {
        getInsuranceDataDubai(this.handleCmsData);
        retrieveTPAs("UAE", (result) => this.handleTPARetrieve(result));
    }

    handleCmsData = (result) => {
        this.setState({cmsData: result, isLoaded: true})
    }

    onTPAChange(e) {
        const selectedTPA = e.value;
        if (selectedTPA == null) {
            this.setState({
                ...this.state,
                selectedTPA,
                companyList: [],
                networkList: [],
                selectedInsuranceCompany: null,
                selectedNetwork: null,
            });
            return;
        }

        this.setState({
            ...this.state,
            selectedTPA
        }, retrieveInsuranceCompanies(selectedTPA, EnumCountry.UAE.key, (result) => this.handleCompanyRetrieve(result)));
    }

    handleTPARetrieve(tpaList) {
        this.setState({
            ...this.state,
            tpaList,
            companyList: [],
            networkList: [],
            selectedTPA: null,
            selectedInsuranceCompany: null,
            selectedNetwork: null,
        });
    }

    onInsuranceCompanyChange(e) {
        const selectedInsuranceCompany = e.value;
        if (selectedInsuranceCompany == null) {
            this.setState({
                ...this.state,
                selectedInsuranceCompany,
                networkList: [],
                selectedNetwork: null,
            });
            return;
        }

        this.setState({
            ...this.state,
            selectedInsuranceCompany
        }, retrieveAcceptedNetworks(selectedInsuranceCompany, (result) => this.handleNetworkRetrieve(result)));
    }

    handleCompanyRetrieve(companyList) {
        this.setState({
            ...this.state,
            companyList,
            networkList: [],
            selectedInsuranceCompany: null,
            selectedNetwork: null,
        });
    }

    onNetworkChange(e) {
        const selectedNetwork = e.value;
        this.setState({...this.state, selectedNetwork});
    }

    handleNetworkRetrieve(networkList) {
        this.setState({...this.state, networkList, selectedNetwork: null});
    }

    selectedOptionTemplate(option, props) {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.name}</div>
                </div>
            );
        }

        return (
            <span>{props.placeholder}</span>
        );
    }

    optionTemplate(option) {
        return (
            <div className="country-item">
                <div>{option.name}</div>
            </div>
        );
    }


    render() {
        const {cmsData, isLoaded} = this.state;
        let data = null;
        if (isLoaded && cmsData) {
            data = cmsData[0].descriptorDom.page;
        }

        return (
            <>
                <TopBar/>
                <ClinicNavigationBar/>
                {isLoaded ? <div className="your-appointment-container container-fluid py-md-5 xxl-limiter">
                    <div className="row min-vh-100 flex-column-reverse flex-md-row justify-content-end">
                        <div className="col-md-8 offset-lg-1 ">
                            <div className="col p-0">
                                <h2 className="bordered-heading font-weight-bold">{data.insuranceTitle_t}</h2>
                                <div dangerouslySetInnerHTML={{__html: data.insuranceDescription_html}}></div>
                            </div>
                            <h4 className="font-weight-bold mt-4">{data.directBillingTitle_t}</h4>
                            <p dangerouslySetInnerHTML={{__html: data.directBillingDescriptionMain_html}}/>
                            <div className="row">
                                <div className={"col-lg-5"}>
                                    <ul className={"list-unstyled"}>
                                        <li className={"mb-3"}>
                                            <Dropdown value={this.state.selectedTPA} options={this.state.tpaList}
                                                      onChange={this.onTPAChange} optionLabel="name" filter showClear
                                                      filterBy="name" placeholder="Select a TPA"
                                                      valueTemplate={this.selectedOptionTemplate}
                                                      itemTemplate={this.optionTemplate}/>
                                        </li>

                                        <li className={"mb-3"}>
                                            <Dropdown value={this.state.selectedInsuranceCompany}
                                                      options={this.state.companyList}
                                                      onChange={this.onInsuranceCompanyChange} optionLabel="name" filter
                                                      showClear filterBy="name"
                                                      placeholder="Select an Insurance Company"
                                                      valueTemplate={this.selectedOptionTemplate}
                                                      itemTemplate={this.optionTemplate}/>
                                        </li>

                                        <li className={"mb-3"}>
                                            <Dropdown value={this.state.selectedNetwork}
                                                      options={this.state.networkList}
                                                      onChange={this.onNetworkChange} optionLabel="name" filter
                                                      showClear filterBy="name" placeholder="Select a Network"
                                                      valueTemplate={this.selectedOptionTemplate}
                                                      itemTemplate={this.optionTemplate}/>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col mt-4">
                                    <div className="check-list"
                                         dangerouslySetInnerHTML={{__html: data.directBillingDescriptionSub_html}}/>
                                </div>
                            </div>

                            <div id="pay-and-claim" className="row">
                                <div className="col">
                                    <h4 className="font-weight-bold mt-4">{data.payAndClaimTitle_t}</h4>
                                    <div className="check-list"
                                         dangerouslySetInnerHTML={{__html: data.payAndClaimDescriptionMain_html}}/>
                                    <div className="check-list"
                                         dangerouslySetInnerHTML={{__html: data.payAndClaimDescriptionSub_html}}/>
                                </div>
                            </div>

                            <div id="guarantee-of-payment" className="row">
                                <div className="col">
                                    <h4 className="font-weight-bold">{data.guaranteeOfPaymentTitle_t}</h4>
                                    <div className="check-list"
                                         dangerouslySetInnerHTML={{__html: data.guaranteeOfPaymentDescription_html}}/>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-3 p-0">
                            <YourAppointmentSideMenuDubai insuranceActive="menu-active"/>
                        </div>
                    </div>
                </div> : null}
            </>
        );
    }
}

export default YourAppointmentDetail;
