import React, { Component } from 'react'
import { connect } from 'react-redux'
import FooterDubai from './FooterDubai'
import routes, { clinicBasedPage } from '../routes'
import PrivateRoute from '../modules/user/PrivateRoute'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import FooterKuwait from './FooterKuwait'

const { GENERAL, DUBAI, KUWAIT } = clinicBasedPage

class Layout extends Component {

    routesMap = routes.map((element, index) => {
        let relativePath
        if (process.env.PUBLIC_URL) {
            relativePath = `${process.env.PUBLIC_URL}${element.path}`
        } else {
            relativePath = `${element.path}`;
        }

        const component = getWrappedComponent(element.basePage, element.component, element.footer);

        let props = {
            path: relativePath,
            component: component,
            key: index,
            exact: element.exact,
            basePage: element.basePage
        };

        return element.authority ? <PrivateRoute {...props} /> : <Route {...props} />;
    });

    render() {
        return (
            <>
                <Router>{this.routesMap}</Router>
            </>
        );
    }
}

export const getWrappedComponent = (basePage, InnerComponent, shouldRenderFooter) => {
    return class extends Component {
        render() {
            return (
                <>
                    <InnerComponent {...this.props}/>
                    {shouldRenderFooter && getFooter(basePage)}
                </>
            );
        }
    };
}

export const getFooter = (basePage) => {
    switch (basePage) {
        case DUBAI:
            return <FooterDubai/>;
        case KUWAIT:
            return <FooterKuwait/>;
        case GENERAL:
            return <FooterDubai/>;
        default:
            return <FooterDubai/>;
    }
}

const mapStateToProps = state => {
    return {
        user: state.authentication.user
    };
};

export default connect(mapStateToProps)(Layout);
