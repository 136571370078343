import React, { Component } from 'react'
import FooterInformationModal from './FooterInformationModal'
import { getPrivacyPolicyData, getTermsOfUseData } from '../services/cms-services/FooterCmsService'

class FooterDubai extends Component {

    state = {
        informationModalVisible: false,
        modalTitle: null,
        retrieveDataFunction: null
    }

    showInformationModal = (modalTitle, retrieveDataFunction) => {
        this.setState({informationModalVisible: true, modalTitle, retrieveDataFunction})
    }

    closeInformationModal = () => {
        this.setState({informationModalVisible: false, modalTitle: null, retrieveDataFunction: null})
    }

    render() {
        const {informationModalVisible, modalTitle, retrieveDataFunction} = this.state;
        return (
            <footer className="bg-white">
                <div className="menu">
                    <a href="/">
                        <img
                            height="36"
                            src="/assets/images/disc-corp-logo.png"
                            className="img-fulid"
                        />
                    </a>
                    <ul className="list-inline menu-items mb-0">
                        <li>
                            <a href="" onClick={(e) => {
                                e.preventDefault();
                                this.showInformationModal("Terms & Conditions Of Use", getTermsOfUseData);
                            }}>Terms & Conditions Of Use</a>
                        </li>
                        <li>
                            <a href=""
                               onClick={(e) => {
                                   e.preventDefault();
                                   this.showInformationModal("Privacy Policy", getPrivacyPolicyData);
                               }}>Privacy
                                Policy</a>
                        </li>
                        <li>
                            <a href="/#aboutUs">About Us</a>
                        </li>
                        <li>
                            <a href="/career">Career</a>
                        </li>
                        <li>
                            <a href="/contact-us">Contact Us</a>
                        </li>
                    </ul>
                    <ul className="list-inline social-links mb-0">
                        <li>
                            <a target="_blank" href="https://www.facebook.com/discdubai">
                                <i className="icon size-3x icon-facebook disc-grey"></i>
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.instagram.com/disc_dubai/">
                                <i className="icon size-3x icon-instagram disc-grey"></i>
                            </a>
                        </li>
                        <li>
                            <a target="_blank"
                               href="https://www.linkedin.com/company/diversified-integrated-sports-clinic/">
                                <i className="icon size-3x icon-linkedin disc-grey"></i>
                            </a>
                        </li>
                    </ul>
                    {<FooterInformationModal show={informationModalVisible}
                                             closeModal={this.closeInformationModal}
                                             title={modalTitle}
                                             retrieveDataFunction={retrieveDataFunction}/>}
                </div>
            </footer>
        );
    }
}

export default FooterDubai;
