import React, {Component} from "react";
import DubaiPhoneNumber from "./DubaiPhoneNumber";
import clsx from "clsx";

class TopBar extends Component {

    static defaultProps = {
        shouldRenderGetDirections: true
    }

    constructor(props) {
        super(props);
        this.publicURL = process.env.PUBLIC_URL;
    }

    render() {
        const {shouldRenderGetDirections} = this.props;

        return (
            <div className="container-fluid top-bar pl-md-5">
                <div className="row justify-content-end">
                    <div className={clsx("d-flex justify-content-end", !shouldRenderGetDirections || "pr-3")}>
                        <DubaiPhoneNumber/>
                        {shouldRenderGetDirections && <a href="/dubai/our-locations">
                            <i className="icon size-2x icon-directions-circle disc-green"></i>
                            <span className="font-weight-bold">Get Directions</span>
                        </a>}
                    </div>
                </div>
            </div>
        );
    }
}

export default TopBar;