import React, {Component} from 'react';
import StaffUtil from "../../../../utils/StaffUtil";
import {getOurServicesData} from "../../../../services/cms-services/OurServicesCmsService";
import OurServicesCommonDetail from "./OurServicesCommonDetail";
import OurServicesCommonTitle from "./OurServicesCommonTitle";

class OurServicesTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {clinicianList: [], cmsData: null, isLoaded: false}
        this.getCmsData();
    }

    onStaffListBack = (clinicianList) => {
        this.setState({clinicianList: this.state.clinicianList.concat(clinicianList)});
    }

    handleCmsData = (response) => {
        const items = response?.data?.component_speciality?.items;
        if (items != null) {
            const data = items[0];
            const {page} = this.props;
            if (page.shouldRetrieveStaff) {
                const departments = data.medaiSpecialityList_o.item;
                departments.map(speciality => {
                    StaffUtil.getClinicianListByDepartment(page.country.clinicNames, speciality.specialityName_t, this.onStaffListBack);
                })
            }
            this.setState({cmsData: data, isLoaded: true});
        }
    }

    getCmsData = () => {
        const {page, service} = this.props;
        getOurServicesData(service, page.cmsTitle, this.handleCmsData);
    }

    render() {
        const {clinicianList, cmsData, isLoaded} = this.state;
        const {page, service} = this.props;

        return (
            <>
                {isLoaded &&
                <>
                    <OurServicesCommonTitle cmsData={cmsData} page={page}/>
                    <OurServicesCommonDetail cmsData={cmsData} page={page} service={service}
                                             clinicianList={clinicianList}/>
                </>}
            </>
        );
    }
}

export default OurServicesTemplate;