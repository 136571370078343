import React, { Component } from 'react'
import { getInnovationLandingPageData } from '../../services/cms-services/CorporateCmsService'
import NavigationBar from '../../layout/NavigationBar'

class InnovationLanding extends Component {

    state = {
        isLoaded: false,
        cmsData: null,
    }

    componentDidMount() {
        getInnovationLandingPageData(this.handleCmsData);
    }

    handleCmsData = (result) => {
        this.setState({cmsData:result, isLoaded:true})
    }

    render() {
        const {cmsData, isLoaded} = this.state;
        let component = null;
        if (isLoaded){
            component = cmsData[0].descriptorDom.page;
        }

        const cmsURL = process.env.REACT_APP_CMS_URL;
        const postFix = process.env.REACT_APP_POST_FIX;
        return (

            <>
                <NavigationBar/>
                {isLoaded ?
                    <>
                        <div className="container-fluid introduction-section">
                            <div className="row align-items-center justify-content-end">
                                <div className="col-md-5 py-5">
                                    <h1 className="font-weight-bold">
                                        {component.innovationTitle_t}
                                    </h1>
                                    <h4>{component.innovationSubtitle_t}</h4>
                                </div>
                                <div className="col-md-6 text-right">
                                    <img className="img-fluid" src={cmsURL + component.innovationImage_s + "?" + postFix}/>


                                </div>
                            </div>
                        </div>

                        <div className="container mt-5">
                            <div className="row">
                                <div className="col-md-12">
                                    <div dangerouslySetInnerHTML={{__html: component.innovationDescription_html}}>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </>
                    : null}
            </>

        );
    }
}

export default InnovationLanding;
