import React, {Component} from "react";
import KuwaitPhoneNumber from "./KuwaitPhoneNumber";

class KuwaitTopBar extends Component {
    constructor(props) {
        super(props);
        this.publicURL = process.env.PUBLIC_URL;
    }

    render() {
        return (
            <div className="container-fluid top-bar pl-md-5">
                <div className="row justify-content-end">
                    <div className="d-flex justify-content-end pr-3">
                        <KuwaitPhoneNumber/>
                        <a href="/kuwait/our-locations">
                            <i className="icon size-2x icon-directions-circle disc-green"></i>
                            <span className="font-weight-bold">Get Directions</span>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default KuwaitTopBar;