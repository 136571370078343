import React, {Component} from 'react';
import OurServicesTemplate from "./template/OurServicesTemplate";
import OurServicesCmsPageEnum from "./OurServicesCmsPageEnum";
import OurServicesCorporateWellnessTemplate from "./template/OurServicesCorporateWellnessTemplate";

class OurServicesGeneralPageDubai extends Component {
    render() {
        const {serviceName} = this.props.match.params;

        if (serviceName === "Corporate Wellness")
            return <OurServicesCorporateWellnessTemplate service={serviceName}
                                                         page={OurServicesCmsPageEnum.DUBAI}/>

        return (
            <OurServicesTemplate service={serviceName}
                                 page={OurServicesCmsPageEnum.DUBAI}/>
        );
    }
}

export default OurServicesGeneralPageDubai;