import Enum from "../../../enums/Enum";

const EnumAppointmentStatus = new Enum({
    BOOKED: {name: "Booked"},
    CONFIRMED: {name: "Confirmed"},
    ARRIVED: {name: "Checked In"},
    BEINGSEEN: {name: "Being Seen"},
    CONSULTED: {name: "Consulted"},
    COMPLETED: {name: "Completed"},
    CANCELLED: {name: "Cancelled"},
    NO_SHOW: {name: "No Show"}
});

export default EnumAppointmentStatus;