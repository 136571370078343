import React, { Component } from 'react'

export default class YourAppointment extends Component {

    state = {
        cmsData: null,
        isLoaded: false,
    }

    componentDidMount() {
        this.props.getData(this.handleCmsData);
    }

    handleCmsData = (result) => {
        this.setState({cmsData: result, isLoaded: true})
    }

    render() {

        const cmsURL = process.env.REACT_APP_CMS_URL;
        const postFix = process.env.REACT_APP_POST_FIX;
        const publicURL = process.env.PUBLIC_URL
        const {cmsData, isLoaded} = this.state;
        let component = null;
        if (isLoaded){
            component = cmsData[0].descriptorDom.component;
        }


        return (
            <>
                {isLoaded ?
                    <>
                        <div id={"yourAppointment"}>
                            <div class="container-fluid xxl-limiter mb-5">
                                <div class="container mb-4">
                                    <div className="row">
                                        <div className="col-lg-6 mx-auto text-center mt-5">
                                            <h1 className="font-weight-bold">{component.yourAppointmentTitle_t}</h1>
                                            <p className="mt-3" dangerouslySetInnerHTML={{__html: component.yourAppointmentDescription_html}}>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-7 mx-auto">
                                    <div class="row">
                                        {
                                            component?.yourAppointmentSubSections_o?.item?.map( (i, index) =>
                                        <div class="col-md-4 text-center equal-height-content ">

                                            <img className="img-fluid" src={cmsURL + i.subSectionImage_s + "?" + postFix}  alt="about-us"/>

                                            <article>
                                                <h6 class="font-weight-bold text-center">
                                                    {i.subSectionTitle_t}
                                                </h6>
                                                <div class="text-center" dangerouslySetInnerHTML={{__html:i.subDescription_html}}>
                                                </div>
                                            </article>
                                            <a
                                                href={i.subSectionURL_t || '#' }
                                                class="btn btn-secondary btn-sm"
                                            >
                                                View in Detail
                                            </a>
                                        </div>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </> : null}
            </>
        );
    }

}


