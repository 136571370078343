import React from "react";
import {InputText as PInputText} from "primereact/inputtext";
import StringUtil from "../../utils/StringUtil";

const InputText = ({alphabeticOnly = false, onChange = ()=>{}, ...others}) => {

    const onChangeInternal = (e) => {
        const value = e.target.value;

        if(!alphabeticOnly || StringUtil.isValidAlphabeticText(value)) {
            onChange(e);
        }
    }
    return <PInputText
        onChange={onChangeInternal}
        {...others} />;
}

export default InputText;