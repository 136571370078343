import React, {Component} from 'react';
import EnumCommunicationMethod from "../../../../user/enums/EnumCommunicationMethod";

class CommunicationMethod extends Component {
    render() {
        const {communicationMethods, handleMultiSelectionChange} = this.props;

        return (
            <div className="col-12 p-0 d-flex flex-row">
                {EnumCommunicationMethod.toArray().map((communicationMethod, idx) => (
                    <div className="custom-control custom-checkbox" key={idx}>
                        <input
                            className="custom-control-input"
                            edge="end"
                            type="checkbox"
                            id={`${communicationMethod.key}-communication-checkbox`}
                            checked={communicationMethods.includes(communicationMethod.key)}
                            onChange={(e) => handleMultiSelectionChange("communicationMethods", communicationMethod, e.target.checked)}
                        />
                        <label className="custom-control-label"
                               htmlFor={`${communicationMethod.key}-communication-checkbox`}>
                            <span className="pl-2 pr-5">{communicationMethod.name}</span>
                        </label>
                    </div>
                ))}
            </div>
        );
    }
}

export default CommunicationMethod;