import React, {Component} from 'react';
import {
    getCorporateWellnessGraphQLQuery,
    getOurServicesData
} from "../../../../services/cms-services/OurServicesCmsService";
import OurServicesCorporateWellnessDetail from "./OurServicesCorporateWellnessDetail";
import OurServicesCommonTitle from "./OurServicesCommonTitle";

class OurServicesTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {cmsData: null, isLoaded: false}

        this.getCmsData();
    }

    handleCmsData = (response) => {
        const items = response?.data?.page_specialtycorporatewellness?.items;
        if (items != null) {
            const data = items[0];
            this.setState({cmsData: data, isLoaded: true});
        }
    }

    getCmsData = () => {
        const {page, service} = this.props;
        getOurServicesData(service, page.cmsTitle, this.handleCmsData, getCorporateWellnessGraphQLQuery);
    }

    render() {
        const {cmsData, isLoaded} = this.state;
        const {page, service} = this.props;

        return (
            <>
                {isLoaded &&
                <>
                    <OurServicesCommonTitle cmsData={cmsData} page={page}/>
                    <OurServicesCorporateWellnessDetail cmsData={cmsData} page={page} service={service}/>
                </>}
            </>
        );
    }
}

export default OurServicesTemplate;