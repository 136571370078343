import React, {Component} from "react";
import WindowUtil from "../../utils/WindowUtil";

export default class ClinicianCard extends Component {


    openStaffProfile = (staffId) => {
        const url = '/doctorprofile/' + staffId;
        WindowUtil.openInNewTab(url);
    }

    render() {
        const {clinician} = this.props;
        const thumbnail = clinician.thumbnail ? 'data:image/jpeg;base64,' + clinician.thumbnail :
            process.env.PUBLIC_URL + '/assets/images/clinic/doctors/default.png';

        return (
            <div className="clinician-card col-md-6 px-2">
                <a className="team-card-link" onClick={() => this.openStaffProfile(clinician.id)}>
                    <div className="team-card mb-3">
                        <div className="row no-gutters align-items-center pl-3">
                            <div className="col-2 d-flex align-items-center py-2">
                                <img src={thumbnail} className="img-fluid rounded-circle" width="96"/>
                            </div>
                            <div className="col-10">
                                <div className="team-card-body">
                                    <h6 className="team-card-title m-0">
                                        {clinician.name}
                                    </h6>
                                    <p className="team-card-text">
                                        {clinician.specialityName}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        )
    }

}